import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { Button, Form } from 'react-bootstrap'
import { customStyles, ModalContent, ModalFooter, ModalHeader, ModalWrapper } from '../../../../../../shared/modal-view'
import { CalendarType } from '../../../../../../../model/calendar-type-enum'

type AssignOuterCalendarModalProps = {
  show: boolean
  onClose: () => void
  onAssignCalendar: (type: CalendarType) => void
}
export const AssignOuterCalendarModal: React.FC<AssignOuterCalendarModalProps> = ({
  show,
  onClose,
  onAssignCalendar,
}) => {
  const [selectedCalendarType, setSelectedCalendarType] = useState<CalendarType>(CalendarType.GOOGLE)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    setSelectedCalendarType(event.target.value as CalendarType)
  }
  return (
    <ReactModal
      isOpen={show}
      contentLabel="Связать с внешним календарем"
      onRequestClose={onClose}
      ariaHideApp
      shouldFocusAfterRender
      shouldCloseOnEsc
      parentSelector={() => document.body}
      style={{
        ...customStyles,
        content: {
          ...customStyles.content,
          width: '600px',
        },
      }}
      appElement={document.getElementById(`root`) || undefined}
    >
      <ModalWrapper>
        <ModalHeader>Связать с внешним календарем</ModalHeader>
        <ModalContent>
          <Form.Group controlId="calendarType">
            <Form.Label>Тип календаря</Form.Label>
            <Form.Check
              type="radio"
              label="Google"
              value={CalendarType.GOOGLE}
              checked={selectedCalendarType === CalendarType.GOOGLE}
              onChange={handleChange}
            />
            <Form.Check
              type="radio"
              label="Yandex"
              value={CalendarType.YANDEX}
              checked={selectedCalendarType === CalendarType.YANDEX}
              onChange={handleChange}
            />
          </Form.Group>
        </ModalContent>
        <ModalFooter>
          <Button variant="success" onClick={() => onAssignCalendar(selectedCalendarType)}>
            Связать
          </Button>
          <Button variant="danger" onClick={onClose} className="ml-3">
            Закрыть
          </Button>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}
