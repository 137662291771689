import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Wrapper } from 'src/components/hoc/pagination-component/pagination-component'
import { useReactAlert } from '../../../../hooks/useReactAlert'
import TableHeaderSorting from '../../../shared/table-header-sorting'
import { StudentsProjectQueueRow } from '../students-project-queue-row'
import { ProjectQueueDto } from '../../../../model/project-queue-dto/project-queue-dto'
import ProjectQueueService from '../../../../services/project-queue-service'
import { AuthService } from '../../../../services/auth-service'
import { assertRole } from '../../../../utils/assertRole'
import useChannelNames from '../../../../hooks/useChannelNames'
import StudentsProjectQueueDeleteModal from '../students-project-queue-delete-modal'
import '../../../pages/mentor/mentor-page/components/users-page/users-list/user-list.css'

enum SortTypes {
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  DATE_ASC = 'DATE_ASC',
  DATE_DESC = 'DATE_DESC',
}

const StudentsProjectQueueListWrapper: React.FC<Wrapper<ProjectQueueDto>> = ({
  entitiesArray,
  setSortType,
  sortType,
  fetchPage,
}) => {
  const [showOnDeleteStudentFromQueueModal, setShowOnDeleteStudentFromQueueModal] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState<ProjectQueueDto>()

  const currentRole = AuthService.currentUserValue()?.role.name
  const { catchErrorAlert, reactAlert } = useReactAlert()
  const { t } = useTranslation()

  assertRole(currentRole)
  const [projectChannelNames, selectedProjectChannel, handleSelectProjectChannel] = useChannelNames(currentRole)

  const queueService = new ProjectQueueService(currentRole)

  const tableHeaders = [
    {
      header: 'ID',
    },
    {
      header: `${t('Student')}`,
      sortOptions: {
        asc: { action: SortTypes.NAME_ASC, label: `${t('Student')} ↑` },
        desc: { action: SortTypes.NAME_DESC, label: `${t('Student')} ↓` },
      },
    },
    {
      header: t('Email'),
      sortOptions: {
        asc: { action: SortTypes.EMAIL_ASC, label: `${t('Email')} ↑` },
        desc: { action: SortTypes.EMAIL_DESC, label: `${t('Email')} ↑` },
      },
    },
    {
      header: t('EndPreparationDate'),
      sortOptions: {
        asc: { action: SortTypes.DATE_ASC, label: `${t('EndPreparationDate')} ↑` },
        desc: { action: SortTypes.DATE_DESC, label: `${t('EndPreparationDate')} ↓` },
      },
    },
    {
      header: t('WhoBooked'),
    },
    {
      header: t('PaymentType'),
    },
    {
      header: t('Actions'),
    },
  ]

  const onCloseModal = () => {
    setShowOnDeleteStudentFromQueueModal(false)
  }

  const openOnDeleteModal = (id: number) => {
    const selectedStudentFromQueue = entitiesArray.find(element => element.id === id)
    setSelectedStudent(selectedStudentFromQueue)
    setShowOnDeleteStudentFromQueueModal(true)
  }

  const deleteStudentFromQueue = (id: number): void => {
    queueService
      .deleteById(id, { queryParams: selectedProjectChannel })
      .then(() => {
        onCloseModal()
        reactAlert.success('Студент успешно удален из очереди')
      })
      .catch((error: Error) => {
        catchErrorAlert(error)
      })
      .finally(() => {
        if (fetchPage) fetchPage()
      })
  }

  const returnStudentToStudy = (id: number): void => {
    queueService
      .returnEducationById(id)
      .then(() => {})
      .catch((error: Error) => {
        catchErrorAlert(error)
      })
      .finally(() => {
        if (fetchPage) fetchPage()
      })
  }

  const bookStudent = (id: number): void => {
    queueService
      .bookStudentById(id)
      .then(() => {})
      .catch((error: Error) => {
        catchErrorAlert(error)
      })
      .finally(() => {
        if (fetchPage) fetchPage()
      })
  }

  const unbookStudent = (id: number): void => {
    queueService
      .unBookStudentById(id)
      .then(() => {})
      .catch((error: Error) => {
        catchErrorAlert(error)
      })
      .finally(() => {
        if (fetchPage) fetchPage()
      })
  }

  const queueContentRows = entitiesArray.map((user: ProjectQueueDto) => {
    return (
      <StudentsProjectQueueRow
        key={user.id}
        entity={user}
        onReturnEducation={returnStudentToStudy}
        onBook={bookStudent}
        onUnBook={unbookStudent}
        openOnDeleteModal={openOnDeleteModal}
      />
    )
  })

  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped admin-users-table">
          <thead>
            <tr>
              {tableHeaders.map(header => (
                <th key={`${header.header}`}>
                  <TableHeaderSorting header={header} sortType={sortType} setSortType={setSortType} />
                </th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody>{queueContentRows}</tbody>
        </table>
        <StudentsProjectQueueDeleteModal
          showOnDeleteStudentFromQueueModal={showOnDeleteStudentFromQueueModal}
          onCloseModal={onCloseModal}
          selectedStudent={selectedStudent!}
          handleSelectProjectChannel={handleSelectProjectChannel}
          projectChannelNames={projectChannelNames}
          selectedProjectChannel={selectedProjectChannel}
          deleteStudentFromQueue={deleteStudentFromQueue}
        />
      </div>
    </>
  )
}

export { StudentsProjectQueueListWrapper }
