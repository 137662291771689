import React, { Component } from 'react'
import { store } from 'react-notifications-component'
import { confirmAlert } from 'react-confirm-alert'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import ReviewQuestionsList from './components/review-questions-list'
import FinishReviewButton from './components/finish-review-button'
import ReviewStudentsList from './components/review-students-list'
import MentorReviewService from '../../../../services/mentor-services/mentor-review-service'
import ZoomService from '../../../../services/zoom-service'
import Spinner from '../../../spinner'
import StudentDebts from './components/student-debts'
import TheoryReviewTopSidebar from './components/theory-review-top-sidebar'
import MentorQuestionsService from '../../../../services/mentor-services/mentor-questions-service'
import SessionStorageService from '../../../../services/session-storage-service'
import { studentSuccessNotyTemplate } from '../../../../config'

import './theory-review-page.css'
import styles from './theory-review.module.scss'

const reviewService = new MentorReviewService()

const zoomService = new ZoomService()

const questionsService = new MentorQuestionsService()

const sessionStorageService = new SessionStorageService()

class TheoryReviewPage extends Component {
  state = {
    review: {},
    loaded: true,
    selectedStudentsAndQuestions: {
      selectedStudentsReviewId: [],
      selectedQuestionsId: [],
    },
    selectedStudentDebtsInfo: {
      studentQuestions: [],
      studentTasks: [],
      studentReviewId: ``,
      studentName: ``,
      studentId: 0,
      isActualDebts: {},
    },
    studentOldQuestion: [],
    reviewQuestionsList: [],
    filterWords: ``,
    questionType: 'ALL',
    selectedUpdatedQuestionInfo: { id: 0, value: `` },
    studentReviewCommentDtos: [],
  }

  componentDidMount() {
    this.updateReview()
  }

  onReviewLoaded = review => {
    this.setState(
      {
        review,
        loaded: false,
      },
      () => {
        const { questionType } = this.state
        this.onLoadStudentsQuestions()
        this.updateReviewQuestionsList(questionType)
      }
    )
  }

  onLoadStudentsQuestions = () => {
    const {
      selectedStudentDebtsInfo: { studentReviewId },
    } = this.state
    if (studentReviewId === ``) {
      this.defaultStudent()
    } else {
      this.selectedStudent(studentReviewId)
    }
  }

  showStudentDebts = (isActualDebts, studentReviewId) => {
    const { catchErrorAlert } = this.props
    const studentId = this.getStudentIdByStudentReviewId(studentReviewId)
    const studentName = this.getStudentNameByStudentReviewId(studentReviewId)
    const studentTasks = this.getStudentReviewTasksByStudentReviewId(studentReviewId)
    reviewService.getOpenedStudentQuestionsByStudentReviewId(studentReviewId).then(studentQuestions => {
      reviewService
        .getStudentReviewCommentDtos(studentReviewId)
        .then(studentReviewCommentDtos => {
          this.setState({
            selectedStudentDebtsInfo: {
              studentQuestions,
              studentTasks,
              studentReviewId,
              studentName,
              isActualDebts,
              studentId,
            },
            studentReviewCommentDtos,
          })
        })
        .catch(error => catchErrorAlert(error.text))
    })
  }

  defaultStudent = () => {
    const {
      review: { studentReviewsDtos },
    } = this.state
    const { catchErrorAlert } = this.props
    const firstStudentReview = studentReviewsDtos[0]

    if (!firstStudentReview) return

    const { studentReviewId, studentName } = firstStudentReview
    this.showOldStudentsQuestion(studentReviewId)
    reviewService.getOpenedStudentQuestionsByStudentReviewId(studentReviewId).then(studentQuestions => {
      reviewService
        .getStudentReviewCommentDtos(studentReviewId)
        .then(studentReviewCommentDtos => {
          this.setState({
            selectedStudentDebtsInfo: {
              studentQuestions,
              studentReviewId,
              studentName,
              isActualDebts: true,
            },
            studentReviewCommentDtos,
          })
        })
        .catch(error => catchErrorAlert(error.text))
    })
  }

  selectedStudent = studentReviewId => {
    const {
      review: { studentReviewsDtos },
    } = this.state
    const { catchErrorAlert } = this.props
    const studentReview = studentReviewsDtos.find(review => review.studentReviewId === studentReviewId)
    const { studentName } = studentReview
    reviewService.getOpenedStudentQuestionsByStudentReviewId(studentReviewId).then(studentQuestions => {
      reviewService
        .getStudentReviewCommentDtos(studentReviewId)
        .then(studentReviewCommentDtos => {
          this.setState({
            selectedStudentDebtsInfo: {
              studentQuestions,
              studentReviewId,
              studentName,
              isActualDebts: true,
            },
            studentReviewCommentDtos,
          })
        })
        .catch(error => catchErrorAlert(error.text))
    })
  }

  findStudentReviewById = id => {
    const {
      review: { studentReviewsDtos },
    } = this.state
    return studentReviewsDtos.find(sr => sr.studentReviewId === id)
  }

  getStudentReviewTasksByStudentReviewId = id => {
    const studentReview = this.findStudentReviewById(id)
    const { taskReviews } = studentReview
    return taskReviews
  }

  getStudentNameByStudentReviewId = id => {
    const studentReview = this.findStudentReviewById(id)
    const { studentName } = studentReview
    return studentName
  }

  getStudentIdByStudentReviewId = studentReviewId => {
    const studentReview = this.findStudentReviewById(studentReviewId)
    const { studentId } = studentReview
    return studentId
  }

  updateSelectedStudentsReviewId = id => {
    const {
      selectedStudentsAndQuestions: { selectedStudentsReviewId },
    } = this.state
    if (selectedStudentsReviewId.indexOf(id) !== -1) {
      this.removeSelectedStudentReviewId(id)
    } else {
      this.addSelectedStudentReviewId(id)
    }
  }

  updateSelectedQuestionsId = id => {
    const {
      selectedStudentsAndQuestions: { selectedQuestionsId },
    } = this.state
    if (selectedQuestionsId.indexOf(id) !== -1) {
      this.removeSelectedQuestionId(id)
    } else {
      this.addSelectedQuestionId(id)
    }
  }

  sendLinkToChannels = id => {
    const { catchErrorAlert } = this.props
    return link =>
      reviewService
        .sendLinkToChannels(id, link)
        .then(() => {
          store.addNotification({
            ...studentSuccessNotyTemplate,
            message: `Ссылка успешно послана`,
          })
        })
        .catch(error => {
          catchErrorAlert(error)
        })
  }

  addSelectedStudentReviewId = id => {
    this.setState(({ selectedStudentsAndQuestions }) => {
      const { selectedStudentsReviewId, selectedQuestionsId } = selectedStudentsAndQuestions
      const newSelectedStudentsReviewId = [...selectedStudentsReviewId, id]

      return {
        selectedStudentsAndQuestions: {
          selectedStudentsReviewId: newSelectedStudentsReviewId,
          selectedQuestionsId,
        },
      }
    })
  }

  removeSelectedStudentReviewId = id => {
    this.setState(({ selectedStudentsAndQuestions }) => {
      const { selectedStudentsReviewId, selectedQuestionsId } = selectedStudentsAndQuestions
      const index = selectedStudentsReviewId.findIndex(el => el === id)
      const newSelectedStudentsReviewId = [
        ...selectedStudentsReviewId.slice(0, index),
        ...selectedStudentsReviewId.slice(index + 1),
      ]
      return {
        selectedStudentsAndQuestions: {
          selectedStudentsReviewId: newSelectedStudentsReviewId,
          selectedQuestionsId,
        },
      }
    })
  }

  addSelectedQuestionId = id => {
    this.setState(({ selectedStudentsAndQuestions }) => {
      const { selectedStudentsReviewId, selectedQuestionsId } = selectedStudentsAndQuestions
      const newSelectedQuestionId = [...selectedQuestionsId, id]

      return {
        selectedStudentsAndQuestions: {
          selectedStudentsReviewId,
          selectedQuestionsId: newSelectedQuestionId,
        },
      }
    })
  }

  removeSelectedQuestionId = id => {
    this.setState(({ selectedStudentsAndQuestions }) => {
      const { selectedStudentsReviewId, selectedQuestionsId } = selectedStudentsAndQuestions
      const index = selectedQuestionsId.findIndex(el => el === id)
      const newSelectedQuestionsId = [...selectedQuestionsId.slice(0, index), ...selectedQuestionsId.slice(index + 1)]
      return {
        selectedStudentsAndQuestions: {
          selectedStudentsReviewId,
          selectedQuestionsId: newSelectedQuestionsId,
        },
      }
    })
  }

  isAnyStudentAndQuestionSelected = () => {
    const { selectedStudentsAndQuestions } = this.state
    return selectedStudentsAndQuestions.selectedStudentsReviewId.length > 0 &&
      selectedStudentsAndQuestions.selectedQuestionsId.length > 0
      ? this.saveStudentsDebts
      : this.pleaseChooseStudent
  }

  pleaseChooseStudent = () => {
    const { reactAlert } = this.props
    reactAlert.error(`Выберите студента и вопрос`)
  }

  saveStudentsDebts = () => {
    const { catchErrorAlert, reactAlert } = this.props
    const {
      selectedStudentsAndQuestions: { selectedQuestionsId, selectedStudentsReviewId },
    } = this.state
    const data = {
      studentsReviewsId: selectedStudentsReviewId,
      questionsId: selectedQuestionsId,
    }

    reviewService
      .saveStudentsDebts(data)
      .then(() => {
        reactAlert.success(`Вопрос(ы) успешно добавлены`)
        this.setState(
          {
            selectedStudentsAndQuestions: {
              selectedStudentsReviewId: [],
              selectedQuestionsId: [],
            },
          },
          () => {
            this.updateReview()
          }
        )
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  completeTheoryReview = studentReviewId => {
    const { catchErrorAlert, reactAlert } = this.props
    reviewService
      .setTheoryStudentReviewCompleteByStudentReviewId(studentReviewId)
      .then(() => {
        reactAlert.success(`Студент был отмечен, как прошедший ревью`)
        this.updateReview()
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  resetTheoryReview = studentReviewId => {
    const { catchErrorAlert, reactAlert } = this.props
    reviewService
      .resetTheoryStudentReviewCompleteByStudentReviewId(studentReviewId)
      .then(() => {
        reactAlert.success(`Студент был отмечен, как не прошедший ревью`)
        this.updateReview()
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  missTheoryReview = studentReviewId => {
    const { catchErrorAlert, reactAlert } = this.props
    reviewService
      .setTheoryStudentReviewMissedByStudentReviewId(studentReviewId)
      .then(() => {
        reactAlert.success(`Студент был отмечен, как не посетивший ревью`)
        this.updateReview()
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  updateReview = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props
    reviewService.getReviewById(id).then(this.onReviewLoaded)
  }

  finishReview = () => {
    const { history } = this.props
    const { catchErrorAlert } = this.props
    const {
      review: { reviewId },
    } = this.state
    reviewService
      .finishTheoryReview(reviewId)
      .then(() => history.push(`/mentor/review/table`))
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  updateReviewQuestionsList = questionType => {
    const {
      review: { moduleId },
    } = this.state
    questionsService.getQuestionsListByModuleIdForReview(moduleId, questionType).then(this.onListLoaded)
  }

  createNewQuestionsList = reviewQuestionsList => {
    const newQuestionsList = []
    reviewQuestionsList.forEach(question => {
      const newQuestion = {
        ...question,
        isHidden: this.checkHiddenQuestion(question.id),
        isSelected: false,
      }
      newQuestionsList.push(newQuestion)
    })

    return newQuestionsList
  }

  onListLoaded = reviewQuestionsList => {
    const newQuestionsList = this.createNewQuestionsList(reviewQuestionsList)
    this.setState({
      reviewQuestionsList: newQuestionsList,
    })
  }

  onToggleSelected = id => {
    this.setState(({ reviewQuestionsList }) => {
      return {
        reviewQuestionsList: this.toggleProperties(id, reviewQuestionsList, `isSelected`),
      }
    })
    this.updateSelectedQuestionsId(id)
  }

  onToggleHidden = id => {
    this.setState(({ reviewQuestionsList }) => {
      return {
        reviewQuestionsList: this.toggleProperties(id, reviewQuestionsList, `isHidden`),
      }
    })
    sessionStorageService.addItem(`question-${id}`, '')
  }

  cancelIsSelected = (id, reviewQuestionsList) => {
    const index = reviewQuestionsList.findIndex(item => item.id === id)
    const oldQuestion = reviewQuestionsList[index]
    const newQuestion = {
      ...oldQuestion,
      isSelected: false,
    }

    return [...reviewQuestionsList.slice(0, index), newQuestion, ...reviewQuestionsList.slice(index + 1)]
  }

  checkHiddenQuestion = id => {
    return sessionStorageService.getItem(`question-${id}`) !== null
  }

  changeFilterWord = value => {
    this.setState({
      filterWords: value,
    })
  }

  changeQuestionType = questionType => {
    this.setState({
      questionType,
    })
    this.updateReviewQuestionsList(questionType)
  }

  createQuestion = () => {
    const { catchErrorAlert, reactAlert } = this.props
    const {
      review: { moduleId },
      questionType,
    } = this.state
    const { value } = document.getElementById(`question-area`)
    const question = {
      value,
    }

    questionsService
      .createAdditionalQuestionByModuleId(question, moduleId)
      .then(() => {
        reactAlert.success(`Теоретический вопрос успешно создан`)
        this.updateReviewQuestionsList(questionType)
      })
      .catch(err => catchErrorAlert(err))
  }

  deleteQuestion = id => {
    const { catchErrorAlert, reactAlert } = this.props
    const { questionType } = this.state
    questionsService
      .removeQuestionById(id)
      .then(() => {
        reactAlert.success(`Вопрос успешно удален`)
        this.updateReviewQuestionsList(questionType)
      })
      .catch(err => catchErrorAlert(err))
  }

  updateQuestion = () => {
    const { catchErrorAlert, reactAlert } = this.props
    const {
      questionType,
      selectedUpdatedQuestionInfo: { id },
    } = this.state
    const { value } = document.getElementById(`question-area`)

    const question = {
      id,
      value,
    }
    questionsService
      .updateQuestion(question)
      .then(() => {
        reactAlert.success(`Теоретический вопрос успешно обновлен`)
        this.updateReviewQuestionsList(questionType)
      })
      .catch(err => catchErrorAlert(err))
  }

  onToggleDelete = id => {
    confirmAlert({
      title: 'Удаление вопроса',
      message: 'Вы действительно хотите удалить?',
      buttons: [
        {
          label: 'Да',
          onClick: () => this.deleteQuestion(id),
        },
        {
          label: 'Нет',
        },
      ],
    })
  }

  onToggleUpdate = id => {
    const { reviewQuestionsList } = this.state
    const question = reviewQuestionsList.find(el => el.id === id)
    const { value } = question

    this.setState({
      selectedUpdatedQuestionInfo: {
        id,
        value,
      },
    })
  }

  updateStateSelectedQuestions = () => {
    const { reviewQuestionsList } = this.state
    reviewQuestionsList.forEach(question => {
      const { id } = question
      this.setState(({ reviewQuestionsList: oldReviewQuestionsList }) => {
        return {
          reviewQuestionsList: this.cancelIsSelected(id, oldReviewQuestionsList),
        }
      })
    })
  }

  toggleProperties = (id, reviewQuestionsList, properties) => {
    const index = reviewQuestionsList.findIndex(item => item.id === id)
    const oldQuestion = reviewQuestionsList[index]
    const newQuestion = {
      ...oldQuestion,
      [properties]: !oldQuestion[properties],
    }

    return [...reviewQuestionsList.slice(0, index), newQuestion, ...reviewQuestionsList.slice(index + 1)]
  }

  showAllHiddenQuestions = () => {
    const { reviewQuestionsList } = this.state
    reviewQuestionsList.forEach(question => {
      const { id, isHidden } = question
      if (isHidden) {
        this.onToggleHidden(id)
        sessionStorageService.removeItem(`question-${id}`)
      }
    })
  }

  creatOrUpdateStudentReviewComment = (studentReviewId, comment) => {
    const { catchErrorAlert } = this.props
    reviewService
      .creatOrUpdateStudentReviewComment(studentReviewId, comment)
      .then(response => {
        this.setState({ studentReviewCommentDtos: response })
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  deleteStudentReviewComment = studentReviewId => {
    const { catchErrorAlert } = this.props
    reviewService
      .deleteStudentReviewCommentByStudentReviewId(studentReviewId)
      .then(response => {
        this.setState({ studentReviewCommentDtos: response })
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  closeQuestion = studentQuestionId => {
    const { catchErrorAlert, reactAlert } = this.props
    reviewService
      .closeStudentQuestionByStudentReviewId(studentQuestionId)
      .then(() => {
        reactAlert.success(`Вопрос успешно закрыт`)
        this.updateReview()
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  allStudentsMarkedTheoryReview = () => {
    const {
      review: { studentReviewsDtos },
    } = this.state
    const studentReviewsIsNullTheoryHasReviewed = studentReviewsDtos.filter(
      studentReview => studentReview.reviewStatus === 'WAITED'
    )

    return studentReviewsIsNullTheoryHasReviewed.length === 0
  }

  // методы для работы со старыми вопросами на ревью
  showOldStudentsQuestion = studentReviewId => {
    const { catchErrorAlert } = this.props
    reviewService
      .getAllOldQuestionByStudentsReviewId(studentReviewId)
      .then(studentOldQuestion => {
        this.setState(() => {
          return {
            studentOldQuestion,
          }
        })
      })
      .catch(error => catchErrorAlert(error))
  }

  createMeeting = () => {
    const {
      review: { reviewId },
    } = this.state
    const { catchErrorAlert } = this.props
    zoomService
      .createMeeting(reviewId)
      .then(link => {
        this.setState(oldState => {
          return {
            review: {
              ...oldState.review,
              link,
            },
          }
        })
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  render() {
    const {
      review: { reviewId, studentReviewsDtos, moduleId, moduleName, reviewTime, theoryHasReviewed, zoomAcc, link },
      studentOldQuestion,
      loaded,
      selectedStudentsAndQuestions,
      selectedStudentDebtsInfo,
      reviewQuestionsList,
      filterWords,
      selectedUpdatedQuestionInfo,
      studentReviewCommentDtos,
    } = this.state

    if (loaded) {
      return (
        <div className="loader">
          <Spinner />
        </div>
      )
    }

    return (
      <div className={styles.page}>
        <TheoryReviewTopSidebar
          selectedStudentDebtsInfo={selectedStudentDebtsInfo}
          showAllHiddenQuestions={this.showAllHiddenQuestions}
          changeFilterWord={this.changeFilterWord}
          changeQuestionType={this.changeQuestionType}
          createQuestion={this.createQuestion}
          updateQuestion={this.updateQuestion}
          selectedUpdatedQuestionInfo={selectedUpdatedQuestionInfo}
          filterWords={filterWords}
        />
        <main className={styles.main}>
          <StudentDebts
            studentOldQuestion={studentOldQuestion}
            selectedStudentDebtsInfo={selectedStudentDebtsInfo}
            closeQuestion={this.closeQuestion}
            reviewId={reviewId}
            updateReview={this.updateReview}
            moduleName={moduleName}
            reviewTime={reviewTime}
            studentReviewCommentDtos={studentReviewCommentDtos}
            creatOrUpdateStudentReviewComment={this.creatOrUpdateStudentReviewComment}
            deleteStudentReviewComment={this.deleteStudentReviewComment}
          />
          <ReviewStudentsList
            showStudentDebts={this.showStudentDebts}
            showOldStudentsQuestion={this.showOldStudentsQuestion}
            studentReviewsList={studentReviewsDtos}
            updateSelectedStudents={this.updateSelectedStudentsReviewId}
            selectedStudentsAndQuestions={selectedStudentsAndQuestions}
            completeReview={this.completeTheoryReview}
            resetTheoryReview={this.resetTheoryReview}
            missTheoryReview={this.missTheoryReview}
            saveStudentsDebts={this.isAnyStudentAndQuestionSelected()}
            zoomAcc={zoomAcc}
            sendLinkToChannels={this.sendLinkToChannels(reviewId)}
            linkValue={link}
            createMeeting={this.createMeeting}
          />
          <div className="right-question-bar">
            <ReviewQuestionsList
              reviewQuestionsList={reviewQuestionsList}
              onToggleHidden={this.onToggleHidden}
              onToggleSelected={this.onToggleSelected}
              updateStateSelectedQuestions={this.updateStateSelectedQuestions}
              moduleId={moduleId}
              selectedStudentsAndQuestions={selectedStudentsAndQuestions}
              onToggleDelete={this.onToggleDelete}
              onToggleUpdate={this.onToggleUpdate}
              filterWords={filterWords}
            />
          </div>
          <FinishReviewButton
            theoryHasReviewed={theoryHasReviewed}
            finishReview={this.finishReview}
            allStudentsMarkedTheoryReview={this.allStudentsMarkedTheoryReview}
          />
        </main>
      </div>
    )
  }
}

export default withReactAlert(TheoryReviewPage)
