import qs from 'query-string'
import { StudentReviewStatisticDto } from 'src/model/student-model'
import { StudentDeadlineDto, ExpellingReasonRequestDto } from 'src/model/student-dto/student-dto'
import { RecruitmentStudentMeetStatusHistoryDto } from 'src/model/recruitment-dto/recruitment-dto'
import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { HTTPMethods } from '../http-enums-methods'
import { CuratorStudentServiceInterface } from '../interfaces/curator-services/curator-student-service'

export class CuratorStudentService implements CuratorStudentServiceInterface {
  private readonly sourceURL = `${serverHost}/api/curator/student`

  public groupSingleStudent = (studentId: number) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/postpaid/group?${qs.stringify({
      recruitmentId: studentId,
    })}`
    return ApiService.request<void>(url, queryOption)
  }

  public groupStudents = (studentsIds: number[], recruitmentId: number) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: {
        ...AuthService.authHeaders(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(studentsIds),
    }
    const url = `${this.sourceURL}/postpaid/group?${qs.stringify({
      recruitmentId,
    })}`
    return ApiService.request<void>(url, queryOption)
  }

  public enrollStudentsGroup = (studentsIds: number[], recruitmentGroupId: number) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        ...AuthService.authHeaders(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(studentsIds),
    }
    const url = `${this.sourceURL}/groupStudent?${qs.stringify({
      recruitmentGroupId,
    })}`
    return ApiService.request<void>(url, queryOption)
  }

  public returningStudent = (studentId: number, recruitmentGroupId: number) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${studentId}/returning?${qs.stringify({
      recruitmentGroupId,
    })}`
    return ApiService.request<void>(url, queryOption)
  }

  public transferStudent = (studentId: number, recruitmentGroupId: number) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${studentId}/transfer?${qs.stringify({
      recruitmentGroupId,
    })}`
    return ApiService.request<void>(url, queryOption)
  }

  public expellingStudent = ({ studentId, reason, comment }: { studentId: number } & ExpellingReasonRequestDto) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: {
        ...AuthService.authHeaders(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ reason, comment }),
    }
    const url = `${this.sourceURL}/${studentId}/expelling`
    return ApiService.request<void>(url, queryOption)
  }

  public getStudentReviewStatistic = (payStudentId: number) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${payStudentId}/reviewStatistic`
    return ApiService.request<StudentReviewStatisticDto[]>(url, queryOption)
  }

  public getStudentMeetHistory = (payStudentId: number) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${payStudentId}/meet-history`
    return ApiService.request<RecruitmentStudentMeetStatusHistoryDto[]>(url, queryOption)
  }

  public completeDirection = (payStudentId: number) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${payStudentId}/completeDirection`
    return ApiService.request<void>(url, queryOption)
  }

  public getDeadlines = (payStudentId: number) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${payStudentId}/deadline`
    return ApiService.request<StudentDeadlineDto[]>(url, queryOption)
  }

  public updateDeadlines = (payStudentId: number, newDeadlines: StudentDeadlineDto[]) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: {
        ...AuthService.authHeaders(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newDeadlines),
    }
    const url = `${this.sourceURL}/${payStudentId}/deadline`
    return ApiService.request<void>(url, queryOption)
  }
}

export const curatorStudentService = new CuratorStudentService()
