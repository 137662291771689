import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { Link } from 'react-router-dom'
import './planning-review-modal.css'

import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import MentorReviewService from 'src/services/mentor-services/mentor-review-service'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../../../../shared/modal-view'
import { ReviewType } from '../../../../../../../../model/review/review-type'

const reviewService = new MentorReviewService()

const PlanningReviewModal = React.memo(function PlanningReviewModal({
  courses,
  modules,
  students,
  selectedDate,
  selectedTime,
  selectedCourseId,
  selectedModuleId,
  selectedMentorId,
  selectedMentorName,
  selectedZoomAccount,
  modalShowed,
  setDefaultReviewData,
  onClose,
  curUser,
  onAssignReview,
  onRefuseReview,
  selectedCodeHasReviewed,
  selectedReviewId,
  selectedTheoryHasReviewed,
  reviewDateTime,
  availableSlots,
  reviewType,
  onShowEditReviewModal,
}) {
  const [isPracticeReviewAvailable, setPracticeReviewAvailable] = useState(false)
  useEffect(() => {
    reviewService.getReviewDtoForCodeReviewById(selectedReviewId).then(codeReviewDto => {
      if (codeReviewDto.studentReviewsDtos.length !== 0) {
        reviewService.getAllCodeTaskToStudent(codeReviewDto.studentReviewsDtos[0].studentReviewId).then(codeTasks => {
          if (Object.entries(codeTasks).length > 0) {
            setPracticeReviewAvailable(true)
          } else {
            setPracticeReviewAvailable(false)
          }
        })
      } else {
        setPracticeReviewAvailable(false)
      }
    })
  }, [selectedReviewId])

  const selectedCourse = courses.find(course => course.id === selectedCourseId)
  const selectedModule = modules.find(module => module.id === selectedModuleId)
  const MIN_DELAY = 250

  const studRow = students.map(dto => {
    const { reviewStatus } = dto
    let classes = 'student-row '
    let mdiClasses = 'mdi mdi-row '
    let studentRowIcon = 'student-row-icon '
    // eslint-disable-next-line default-case
    switch (reviewStatus) {
      case 'WAITED':
        classes += 'gray'
        studentRowIcon += 'gray'
        break
      case `SUCCESS`:
        classes += 'green'
        mdiClasses += 'mdi-check'
        studentRowIcon += 'green'
        break
      case `FAILED`:
        classes += 'rad'
        mdiClasses += 'mdi-block-helper'
        studentRowIcon += 'rad'
        break
      case `MISSED`:
        classes += 'orange'
        mdiClasses += 'mdi-account-minus'
        studentRowIcon += 'orange'
        break
    }

    return (
      <>
        <div key={dto.studentReviewId} className={classes}>
          <span>{dto.studentName}</span>
        </div>
        <div className={studentRowIcon}>
          <i className={mdiClasses} />
        </div>
      </>
    )
  })

  const renderNoPracticeTasksTooltip = props => (
    <Tooltip id="tooltip" className="tooltip" {...props}>
      Отсутствуют задачи для проверки
    </Tooltip>
  )

  const renderDisabledTooltip = props => (
    <Tooltip id="tooltip" className="tooltip" {...props}>
      Отсутствуют студенты для проверки
    </Tooltip>
  )

  const areStudentsSignUpForCurrentReview = !!students.length
  const reviewDidNotPass = !selectedCodeHasReviewed && reviewDateTime > new Date()
  const mentorNotAssigned = reviewType === ReviewType.GROUP && reviewDidNotPass && selectedMentorId === 0
  const curMentorAssigned = curUser.id === selectedMentorId
  const canRefuse = reviewType === ReviewType.GROUP && curMentorAssigned
  const canEdit = reviewType === ReviewType.PERSONAL_MENTOR && reviewDidNotPass && curMentorAssigned

  const groupButton = () => {
    return (
      <>
        {canEdit && (
          <ModalButton action="default" onClick={onShowEditReviewModal}>
            Изменить
          </ModalButton>
        )}
        {mentorNotAssigned && (
          <ModalButton action="assign" onClick={onAssignReview}>
            Взять ревью
          </ModalButton>
        )}
        {canRefuse && (
          <ModalButton action="refuse" onClick={onRefuseReview}>
            Отказать от ревью
          </ModalButton>
        )}
        <ModalButton
          action="back"
          onClick={() => {
            onClose()
            setDefaultReviewData()
          }}
        >
          Назад
        </ModalButton>
      </>
    )
  }
  ReactModal.setAppElement('#root')

  return (
    <ReactModal isOpen={modalShowed} onRequestClose={onClose} style={customStyles}>
      <ModalWrapper>
        <ModalHeader>Информация о ревью</ModalHeader>
        <ModalContent>
          <div className="planning-review">
            <div className="main">
              <span className="label-box">
                <label>
                  Дата ревью: {selectedDate} в {selectedTime}
                </label>
              </span>
              <span className="label-box">
                <label>Курс: {selectedCourse ? selectedCourse.name : null}</label>
              </span>
              <span className="label-box">
                <label>Модуль: {selectedModule ? selectedModule.name : null}</label>
              </span>
              <span className="label-box">
                <label>Zoom аккаунт: {selectedZoomAccount}</label>
              </span>
              <span className="label-box">
                <label>Ментор: {selectedMentorName || null}</label>
              </span>
              <div className="code-review">
                <div>
                  <label>Статус проверки практических задач:</label>
                </div>
                <div className="icon-block">
                  {selectedCodeHasReviewed ? <i className="mdi mdi-check check-review" /> : null}
                </div>
                <div className="link-block">
                  {areStudentsSignUpForCurrentReview && isPracticeReviewAvailable ? (
                    <Link to={`/mentor/review/code/${selectedReviewId}`} className="link-code-review">
                      {selectedCodeHasReviewed ? `посмотреть результаты` : `перейти к проверке`}
                    </Link>
                  ) : (
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: MIN_DELAY, hide: MIN_DELAY }}
                      overlay={isPracticeReviewAvailable ? renderDisabledTooltip : renderNoPracticeTasksTooltip}
                    >
                      <Link
                        to={`/mentor/review/code/${selectedReviewId}`}
                        className="link-code-review disabled-link"
                        onClick={e => e.preventDefault()}
                      >
                        {selectedCodeHasReviewed ? `посмотреть результаты` : `перейти к проверке`}
                      </Link>
                    </OverlayTrigger>
                  )}
                </div>
              </div>
              <div className="code-review">
                <div>
                  <label>Статус проверки теории:</label>
                </div>
                <div className="icon-block">
                  {selectedTheoryHasReviewed ? <i className="mdi mdi-check check-review" /> : null}
                </div>
                <div className="link-block">
                  {areStudentsSignUpForCurrentReview ? (
                    <Link to={`/mentor/review/theory/${selectedReviewId}`} className="link-code-review">
                      {selectedTheoryHasReviewed ? `посмотреть результаты` : `перейти к проверке`}
                    </Link>
                  ) : (
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: MIN_DELAY, hide: MIN_DELAY }}
                      overlay={renderDisabledTooltip}
                    >
                      <Link
                        to={`/mentor/review/theory/${selectedReviewId}`}
                        className="link-code-review disabled-link"
                        onClick={e => e.preventDefault()}
                      >
                        {selectedTheoryHasReviewed ? `посмотреть результаты` : `перейти к проверке`}
                      </Link>
                    </OverlayTrigger>
                  )}
                </div>
              </div>
              <div className="students">
                <div className="students-header">
                  <div>Студенты (Максимальное количество - {availableSlots || 'не настроено'}):</div>
                  {selectedTheoryHasReviewed ? null : (
                    <Link
                      className="mdi mdi-pencil mentor-page-edit-review-button"
                      to={`/mentor/review/add?reviewId=${selectedReviewId}`}
                      title="Редактировать список студентов"
                    />
                  )}
                </div>
                <div className="student-table">{studRow}</div>
              </div>
            </div>
          </div>
        </ModalContent>
        <ModalFooter>{groupButton()}</ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
})

export default PlanningReviewModal
