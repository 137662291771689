import React, { useMemo } from 'react'
import { FormControl } from 'react-bootstrap'
import { ReviewType } from 'src/model/review/review-type'

import './chief-planning-reviews-action-panel.css'

const ChiefPlanningReviewsActionPanel = React.memo(function ChiefPlanningReviewsActionPanel({
  selectedDate,
  setSelectedDate,
  onChangeReviewsByDate,
  onOpenCopyReviewModal,
  selectedReviewType,
  onSelectReviewType,
  selectedCourse,
  courses,
  onChangeCourse,
  modules,
  selectedModule,
  onChangeModule,
  mentors,
  selectedMentor,
  onChangeMentor,
}) {
  const onDateChange = event => {
    const inputValue = event.target.value
    if (inputValue) {
      setSelectedDate(new Date(inputValue))
    }
  }

  const reviewTypesOptions = Object.values(ReviewType).map(reviewType => {
    const reviewTypeLabel = {
      PERSONAL_MENTOR: 'Персональное',
      GROUP: 'Групповое',
    }

    return (
      <option key={`course-id-${reviewType}`} value={reviewType}>
        {reviewTypeLabel[reviewType]}
      </option>
    )
  })

  const coursesOptions = courses?.map(course => {
    const { id, name } = course
    return (
      <option key={`course-id-${id}`} value={id}>
        {name}
      </option>
    )
  })

  const modulesOptions = useMemo(() => {
    return modules?.map(module => {
      const { id, name } = module
      return (
        <option key={`module-id-${id}${selectedCourse?.id}`} value={id}>
          {name}
        </option>
      )
    })
  }, [selectedCourse?.id, modules])

  const mentorsOptions = mentors?.map(mentor => {
    const { id, firstName, lastName } = mentor
    return (
      <option key={`module-id-${id}`} value={id}>
        {`${firstName} ${lastName}`}
      </option>
    )
  })

  const defaultOption = <option key="defaultOption">-</option>

  return (
    <span className="chief-review-action-panel">
      <label>
        Групповые ревью за:
        <input
          type="date"
          value={selectedDate.toISOString().substring(0, 10)}
          className="form-control ScheduleSettings__date"
          onChange={onDateChange}
        />
      </label>

      <label>
        Тип ревью:
        <FormControl
          as="select"
          value={selectedReviewType}
          onChange={e => {
            const newReviewType = e.target.value
            if (onSelectReviewType) {
              onSelectReviewType(
                Object.values(ReviewType).some(reviewType => reviewType === newReviewType) ? newReviewType : undefined
              )
            }
          }}
          className="review-filter-selector"
        >
          {reviewTypesOptions ? [defaultOption, ...reviewTypesOptions] : defaultOption}
        </FormControl>
      </label>

      <label>
        Курс:
        <FormControl
          as="select"
          value={selectedCourse?.id}
          onChange={e => onChangeCourse && onChangeCourse(e)}
          className="review-filter-selector"
        >
          {coursesOptions ? [defaultOption, ...coursesOptions] : defaultOption}
        </FormControl>
      </label>

      <label>
        Модуль:
        <FormControl
          as="select"
          disabled={!selectedCourse || !modules?.length}
          value={selectedModule?.id}
          onChange={e => onChangeModule && onChangeModule(e)}
          className="review-filter-selector"
        >
          {modulesOptions ? [defaultOption, ...modulesOptions] : defaultOption}
        </FormControl>
      </label>

      <label>
        Ментор:
        <FormControl
          as="select"
          value={selectedMentor?.id}
          onChange={e => onChangeMentor && onChangeMentor(e)}
          className="review-filter-selector"
        >
          {mentorsOptions ? [defaultOption, ...mentorsOptions] : defaultOption}
        </FormControl>
      </label>

      <button
        type="button"
        className="btn btn-primary chief-review-action-panel-btn"
        id="chief-review-action-button"
        onClick={onChangeReviewsByDate}
      >
        Запросить план
      </button>
      <button
        type="button"
        className="btn btn-primary chief-review-action-panel-btn"
        id="chief-review-copy-review-button"
        onClick={onOpenCopyReviewModal}
      >
        Скопировать план
      </button>
    </span>
  )
})

export default ChiefPlanningReviewsActionPanel
