import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { Button } from 'react-bootstrap'
import ReactModal from 'react-modal'
import { ProjectQueueDto } from '../../../../model/project-queue-dto/project-queue-dto'
import { AuthService } from '../../../../services/auth-service'
import { RoleEnum } from '../../../../utils/select-state/RoleEnum'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { PaymentType } from '../../../../model/payment-type-enum'

const paymentTypes = {
  [PaymentType.UNTIL_PAID]: 'Постоплата',
  [PaymentType.REGULAR]: 'Предоплата',
  [PaymentType.TRIAL]: 'Пробный период',
}

interface Props {
  entity: ProjectQueueDto
  onReturnEducation: (id: number) => void
  onBook: (id: number) => void
  onUnBook: (id: number) => void
  openOnDeleteModal: (id: number) => void
}

const StudentsProjectQueueRow: React.FC<Props> = ({
  entity,
  onReturnEducation,
  onBook,
  onUnBook,
  openOnDeleteModal,
}) => {
  const { id, username, email, bookedByMentorName, bookingMentorId, queueDate, paymentType } = entity
  const currentUser = AuthService.currentUserValue()
  const isBooked = bookingMentorId !== null
  const isBookedByCurrentUser = isBooked && bookingMentorId === currentUser!.id
  const permittedRolesForBooking = [RoleEnum.MENTOR]
  const isCanBook = permittedRolesForBooking.includes(currentUser!.role?.name)

  ReactModal.setAppElement('#root')

  const confirmOnReturnEducation = (studentId: number) => {
    const alertChildrenElement = () => (
      <div>
        <p>
          Вы действительно хотите вернуть студента <strong>{username}</strong> на обучение? Студен снова будет доступен
          в{/* eslint-disable-next-line react/no-unescaped-entities */}
          разделе "Аналитика по студентам" у своего ментора
        </p>
      </div>
    )
    confirmAlert({
      title: 'Вернуть на обучение',
      childrenElement: alertChildrenElement,
      buttons: [
        {
          label: 'Подтвердить',
          onClick: () => onReturnEducation(studentId),
        },
        {
          label: 'Отмена',
          onClick: () => {},
        },
      ],
    })
  }

  const confirmOnBookStudent = (studentId: number) => {
    const alertChildrenElement = () => (
      <div>
        <p>
          Вы действительно хотите забронировать студента <strong>{username}</strong> ?
        </p>
      </div>
    )
    confirmAlert({
      title: 'Забронировать студента',
      childrenElement: alertChildrenElement,
      buttons: [
        {
          label: 'Подтвердить',
          onClick: () => onBook(studentId),
        },
        {
          label: 'Отмена',
          onClick: () => {},
        },
      ],
    })
  }

  const confirmOnUnBookStudent = (studentId: number) => {
    const alertChildrenElement = () => (
      <div>
        <p>
          Вы действительно хотите рабронировать студента <strong>{username}</strong> ?
        </p>
      </div>
    )
    confirmAlert({
      title: 'Рабронировать студента',
      childrenElement: alertChildrenElement,
      buttons: [
        {
          label: 'Подтвердить',
          onClick: () => onUnBook(studentId),
        },
        {
          label: 'Отмена',
          onClick: () => {},
        },
      ],
    })
  }

  return (
    <>
      <tr>
        <td>{id}</td>
        <td>{username}</td>
        <td>{email}</td>
        <td>{queueDate}</td>
        <td>{bookedByMentorName ?? '-'}</td>
        <td>{paymentTypes[paymentType]}</td>
        <td style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>
            {(!isBooked || isBookedByCurrentUser) && (
              <Button
                className="StudentAnalyticsTable__action-button mb-2"
                variant="success"
                title="Забрать"
                size="sm"
                onClick={() => {
                  openOnDeleteModal(id)
                }}
              >
                Забрать
              </Button>
            )}{' '}
            {!isBooked && isCanBook && (
              <Button
                className="StudentAnalyticsTable__action-button mb-2"
                variant="primary"
                title="Забронировать"
                size="sm"
                onClick={() => confirmOnBookStudent(id)}
              >
                Забронировать
              </Button>
            )}{' '}
            {isBookedByCurrentUser && (
              <Button
                className="StudentAnalyticsTable__action-button mb-2"
                variant="outline-primary"
                title="Снять бронь"
                size="sm"
                onClick={() => confirmOnUnBookStudent(id)}
              >
                Снять бронь
              </Button>
            )}{' '}
            {(!isBooked || isBookedByCurrentUser) && (
              <Button
                className="StudentAnalyticsTable__action-button mb-2"
                variant="warning"
                title="Вернуть на обучение"
                size="sm"
                onClick={() => confirmOnReturnEducation(id)}
              >
                Вернуть на обучение
              </Button>
            )}{' '}
          </div>
        </td>
      </tr>
    </>
  )
}

export { StudentsProjectQueueRow }
