import { PageDto } from 'src/model/page-dto'
import { RecruitmentGroupStudentStatus } from '../../../model/student-dto/student-dto'

export enum RecruitmentGroupSortType {
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  CHANNEL_NAME_ASC = 'CHANNEL_NAME_ASC',
  CHANNEL_NAME_DESC = 'CHANNEL_NAME_DESC',
  DIRECTION_ASC = 'DIRECTION_ASC',
  DIRECTION_DESC = 'DIRECTION_DESC',
  RECRUITMENT_ASC = 'RECRUITMENT_ASC',
  RECRUITMENT_DESC = 'RECRUITMENT_DESC',
  MENTOR_ASC = 'MENTOR_ASC',
  MENTOR_DESC = 'MENTOR_DESC',
}

export type RecruitmentGroupPageParams = {
  pageNumber: number
  filterPattern?: string
  sortType?: RecruitmentGroupSortType
  directionId?: number | null
  mentorId?: number | null
  recruitmentId?: number | null
  onlySelf?: boolean
}

export type RecruitmentGroupPageDto = {
  id: number
  name: string
  channelName: string | null
  directionName: string
  recruitmentChannel: string
  mentorName: string | null
}

export interface CuratorGroupServiceInterface {
  getRecruitmentGroupPage(params: RecruitmentGroupPageParams): Promise<PageDto<RecruitmentGroupPageDto>>
}

interface RecruitmentGroupStudentDto {
  id: number
  firstName: string
  lastName: string
  email: string
  status: RecruitmentGroupStudentStatus
}

export interface RecruitmentGroupStudentsInfoDto {
  recruitmentGroupId: number
  name: string
  channelName: string | null
  students: RecruitmentGroupStudentDto[]
}
