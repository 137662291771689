import React from 'react'
import { Form, Formik } from 'formik'
import { Accordion, FormControl } from 'react-bootstrap'
import produce from 'immer'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import { ReactAlert, CatchErrorAlert } from 'src/hooks/useReactAlert'
import CourseService from 'src/services/admin-services/admin-course-service'
import { ChapterDto, ChapterTaskDto, Chapter } from 'src/model/chapter-dto/chapter-dto'
import AdminModuleService from '../../../../../../services/admin-services/admin-module-service'
import AdminChapterService from '../../../../../../services/admin-services/admin-chapter-service'
import ModuleItem from './module-item'
import {
  addItem,
  addItemByIndex,
  deleteItemByIndex,
  getIndexByKeyValue,
  sortArrayByKey,
} from '../../../../../../utils/ArraysUtils'
import AddTaskModal from './add-task-modal/add-task-modal'
import AdminCourseTaskService from '../../../../../../services/admin-services/admin-course-task-service'
import ModuleModal from './module-modal'

import './modules-block.css'
import ChapterModal from './chapter-modal'
import { ModuleDto, Module } from '../../../../../../model/module-dto/module-dto'

interface Props {
  courseId: number
  reactAlert: ReactAlert
  catchErrorAlert: CatchErrorAlert
}

interface State {
  modules: ModuleDto[]
  openedModuleId: number
  chapters: ChapterDto[]
  courseTasksLoaded: boolean
  chaptersLoaded: boolean
  courseTasks: ChapterTaskDto[]
  openedChapterId: number
  showAddForm: boolean
  editingModule: ModuleDto | {}
  editingChapter: ChapterDto | {}
  showModuleForm: boolean
  showChapterForm: boolean
}

interface ChangeIndexObjInterface {
  oldIndex: number
  newIndex: number
}

const service = new CourseService()
const modulesService = new AdminModuleService()
const chapterService = new AdminChapterService()
const courseTasksService = new AdminCourseTaskService()

class ModulesBlock extends React.Component<Props, State> {
  state: State = {
    modules: [],
    chapters: [],
    courseTasksLoaded: false,
    chaptersLoaded: false,
    courseTasks: [],
    openedModuleId: -1,
    openedChapterId: -1,
    showAddForm: false,
    editingModule: {},
    editingChapter: {},
    showModuleForm: false,
    showChapterForm: false,
  }

  componentDidMount() {
    const { courseId = 0, catchErrorAlert } = this.props
    if (courseId > 0) {
      service
        .getCourseModules(courseId)
        .then(modules => this.setState({ modules }))
        .catch(error => catchErrorAlert(error))
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { openedModuleId, openedChapterId } = this.state
    const { catchErrorAlert } = this.props
    if (openedModuleId !== -1 && openedModuleId !== prevState.openedModuleId) {
      modulesService
        .getChaptersByModuleId(openedModuleId)
        .then(chapters =>
          this.setState({
            chapters: sortArrayByKey(chapters, 'position'),
            chaptersLoaded: true,
          })
        )
        .catch(error => {
          if (catchErrorAlert) catchErrorAlert(error)
        })
    }

    if (openedChapterId !== -1 && openedChapterId !== prevState.openedChapterId) {
      this.loadCourseTasks()
    }
  }

  onChooseModule = (id: number) => () => {
    const { openedModuleId } = this.state
    this.setState({
      openedModuleId: openedModuleId === id ? -1 : id,
      openedChapterId: -1,
      chaptersLoaded: false,
    })
  }

  onChooseChapter = (id: number) => () => {
    const { openedChapterId } = this.state
    this.setState({
      openedChapterId: openedChapterId === id ? -1 : id,
      courseTasksLoaded: false,
    })
  }

  loadCourseTasks = () => {
    const { openedChapterId } = this.state
    const { catchErrorAlert, reactAlert } = this.props
    if (openedChapterId === -1) {
      reactAlert.error('ошибка в загрузке задания, по причине неправильного выбора главы')
      return
    }
    chapterService
      .getCourseTasksByChapterId(openedChapterId)
      .then(courseTasks =>
        this.setState({
          courseTasksLoaded: true,
          courseTasks: sortArrayByKey(courseTasks, 'position'),
        })
      )
      .catch(err => {
        if (catchErrorAlert) catchErrorAlert(err)
      })
  }

  onAddModule = (module: Module) => {
    const { catchErrorAlert } = this.props
    const { courseId } = this.props
    service
      .addModule(courseId, module)
      .then(newModule => {
        const { modules } = this.state
        this.setState({ modules: addItem(modules, newModule) })
      })
      .catch(err => {
        if (catchErrorAlert) catchErrorAlert(err)
      })
  }

  onSaveModule = (savedModule: ModuleDto) => {
    const { catchErrorAlert, reactAlert } = this.props
    return modulesService
      .update(savedModule)
      .then(() => {
        const { modules } = this.state
        const idx = getIndexByKeyValue(modules, 'id', savedModule.id)
        const module = {
          ...modules[idx],
          name: savedModule.name,
          description: savedModule.description,
        }
        this.setState({
          modules: this.restorePosition<ModuleDto>(addItemByIndex(deleteItemByIndex(modules, idx), idx, module)),
          showModuleForm: false,
        })
      })
      .catch(err => {
        if (catchErrorAlert) catchErrorAlert(err)
      })
  }

  onAddChapterToModule = (chapter: Chapter) => {
    const { courseId, catchErrorAlert, reactAlert } = this.props
    const { openedModuleId } = this.state
    if (openedModuleId === -1) {
      reactAlert.error('ошибка: неверный id модуля')
      return
    }
    service
      .addChapter(courseId, openedModuleId, chapter)
      .then(newChapter => {
        reactAlert.success(`Глава успешно добавлена`)
        const { chapters } = this.state
        this.setState({ chapters: [...chapters, newChapter] })
      })
      .catch(err => {
        if (catchErrorAlert) catchErrorAlert(err)
      })
  }

  onSaveChapter = (savedChapter: ChapterDto) => {
    const { catchErrorAlert, reactAlert } = this.props
    const { id, name } = savedChapter
    chapterService
      .updateChapterById(id, name)
      .then(() => {
        reactAlert.success(`Глава успешно обновлена`)
        const { chapters } = this.state
        const index = chapters.findIndex(element => element.id === id)
        const newChapters = [...chapters.slice(0, index), savedChapter, ...chapters.slice(index + 1)]
        this.setState({ chapters: newChapters })
        this.hideChapterForm()
      })
      .catch(err => {
        if (catchErrorAlert) catchErrorAlert(err)
      })
  }

  updateChapterTopics = (chapterId: number, topics: string) => {
    const { catchErrorAlert, reactAlert } = this.props
    const { chapters } = this.state
    const newTopics = topics.split(';')
    chapterService
      .updateChapterTopicsById(chapterId, newTopics)
      .then(() => {
        reactAlert.success(`Список тем успешно обновлён`)
        const index = chapters.findIndex(element => element.id === chapterId)
        const chapter = chapters[index]
        if (!chapter) {
          reactAlert.error('ошибка: возникла ошибка при выборе главы')
          return
        }
        const newChapter = {
          ...chapter,
          topics: newTopics,
        }
        const newChapters = [...chapters.slice(0, index), newChapter, ...chapters.slice(index + 1)]
        this.setState({ chapters: newChapters })
      })
      .catch(err => {
        if (catchErrorAlert) catchErrorAlert(err)
      })
  }

  onDeleteChapter = (chapterId: number) => {
    const { catchErrorAlert, reactAlert } = this.props
    chapterService
      .deleteById(chapterId)
      .then(() => {
        reactAlert.success(`Глава успешно удалена`)
        const { chapters } = this.state
        const idx = getIndexByKeyValue(chapters, 'id', chapterId)
        this.setState({ chapters: deleteItemByIndex(chapters, idx) })
      })
      .catch(err => {
        if (catchErrorAlert) catchErrorAlert(err)
      })
  }

  onDeleteModule = (moduleId: number) => () => {
    const { catchErrorAlert } = this.props
    modulesService
      .deleteById(moduleId)
      .then(() => {
        const { modules } = this.state
        const idx = getIndexByKeyValue(modules, 'id', moduleId)
        this.setState({ modules: deleteItemByIndex(modules, idx) })
      })
      .catch(err => {
        if (catchErrorAlert) catchErrorAlert(err)
      })
  }

  onAddCourseTask = (id: number) => {
    const { courseId, catchErrorAlert, reactAlert } = this.props
    const { openedChapterId } = this.state
    if (openedChapterId === -1) {
      reactAlert.error('ошибка: неправильный id главы')
      return
    }
    service
      .addTaskToChapter(openedChapterId, id)
      .then(() => {
        this.loadCourseTasks()
        reactAlert.success('Задача успешно добавлена в курс')
      })
      .catch(err => {
        if (catchErrorAlert) catchErrorAlert(err)
      })
  }

  showAddForm = (openedChapterId: number) => () => {
    this.setState({ showAddForm: true, openedChapterId })
  }

  hideAddForm = () => {
    this.setState({ showAddForm: false })
  }

  showModuleForm = (idx: number) => () => {
    const { reactAlert } = this.props
    const { modules } = this.state
    const module = modules[idx]
    if (!module) {
      reactAlert.error('ошибка: при выборе модуля возникла ошибка')
      return
    }
    this.setState({
      showModuleForm: true,
      editingModule: module,
    })
  }

  showChapterForm = (idx: number) => {
    const { reactAlert } = this.props
    const { chapters } = this.state
    const chapter = chapters.find(element => element.id === idx)
    if (!chapter) {
      reactAlert.error('ошибка: при выборе главы возникла ошибка')
      return
    }
    this.setState({
      showChapterForm: true,
      editingChapter: chapter,
    })
  }

  hideModuleForm = () => {
    this.setState({ showModuleForm: false })
  }

  hideChapterForm = () => {
    this.setState({
      showChapterForm: false,
    })
  }

  onDeleteCourseTask = (courseTaskId: number) => {
    const { catchErrorAlert, reactAlert } = this.props
    courseTasksService
      .deleteById(courseTaskId)
      .then(() => {
        reactAlert.success('Задача успешно удалена')
        const { courseTasks } = this.state
        this.setState({
          courseTasks: deleteItemByIndex(courseTasks, getIndexByKeyValue(courseTasks, 'id', courseTaskId)),
        })
      })
      .catch(err => {
        if (catchErrorAlert) catchErrorAlert(err)
      })
  }

  restorePosition = function<EntityType>(items: EntityType[]) {
    return items.map((item, index: number) => {
      return {
        ...item,
        position: index + 1,
      }
    })
  }

  onUpModule = (id: number) => () => {
    const { modules } = this.state
    const { catchErrorAlert, reactAlert, courseId } = this.props
    const oldIndex = getIndexByKeyValue(modules, 'id', id)
    if (oldIndex === 0) return
    const module = modules[oldIndex]
    if (!module) {
      reactAlert.error('ошибка: неправильно выбран id модуля')
      return
    }
    modulesService
      .reducePosition(module.id, courseId)
      .then(() => {
        this.setState({
          modules: this.restorePosition<ModuleDto>(
            addItemByIndex(deleteItemByIndex(modules, oldIndex), oldIndex - 1, module)
          ),
        })
      })
      .catch(err => {
        if (catchErrorAlert) catchErrorAlert(err)
      })
  }

  onDownModule = (id: number) => () => {
    const { modules } = this.state
    const { catchErrorAlert, reactAlert, courseId } = this.props
    const oldIndex = getIndexByKeyValue(modules, 'id', id)
    if (oldIndex === modules.length - 1) return
    const module = modules[oldIndex]
    if (!module) {
      reactAlert.error('ошибка: неправильный id модуля')
      return
    }
    modulesService
      .increasePosition(module.id, courseId)
      .then(() => {
        this.setState({
          modules: this.restorePosition<ModuleDto>(
            addItemByIndex(deleteItemByIndex(modules, oldIndex), oldIndex + 1, module)
          ),
        })
      })
      .catch(err => {
        if (catchErrorAlert) catchErrorAlert(err)
      })
  }

  onUpChapter = (id: number, module: ModuleDto) => () => {
    const { catchErrorAlert, reactAlert } = this.props
    const { chapters } = this.state
    const oldIndex = getIndexByKeyValue(chapters, 'id', id)
    const chapter = chapters[oldIndex]
    if (!chapter) {
      reactAlert.error('ошибка: при выборе главы произошла ошибка')
      return
    }
    if (chapter.position === 1) return
    chapterService
      .reducePosition(chapter.id, module.id)
      .then(() => {
        this.setState({
          chapters: this.restorePosition<ChapterDto>(
            addItemByIndex(deleteItemByIndex(chapters, oldIndex), oldIndex - 1, chapter)
          ),
        })
      })
      .catch(err => {
        if (catchErrorAlert) catchErrorAlert(err)
      })
  }

  onDownChapter = (id: number, module: ModuleDto) => () => {
    const { chapters } = this.state
    const { catchErrorAlert, reactAlert } = this.props
    const oldIndex = getIndexByKeyValue(chapters, 'id', id)
    if (oldIndex === chapters.length - 1) return
    const chapter = chapters[oldIndex]
    if (!chapter) {
      reactAlert.error('ошибка: возникла ошибка при выборе главы')
      return
    }
    chapterService
      .increasePosition(chapter.id, module.id)
      .then(() => {
        this.setState({
          chapters: this.restorePosition<ChapterDto>(
            addItemByIndex(deleteItemByIndex(chapters, oldIndex), oldIndex + 1, chapter)
          ),
        })
      })
      .catch(err => {
        if (catchErrorAlert) catchErrorAlert(err)
      })
  }

  onChangeTaskPosition = (ChangeIndexObj: ChangeIndexObjInterface) => {
    const { oldIndex, newIndex } = ChangeIndexObj
    const { catchErrorAlert } = this.props
    const { courseTasks, openedChapterId } = this.state
    const idx = getIndexByKeyValue(courseTasks, 'position', oldIndex + 1)
    const courseTask = courseTasks[idx]
    if (openedChapterId === -1 || !courseTask) return
    const { id: courseTaskId } = courseTask
    chapterService
      .replaceCourseTask(courseTaskId, openedChapterId, openedChapterId, newIndex + 1)
      .then(() => {
        this.setState({
          courseTasks: this.restorePosition<ChapterTaskDto>(
            addItemByIndex(deleteItemByIndex(courseTasks, oldIndex), newIndex, courseTasks[idx])
          ),
        })
      })
      .catch(err => {
        if (catchErrorAlert) catchErrorAlert(err)
      })
  }

  changeModuleControl = ({ moduleId, control }: ChangeModuleControlParam) => {
    const updatedModuleIndex = this.state.modules.findIndex(module => module.id === moduleId)

    const updatedState = produce(this.state, draft => {
      const updatedModule = draft.modules[updatedModuleIndex]

      if (!updatedModule) {
        return
      }

      updatedModule.control = control
    })

    this.setState(updatedState, () => {
      const updatedModule = this.state.modules[updatedModuleIndex]

      if (!updatedModule) {
        return
      }

      this.onSaveModule(updatedModule).then(() =>
        this.props.reactAlert.success('Контрольность модуля успешно изменена')
      )
    })
  }

  render() {
    const {
      modules,
      chapters,
      openedModuleId,
      openedChapterId,
      courseTasks,
      chaptersLoaded,
      courseTasksLoaded,
      showAddForm,
      showModuleForm,
      editingModule,
      editingChapter,
      showChapterForm,
    } = this.state
    return (
      <div className="modules-block">
        <Accordion>
          {modules.map((module, index) => (
            <ModuleItem
              // TODO: Разобраться почему ругается на onChangeTaskPosition
              // @ts-ignore
              onChangeTaskPosition={this.onChangeTaskPosition}
              onChooseModule={this.onChooseModule(module.id)}
              openedChapterId={openedChapterId}
              onChooseChapter={this.onChooseChapter}
              onShowAddModal={this.showAddForm}
              chapters={chapters}
              courseTasks={courseTasks}
              module={module}
              onAddChapterToModule={this.onAddChapterToModule}
              key={module.id}
              onDelete={this.onDeleteModule(module.id)}
              onDeleteChapter={this.onDeleteChapter}
              onDeleteCourseTask={this.onDeleteCourseTask}
              courseTasksLoaded={courseTasksLoaded}
              show={chaptersLoaded && module.id === openedModuleId}
              onUpModule={this.onUpModule(module.id)}
              onDownModule={this.onDownModule(module.id)}
              onUpChapter={this.onUpChapter}
              onDownChapter={this.onDownChapter}
              onSelectForEdit={this.showModuleForm(index)}
              onEditChapterForm={this.showChapterForm}
              updateTopics={this.updateChapterTopics}
              onControlChange={(updatedControl: boolean) =>
                this.changeModuleControl({
                  moduleId: module.id,
                  control: updatedControl,
                })
              }
            />
          ))}
        </Accordion>
        <Formik
          initialValues={{
            name: '',
            description: '',
          }}
          onSubmit={(values, { resetForm }) => {
            this.onAddModule(values)
            resetForm({
              name: '',
              description: '',
            })
          }}
        >
          {({ values, handleChange }) => (
            <Form className="form-inline">
              <div className="form-group">
                <FormControl
                  type="text"
                  value={values.name}
                  name="name"
                  onChange={handleChange}
                  placeholder="name"
                  className="mt-2 mr-2"
                />
              </div>
              <div className="form-group">
                <FormControl
                  type="text"
                  placeholder="description"
                  value={values.description}
                  name="description"
                  onChange={handleChange}
                  className="mt-2 mr-2"
                />
              </div>

              <div className="input-group-append">
                <button type="submit" className="btn btn-sm btn-info mt-2 mr-2">
                  Добавить
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <AddTaskModal show={showAddForm} onAddTask={this.onAddCourseTask} onClose={this.hideAddForm} />
        <ModuleModal
          show={showModuleForm}
          onSave={this.onSaveModule}
          module={editingModule}
          onClose={this.hideModuleForm}
        />
        <ChapterModal
          show={showChapterForm}
          onSave={this.onSaveChapter}
          chapter={editingChapter}
          onClose={this.hideChapterForm}
        />
      </div>
    )
  }
}

export default withReactAlert(ModulesBlock)

type ChangeModuleControlParam = {
  moduleId: number
  control: boolean
}
