import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import { StudentAnalyticsDto } from 'src/model/student-dto/student-dto'

import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from 'src/components/shared/modal-view'
import { curatorStudentService } from 'src/services/curator-services/curator-student-service'
import {
  RecruitmentStudentMeetStatusHistoryDto,
  RecruitmentMeetEntryStatus,
} from 'src/model/recruitment-dto/recruitment-dto'

import { useReactAlert } from 'src/hooks/useReactAlert'

interface MeetHistoryModalProps {
  open: boolean
  student: StudentAnalyticsDto
  onClose: () => void
}

export const MeetHistoryModal: React.FC<MeetHistoryModalProps> = ({ open, onClose, student }) => {
  ReactModal.setAppElement('#root')
  const { catchErrorAlert } = useReactAlert()
  const [studentMeetStatusHistory, setStudentMeetStatusHistory] = useState<RecruitmentStudentMeetStatusHistoryDto[]>([])

  useEffect(() => {
    curatorStudentService
      .getStudentMeetHistory(student.id)
      .then(response => setStudentMeetStatusHistory(response))
      .catch(error => catchErrorAlert(error))
  }, [])

  const renderStatus = (status: RecruitmentMeetEntryStatus) => {
    const icons = {
      [RecruitmentMeetEntryStatus.MISSED]: (
        <div>
          <span className="mdi mdi-close-circle-outline" style={{ color: '#DC3545', fontSize: '20px' }} />{' '}
          <span>Не присутствовал</span>
        </div>
      ),
      [RecruitmentMeetEntryStatus.VISITED]: (
        <div>
          <span className="mdi mdi-check-circle-outline" style={{ color: '#28A745', fontSize: '20px' }} />{' '}
          <span>Присутствовал</span>
        </div>
      ),
      [RecruitmentMeetEntryStatus.NOT_SELECTED]: (
        <div>
          <span className="mdi mdi-help-circle-outline" style={{ color: '#DDDDDD', fontSize: '20px' }} />{' '}
          <span>Неизвестно</span>
        </div>
      ),
    }

    return icons[status]
  }

  return (
    <ReactModal isOpen={open} onRequestClose={onClose} style={customStyles} shouldCloseOnEsc>
      <ModalWrapper>
        <ModalHeader>История присутствия на созвонах</ModalHeader>
        <ModalContent>
          <table className="table table-striped admin-users-table">
            <thead>
              <tr>
                <th>
                  <b>Дата созвона</b>
                </th>
                <th>
                  <b>Статус</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {studentMeetStatusHistory.map(studentMeetStatus => (
                <tr key={studentMeetStatus.meetDateTime}>
                  <td>{studentMeetStatus.meetDateTime}</td>
                  <td>{renderStatus(studentMeetStatus.status)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </ModalContent>
        <ModalFooter>
          <ModalButton action="back" onClick={onClose}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}
