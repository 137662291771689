import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import classNames from 'classnames'

import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from 'src/components/shared/modal-view'
import { curatorStudentService } from 'src/services/curator-services/curator-student-service'

import { StudentReviewStatisticDto, StudentStatisticReviewStatus } from 'src/model/student-model'

import { useReactAlert } from 'src/hooks/useReactAlert'

interface ReviewHistoryModalProps {
  open: boolean
  studentId: number
  onClose: () => void
}

export const ReviewHistoryModal: React.FC<ReviewHistoryModalProps> = ({ open, onClose, studentId }) => {
  ReactModal.setAppElement('#root')
  const { catchErrorAlert } = useReactAlert()
  const [studentReviewStatistic, setStudentReviewStatistic] = useState<StudentReviewStatisticDto[]>([])

  useEffect(() => {
    curatorStudentService
      .getStudentReviewStatistic(studentId)
      .then(response => setStudentReviewStatistic(response))
      .catch(error => catchErrorAlert(error))
  }, [])

  const statusMapper = (status: StudentStatisticReviewStatus) => {
    switch (status) {
      case StudentStatisticReviewStatus.ReviewNotAttempt:
        return 'Не было попыток'
      case StudentStatisticReviewStatus.ReviewSuccess:
        return 'Ревью сдано'
      case StudentStatisticReviewStatus.ReviewFailed:
        return 'Ревью провалено'
      case StudentStatisticReviewStatus.ReviewWaited:
        return 'Ожидает ревью'
      default:
        return ''
    }
  }

  return (
    <ReactModal isOpen={open} onRequestClose={onClose} style={customStyles} shouldCloseOnEsc>
      <ModalWrapper>
        <ModalHeader>Ревью студента</ModalHeader>
        <ModalContent>
          <table className="table table-striped admin-users-table">
            <thead>
              <tr>
                <th>
                  <b>ID модуля</b>
                </th>
                <th>
                  <b>Модуль</b>
                </th>
                <th>
                  <b>ID Курса</b>
                </th>
                <th>
                  <b>Курс</b>
                </th>
                <th>
                  <b>Последняя попытка</b>
                </th>
                <th>
                  <b>Количество попыток</b>
                </th>
                <th>
                  <b>Статус</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {studentReviewStatistic.map(ReviewStatistic => (
                <tr
                  key={ReviewStatistic.lastAttemptDateTime}
                  className={classNames({
                    'review-history-success': ReviewStatistic.status === StudentStatisticReviewStatus.ReviewSuccess,
                    'review-history-failed': ReviewStatistic.status === StudentStatisticReviewStatus.ReviewFailed,
                  })}
                >
                  <td>{ReviewStatistic.moduleId}</td>
                  <td>{ReviewStatistic.moduleTitle}</td>
                  <td>{ReviewStatistic.courseId}</td>
                  <td>{ReviewStatistic.courseTitle}</td>
                  <td>{ReviewStatistic.lastAttemptDateTime}</td>
                  <td>{ReviewStatistic.reviewAttemptCount}</td>
                  <td>{statusMapper(ReviewStatistic.status)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </ModalContent>
        <ModalFooter>
          <ModalButton action="back" onClick={onClose}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}
