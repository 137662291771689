import React from 'react'
import { Form, Formik } from 'formik'
import { Form as RBForm, FormControl, Button, FormCheck, FormGroup, Row, Col } from 'react-bootstrap'
import CKEditor from 'ckeditor4-react'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import CourseService from '../../../../../../services/admin-services/admin-course-service'

import './basic-info-tab.css'

class BasicInfoTab extends React.Component {
  service = new CourseService()

  state = {
    course: {},
  }

  componentDidMount() {
    const { courseId = 0, catchErrorAlert } = this.props
    if (courseId > 0) {
      this.service
        .getById(courseId)
        .then(course => this.setState({ course }))
        .catch(error => {
          if (catchErrorAlert) catchErrorAlert(error)
        })
    }
  }

  render() {
    const {
      course: { id, name, htmlBody, slackChannelName, available, fullPassRequired },
    } = this.state
    const { reactAlert } = this.props

    return (
      <Formik
        enableReinitialize
        initialValues={{
          available: available || false,
          fullPassRequired,
          slackChannelName,
          name,
          htmlBody,
        }}
        onSubmit={values => {
          this.service.update({ id, ...values }).then(() => {
            reactAlert.success('Информация успешно сохранена')
          })
        }}
      >
        {({ values, handleSubmit, handleChange, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <RBForm.Group as={Row} className="align-items-center" controlId="available">
              <RBForm.Label column="md" sm="auto">
                Курс доступен:
              </RBForm.Label>
              <Col sm="auto">
                <RBForm.Check
                  value={values.available}
                  checked={values.available}
                  type="checkbox"
                  name="available"
                  onChange={handleChange}
                />
              </Col>
            </RBForm.Group>
            <RBForm.Group as={Row} className="align-items-center" controlId="fullPassRequired">
              <RBForm.Label column="md" sm="auto">
                Требуется полное прохождение (если курс будет добавлен как дополнительный, студент не сможет решать
                курсы направления):
              </RBForm.Label>
              <Col sm="auto">
                <RBForm.Check
                  value={values.fullPassRequired}
                  checked={values.fullPassRequired}
                  type="checkbox"
                  name="fullPassRequired"
                  onChange={handleChange}
                />
              </Col>
            </RBForm.Group>
            <div className="form-group">
              <label>Название курса: </label>
              <FormControl type="text" value={values.name} name="name" onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Название канала в Slack: </label>
              <FormControl
                type="text"
                value={values.slackChannelName}
                name="slackChannelName"
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <b>Описание курса: </b>
              <CKEditor
                name="htmlBody"
                onChange={({ editor }) => setFieldValue('htmlBody', editor.getData())}
                data={values.htmlBody}
              />
            </div>
            <Button type="submit" variant="success" className="mr-2">
              Обновить
            </Button>
          </Form>
        )}
      </Formik>
    )
  }
}

export default withReactAlert(BasicInfoTab)
