import { useState, useEffect } from 'react'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { deleteItemByIndex, getIndexByKeyValue } from 'src/utils/ArraysUtils'

import { PaginationParamPair, ParameterValue } from '../../../../services/filter-pagination-service'

import { ReportPagesApiProps } from '../types'

export const useFilters = ({ service }: ReportPagesApiProps) => {
  const [paginationParamPairs, setPaginationParamPairs] = useState<PaginationParamPair[]>([])
  const [currentRecruitmentId, setCurrentRecruitmentId] = useState<number | string | null>(null)
  const [paginationParams, setPaginationParams] = useState<ParameterValue[]>([])
  const { catchErrorAlert } = useReactAlert()

  const handleRecruitmentSelect = (paramValue: number | string | null) => {
    if (paramValue !== null) {
      setCurrentRecruitmentId(paramValue)
      return
    }
    setPaginationParamPairs(params => {
      const newParams = deleteItemByIndex(params, getIndexByKeyValue(params, 'parameterName', 'payStudentId'))
      return [
        ...newParams,
        {
          parameterName: 'payStudentId',
          parameterDisplayedName: 'Студент',
          parameterValues: [{ name: 'Все', id: null }],
        },
      ]
    })
  }

  useEffect(() => {
    if (!service) return

    service
      .getRecruitments({})
      .then(recruitments => {
        setPaginationParamPairs([
          {
            parameterName: 'recruitmentId',
            parameterDisplayedName: 'Набор',
            parameterValues: [
              { name: 'Все', id: null },
              ...recruitments.map(({ channelName, id }) => ({
                name: channelName,
                id,
              })),
            ],
          },
          {
            parameterName: 'payStudentId',
            parameterDisplayedName: 'Студент',
            parameterValues: [{ name: 'Все', id: null }],
          },
          {
            parameterName: 'prePaid',
            parameterDisplayedName: 'Только предоплата',
            parameterValues: [
              { name: 'Да', id: 'true' },
              { name: 'Нет', id: 'false' },
            ],
          },
        ])
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [])

  useEffect(() => {
    if (!currentRecruitmentId) {
      return
    }
    if (!service) return
    service
      .getRecruitmentStudents(currentRecruitmentId)
      .then(students => {
        const studentsAsParams = students.map(student => {
          const { id, lastName, firstName } = student
          const name = `${lastName} ${firstName}`
          return { id, name } as ParameterValue
        })

        setPaginationParamPairs(params => {
          const newParams = deleteItemByIndex(params, getIndexByKeyValue(params, 'parameterName', 'payStudentId'))
          return [
            ...newParams,
            {
              parameterName: 'payStudentId',
              parameterDisplayedName: 'Студент',
              parameterValues: [{ name: 'Все', id: null }, ...studentsAsParams],
            },
          ]
        })
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [currentRecruitmentId])

  return {
    handleRecruitmentSelect,
    paginationParamPairs,
    currentRecruitmentId,
    paginationParams,
    setPaginationParams,
    setPaginationParamPairs,
  }
}
