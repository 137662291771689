import React, { useCallback, useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useMeetPageParams } from 'src/hooks/useMeetPageParams'
import useSetDocumentTitle from '../../../../../hooks/useSetDocumentTitle'
import useRecruitmentStudents from './hooks/useRecruitmentStudents'
import useRecruitmentMeetCalendar from './hooks/useRecruitmentMeetCalendar'
import { MeetPlanFilters } from './meet-plan-filters'
import { MeetPlanCalendar } from './meet-plan-calendar'
import { DAYS_PER_WEEK, fillDayMap, PERMITTED_ROW } from './helpers'
import { MeetPlanCreateCallModal } from './meet-plan-create-call-modal'
import { MeetPlanEditCallModal } from './meet-plan-edit-call-modal'
import { useReactAlert } from '../../../../../hooks/useReactAlert'
import { MeetPlanExtendedData, recruitmentMeetMapper } from './mappers'
import {
  CuratorRecruitmentMeetService,
  RecruitmentMeetCalendarQueryParams,
} from '../../../../../services/curator-services/curator-recruitment-meet-service'
import 'dayjs/locale/ru'
import './meet-plan-page.scss'

dayjs.locale('ru')

export const MeetPlanPage = () => {
  const [month, setMonth] = useState<Dayjs>(dayjs())
  const [onlySelf, setOnlySelf] = useState(false)
  const [startDay, setStartDay] = useState<Dayjs>(
    dayjs(new Date(dayjs().year(), dayjs().month(), 2 - dayjs(new Date(dayjs().year(), dayjs().month(), 1)).day()))
  )
  const [endDay, setEndDay] = useState<Dayjs>(
    dayjs(new Date(dayjs().year(), dayjs().month(), 36 - dayjs(new Date(dayjs().year(), dayjs().month(), 1)).day()))
  )
  const { recruitmentId } = useMeetPageParams()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(!!recruitmentId)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedMeet, setSelectedMeet] = useState<MeetPlanExtendedData | undefined>()
  const { selectedRecruitment, recruitmentStudents, handleSelectRecruitment } = useRecruitmentStudents(onlySelf)

  const {
    recruitmentMeetData,
    createRecruitmentMeetCall,
    deleteRecruitmentMeetCall,
    updateRecruitmentMeetCall,
    getRecruitmentsMeetData,
    loadingMeetData,
  } = useRecruitmentMeetCalendar()

  const { catchErrorAlert, reactAlert } = useReactAlert()

  const handleStartAndEndDayChanges = (newMonth: Dayjs) => {
    setStartDay(
      dayjs(
        new Date(newMonth.year(), newMonth.month(), 2 - dayjs(new Date(newMonth.year(), newMonth.month(), 1)).day())
      )
    )
    setEndDay(
      dayjs(
        new Date(newMonth.year(), newMonth.month(), 36 - dayjs(new Date(newMonth.year(), newMonth.month(), 1)).day())
      )
    )
  }

  useEffect(() => {
    handleStartAndEndDayChanges(month)
  }, [month])

  const handleRequestCalendarData = () => {
    getRecruitmentsMeetData({
      onlySelf,
      recruitmentId: selectedRecruitment?.id,
      startDate: startDay.format('DD.MM.YYYY'),
      endDate: endDay.format('DD.MM.YYYY'),
    })
  }

  useEffect(() => {
    handleStartAndEndDayChanges(dayjs())
    handleRequestCalendarData()
  }, [])

  const PAGE_TITLE = 'Календарь созвона с потоками'
  useSetDocumentTitle(PAGE_TITLE)

  const closeCreateModal = () => {
    setIsCreateModalOpen(false)
  }

  const openCreateModal = () => {
    setIsCreateModalOpen(true)
  }

  const closeEditModal = () => {
    setIsEditModalOpen(false)
  }

  const openEditModal = useCallback((recruitmentMeetId: number) => {
    CuratorRecruitmentMeetService.getRecruitmentMeetById(recruitmentMeetId)
      .then(meetData => {
        setSelectedMeet(recruitmentMeetMapper(meetData))
        setIsEditModalOpen(true)
      })
      .catch((error: Error) => catchErrorAlert(error))
  }, [])

  return (
    <>
      <section className="meet-plan">
        <h1 className="meet-plan__title">{PAGE_TITLE}</h1>
        <div className="meet-plan__filters-wrapper">
          <MeetPlanFilters
            month={month}
            setMonth={setMonth}
            self={onlySelf}
            setSelf={setOnlySelf}
            recruitmentStudents={recruitmentStudents}
            handleSelectRecruitment={handleSelectRecruitment}
            handleStartEndDateChange={handleStartAndEndDayChanges}
            openCreateModal={openCreateModal}
            handleFilters={handleRequestCalendarData}
          />
        </div>
        <div className="meet-plan__calendar-wrapper">
          <MeetPlanCalendar
            currentMonth={month}
            recruitmentMeetData={recruitmentMeetData}
            isLoading={loadingMeetData}
            openEditModal={openEditModal}
          />
        </div>
      </section>
      {isCreateModalOpen && (
        <MeetPlanCreateCallModal
          isCreateModalOpen={isCreateModalOpen}
          closeCreateModal={closeCreateModal}
          createRecruitmentMeetCall={createRecruitmentMeetCall}
          getRecruitmentsMeetData={handleRequestCalendarData}
        />
      )}

      {isEditModalOpen && (
        <MeetPlanEditCallModal
          isEditModalOpen={isEditModalOpen}
          closeEditModal={closeEditModal}
          selectedMeet={selectedMeet}
          deleteRecruitmentMeetCall={deleteRecruitmentMeetCall}
          updateRecruitmentMeetCall={updateRecruitmentMeetCall}
          getRecruitmentsMeetData={handleRequestCalendarData}
          setSelectedMeet={setSelectedMeet}
        />
      )}
    </>
  )
}
