import React, { useEffect } from 'react'
import { Route, Switch, Router } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'
import AlertTemplate from 'react-alert-template-basic'
import ReactNotification from 'react-notifications-component'
import { positions, Provider as AlertProvider, transitions } from 'react-alert'
import { availableRoutesByInactivation, messagesForInactivationReasons } from '../../model/inactivation-model'
import { AuthService } from '../../services/auth-service'
import { history } from '../../config'
import PrivateRoute from '../private-route'
import MentorPage from '../pages/mentor/mentor-page'
import ManagerPage from '../pages/managers/manager/manager-page'
import ChiefManagerPage from '../pages/managers/chief-manager'
import LoginPage from '../pages/login-page'
import BlankPage from '../pages/blank-page'
import AdminPage from '../pages/admin'
import TheoryReviewPage from '../pages/mentor/theory-review-page'
import ChiefPage from '../pages/chief/components'
import PasswordRecoveryContainer from '../pages/login-page/password-recovery/password-recovery-container'
import CuratorPage from '../pages/curator'
import NotFound from '../not-found'
import './root.scss'
import './common.scss'
import './fonts.scss'
import './garbage.common.css'
import ProtectedRoute from '../protected-route'

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 10000,
  },
}

const App = () => {
  useEffect(() => {
    history.listen(({ pathname }) => {
      const currentUser = AuthService.currentUserValue()
      if (currentUser?.inactivation) {
        const { reason } = currentUser.inactivation
        const currentRole = currentUser.role.name
        const availableRoutes = availableRoutesByInactivation[reason][currentRole]
        if (!availableRoutes.includes(pathname)) {
          history.replace({
            pathname: availableRoutes[0],
            state: { reasonMessage: messagesForInactivationReasons[reason] },
          })
        }
      }
    })
  }, [])

  const isAuthorized = () => {
    // FIXME: Переделать логику редиректа и проверки авторизации, чтобы зависимость была только от token.
    return Boolean(AuthService.getToken() && AuthService.currentUserValue())
  }

  return (
    <Router history={history}>
      <AlertProvider template={AlertTemplate} {...options}>
        <LastLocationProvider>
          <ReactNotification />
          <Switch>
            <PrivateRoute strict path="/mentor/review/theory/:id" component={TheoryReviewPage} authRole={['MENTOR']} />
            <PrivateRoute path="/mentor" component={MentorPage} authRole={['MENTOR']} />
            <PrivateRoute path="/admin" component={AdminPage} authRole={['ADMIN']} />
            <PrivateRoute path="/chief" component={ChiefPage} authRole={['CHIEF_MENTOR']} />
            <PrivateRoute path="/manager" component={ManagerPage} authRole={['MANAGER']} />
            <PrivateRoute path="/chief-manager" component={ChiefManagerPage} authRole={['CHIEF_MANAGER']} />
            <PrivateRoute path="/curator" component={CuratorPage} authRole={['CURATOR']} />

            <Route path="/" exact component={BlankPage} />
            <ProtectedRoute isAllowed={() => !isAuthorized()} path="/login" component={LoginPage} />
            <Route path="/recovery-pass" component={PasswordRecoveryContainer} />
            <ProtectedRoute isAllowed={() => isAuthorized()} path="" component={NotFound} />
          </Switch>
        </LastLocationProvider>
      </AlertProvider>
    </Router>
  )
}

export default App
