import React, { useContext, useEffect, useState, useMemo } from 'react'
import ReactModal from 'react-modal'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from 'src/components/shared/modal-view'
import { decodeDate, formatDate } from 'src/utils/dateUtils'

import { useReactAlert } from 'src/hooks/useReactAlert'
import { CuratorDirectionDto } from 'src/model/direction-model'

import { Form } from 'react-bootstrap'

import CuratorUserService from 'src/services/curator-services/curator-user-service'
import { CuratorDto } from 'src/model/curator-dto/curator-dto'
import { RecruitmentContext } from '../recruitment-page'

interface RecruitmentModalProps {}

const curatorService = new CuratorUserService()

export const RecruitmentModal: React.FC<RecruitmentModalProps> = () => {
  ReactModal.setAppElement('#root')
  const {
    curatorRecruitmentService,
    curatorDirectionsService,
    selectedRecruitment,
    setSelectedRecruitment,
    openModal,
    setOpenModal,
    fetchRecruitment,
  } = useContext(RecruitmentContext)

  const { catchErrorAlert, reactAlert } = useReactAlert()

  const [allDirections, setAllDirections] = useState<CuratorDirectionDto[]>([])
  const [selectedDirection, setSelectedDirection] = useState<number | null>(null)
  const [curators, setCurators] = useState<CuratorDto[]>([])
  const curatorsByName = useMemo<Record<string, CuratorDto>>(
    () =>
      curators.reduce((acc: Record<string, CuratorDto>, curr: CuratorDto) => {
        const key = `${curr.firstName} ${curr.lastName}`
        acc[key] = curr
        return acc
      }, {}),
    [curators]
  )

  const [selectedCuratorId, setSelectedCuratorId] = useState<number | null>(null)

  const [channelName, setChannelName] = useState<string>('')
  const [startTraining, setStartTraining] = useState<string>('')
  const defaultCuratorId = selectedRecruitment?.curatorName ? curatorsByName[selectedRecruitment?.curatorName]?.id : ''

  function fetchCurators() {
    curatorService
      .getAllCurators()
      .then(data => setCurators(data))
      .catch(err => catchErrorAlert(err))
  }

  const [deadlines, setDeadlines] = useState<
    {
      deadlineDate: string
      courseId: number
      courseName: string
      coursePosition: number
    }[]
  >([])

  useEffect(() => {
    if (!openModal) return
    curatorDirectionsService
      .getAll()
      .then(directions => {
        setAllDirections(directions)
        if (selectedRecruitment?.directionName) {
          setSelectedDirection(
            directions.find(direction => direction.name === selectedRecruitment.directionName)?.id ?? null
          )
        }
      })
      .catch(error => {
        catchErrorAlert(error)
      })
    fetchCurators()
  }, [openModal, selectedRecruitment])

  useEffect(() => {
    if (selectedRecruitment) {
      setDeadlines(
        selectedRecruitment.deadlines.map(deadline => ({
          ...deadline,
          deadlineDate: formatDate(deadline.deadlineDate),
        }))
      )
      return
    }

    const courses = allDirections.find(direction => direction.id === selectedDirection)?.courses || []

    const newDeadlines = courses.map(course => ({
      courseId: course.id,
      courseName: course.name,
      coursePosition: course.position,
      deadlineDate: '',
    }))

    setDeadlines(newDeadlines)
  }, [selectedDirection, selectedRecruitment])

  useEffect(() => {
    setChannelName(selectedRecruitment?.channelName ?? '')
    setStartTraining(selectedRecruitment?.startTraining ? formatDate(selectedRecruitment?.startTraining) : '')
  }, [openModal, selectedRecruitment])

  const isDisabledForm = () => {
    return !(selectedDirection && channelName && startTraining && deadlines.every(({ deadlineDate }) => deadlineDate))
  }

  const resetForm = () => {
    setChannelName('')
    setStartTraining('')
    setSelectedRecruitment(null)
    setSelectedCuratorId(null)
    setDeadlines([])
  }

  return (
    <ReactModal
      isOpen={openModal}
      onRequestClose={() => {
        setOpenModal(false)
        resetForm()
        setSelectedDirection(null)
      }}
      style={customStyles}
      shouldCloseOnEsc
    >
      <ModalWrapper>
        <ModalHeader>{selectedRecruitment ? 'Редактирование потока' : 'Создание потока'}</ModalHeader>
        <ModalContent>
          <div id="group-form" className="group-form">
            <div>
              <label className="label">
                <div>Направление:</div>
                <select
                  required
                  name="directionId"
                  value={selectedDirection || ''}
                  className="input"
                  onChange={event => {
                    setSelectedDirection(Number(event.target.value))
                    resetForm()
                  }}
                >
                  <option value="not selected">Выбери направление из списка</option>
                  {allDirections?.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            <div>
              <label className="label">
                <div>Выбрать куратора:</div>
                <select
                  name="curatorId"
                  value={selectedCuratorId || defaultCuratorId}
                  className="input"
                  onChange={event => setSelectedCuratorId(Number(event.target.value))}
                >
                  <option value="not selected">Выбери куратора из списка</option>
                  {curators?.map(option => (
                    <option key={option.id} value={option.id}>
                      {`${option.firstName} ${option.lastName}`}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            <div>
              <label className="label">
                <div>Канал потока в Slack:</div>
                <input
                  required
                  type="text"
                  name="channelName"
                  value={channelName}
                  className="input"
                  placeholder="Введите имя канала потока в Slack"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setChannelName(e.target.value)
                  }}
                />
              </label>
            </div>

            <div>
              <label className="label">
                <div>Дата старта потока:</div>
                <Form.Control
                  required
                  name="startTraining"
                  type="date"
                  value={startTraining}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setStartTraining(e.target.value)
                  }}
                />
              </label>
            </div>

            {deadlines.map(({ courseId, courseName, deadlineDate }) => {
              return (
                <div key={courseId}>
                  <label className="label">
                    <div>{courseName}:</div>
                    <Form.Control
                      required
                      name={String(courseName)}
                      type="date"
                      value={deadlineDate}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setDeadlines(
                          deadlines.map(deadline => {
                            if (deadline.courseId === courseId) {
                              return {
                                courseId: deadline.courseId,
                                courseName: deadline.courseName,
                                coursePosition: deadline.coursePosition,
                                deadlineDate: e.target.value,
                              }
                            }
                            return deadline
                          })
                        )
                      }}
                    />
                  </label>
                </div>
              )
            })}
          </div>

          <ModalFooter>
            <ModalButton
              action="assign"
              type="submit"
              form="group-form"
              disabled={!!isDisabledForm()}
              onClick={() => {
                if (selectedRecruitment) {
                  curatorRecruitmentService
                    .editRecruitment({
                      id: selectedRecruitment.id,
                      channelName,
                      startTraining: decodeDate(startTraining),
                      directionId: Number(selectedDirection),
                      deadlines: deadlines.map(deadline => {
                        return {
                          ...deadline,
                          deadlineDate: decodeDate(deadline.deadlineDate),
                          id: Number(selectedRecruitment.deadlines.find(d => d.courseId === deadline.courseId)?.id),
                        }
                      }),
                      curatorId: selectedCuratorId,
                    })
                    .then(() => fetchRecruitment())
                    .then(() => reactAlert.success('Поток успешно обновлен'))
                    .catch(error => catchErrorAlert(error))
                    .finally(() => {
                      setOpenModal(false)
                    })
                } else {
                  curatorRecruitmentService
                    .createRecruitment({
                      channelName,
                      startTraining: decodeDate(startTraining),
                      directionId: Number(selectedDirection),
                      deadlines: deadlines.map(deadline => ({
                        ...deadline,
                        deadlineDate: decodeDate(deadline.deadlineDate),
                      })),
                      curatorId: selectedCuratorId,
                    })
                    .then(() => reactAlert.success('Поток успешно создан'))
                    .then(() => fetchRecruitment())
                    .catch(error => catchErrorAlert(error))
                    .finally(() => {
                      setOpenModal(false)
                    })
                }
              }}
            >
              Подтвердить
            </ModalButton>
            <ModalButton
              action="cancel"
              onClick={() => {
                setOpenModal(false)
                resetForm()
                setSelectedDirection(null)
              }}
            >
              Закрыть
            </ModalButton>
          </ModalFooter>
        </ModalContent>
      </ModalWrapper>
    </ReactModal>
  )
}
