import React, { useContext, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from 'src/components/shared/modal-view'
import { Field, Formik } from 'formik'
import { DirectionDto } from 'src/model/direction-model'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { MentorGetDto } from 'src/model/mentor-model'
import { RecruitmentDto } from 'src/model/recruitment-dto/recruitment-dto'
import * as Yup from 'yup'
import { RecruitmentGroupContext } from '../recruitment-group-page'

import './styles.css'

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Нужно указать имя'),
  channelName: Yup.string().nullable(),
  recruitmentId: Yup.number().required('Нужно выбрать поток'),
  mentorId: Yup.number().required('Нужно выбрать ментора'),
})

interface GroupFormModalProps {}

export const GroupFormModal: React.FC<GroupFormModalProps> = () => {
  ReactModal.setAppElement('#root')
  const { catchErrorAlert, reactAlert } = useReactAlert()

  const {
    curatorGroupService,
    curatorRecruitmentService,
    curatorDirectionsService,
    curatorMentorService,
    selectedRecruitmentGroup,
    fetchRecruitmentGroups,
    openModal,
    setOpenModal,
  } = useContext(RecruitmentGroupContext)

  const [allDirections, setAllDirections] = useState<DirectionDto[] | null>(null)
  const [selectedDirection, setSelectedDirection] = useState<number | null>(null)

  const [allRecruitments, setAllRecruitments] = useState<RecruitmentDto[] | null>(null)

  const [allMentors, setAllMentors] = useState<MentorGetDto[] | null>(null)

  const [onlySelf, setOnlySelf] = useState(false)

  useEffect(() => {
    if (!openModal) return
    curatorDirectionsService
      .getAll()
      .then(directions => {
        setAllDirections(directions)
        if (selectedRecruitmentGroup?.directionName) {
          setSelectedDirection(
            directions.find(direction => direction.name === selectedRecruitmentGroup.directionName)?.id ?? null
          )
        }
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [openModal])

  useEffect(() => {
    if (!openModal) return
    if (selectedDirection !== null) {
      curatorRecruitmentService
        .getRecruitments({ onlySelf, directionId: selectedDirection })
        .then(recruitments => {
          setAllRecruitments(recruitments)
        })
        .catch(error => {
          catchErrorAlert(error)
        })
    }
  }, [onlySelf, openModal, selectedDirection])

  useEffect(() => {
    if (!openModal) return

    if (selectedDirection !== null) {
      curatorMentorService
        .getMentors(selectedDirection)
        .then(mentors => {
          setAllMentors(mentors)
        })
        .catch(error => {
          catchErrorAlert(error)
        })
    }
  }, [selectedDirection, openModal])

  return (
    <ReactModal
      isOpen={openModal}
      onRequestClose={() => {
        setOpenModal(false)
        setSelectedDirection(null)
        setAllMentors(null)
        setAllRecruitments(null)
        setAllDirections(null)
        setOnlySelf(false)
      }}
      style={customStyles}
      shouldCloseOnEsc
    >
      <ModalWrapper>
        <ModalHeader>{selectedRecruitmentGroup?.name ? 'Редактирование группы' : 'Создание группы'}</ModalHeader>
        <ModalContent>
          <Formik
            validationSchema={validationSchema}
            enableReinitialize
            initialValues={{
              name: selectedRecruitmentGroup?.name ?? '',
              channel: selectedRecruitmentGroup?.channelName ? 'exist' : 'create',
              channelName: selectedRecruitmentGroup?.channelName ?? '',
              directionId:
                allDirections?.find(direction => direction.name === selectedRecruitmentGroup?.directionName)?.id ?? '',
              recruitmentId:
                allRecruitments?.find(
                  recruitment => recruitment.channelName === selectedRecruitmentGroup?.recruitmentChannel
                )?.id ?? '',
              mentorId:
                allMentors?.find(
                  mentor => `${mentor.firstName} ${mentor.lastName}` === selectedRecruitmentGroup?.mentorName
                )?.id ?? '',
            }}
            onSubmit={() => {}}
          >
            {({ values, handleChange, handleSubmit, isValid }) => {
              return (
                <>
                  <form
                    id="group-form"
                    className="group-form"
                    onSubmit={e => {
                      handleSubmit(e)
                      const { mentorId, name, recruitmentId } = values
                      const channelName = values.channelName || null

                      if (selectedRecruitmentGroup) {
                        curatorGroupService
                          .editRecruitmentGroup({
                            id: selectedRecruitmentGroup.id,
                            mentorId: Number(mentorId),
                            name,
                            recruitmentId: Number(recruitmentId),
                            channelName,
                          })
                          .then(() => fetchRecruitmentGroups())
                          .then(() => reactAlert.success('Группа потока успешно обновлена'))
                          .catch(error => catchErrorAlert(error))
                          .finally(() => {
                            setOpenModal(false)
                          })
                      } else {
                        curatorGroupService
                          .createRecruitmentGroup({
                            mentorId: Number(mentorId),
                            name,
                            recruitmentId: Number(recruitmentId),
                            channelName,
                          })
                          .then(() => reactAlert.success('Группа потока успешно создана'))
                          .then(() => fetchRecruitmentGroups())
                          .catch(error => catchErrorAlert(error))
                          .finally(() => {
                            setOpenModal(false)
                          })
                      }
                    }}
                  >
                    <div>
                      <label className="label">
                        <div>Название группы потока на EWP (свободное имя):</div>
                        <Field
                          type="text"
                          name="name"
                          value={values.name}
                          className="input"
                          placeholder="Введите название потока"
                          onChange={handleChange}
                        />
                      </label>
                    </div>

                    <div className="label">
                      <div>Канал группы потока в Slack:</div>
                      <div className="radio">
                        <label>
                          <Field
                            type="radio"
                            name="channel"
                            value="create"
                            checked={values.channel === 'create'}
                            onChange={(event: any) => {
                              handleChange(event)
                              values.channelName = ''
                            }}
                          />
                          Создать канал
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="channel"
                            value="exist"
                            checked={values.channel === 'exist'}
                            onChange={handleChange}
                          />
                          Канал уже существует
                        </label>
                      </div>
                      <Field
                        disabled={values.channel === 'create'}
                        onChange={handleChange}
                        type="text"
                        name="channelName"
                        value={values.channelName}
                        className="input"
                        placeholder="Введите имя канала группы в Slack"
                      />
                    </div>

                    <div>
                      <label className="label">
                        <div>Направление:</div>
                        <select
                          name="directionId"
                          value={values.directionId}
                          className="input"
                          onChange={event => {
                            handleChange(event)
                            setSelectedDirection(Number(event.target.value))
                            values.mentorId = ''
                            values.recruitmentId = ''
                          }}
                        >
                          <option value="not selected">Выбери направление из списка</option>
                          {allDirections?.map(option => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>

                    <div>
                      <label className="label">
                        <div>Поток:</div>
                        <Field
                          checked={onlySelf}
                          type="checkbox"
                          className="checkbox"
                          onChange={() => {
                            setOnlySelf(!onlySelf)
                            values.recruitmentId = ''
                          }}
                          disabled={!selectedRecruitmentGroup}
                        />
                        Только свои
                        <select
                          name="recruitmentId"
                          value={values.recruitmentId}
                          className="input"
                          onChange={handleChange}
                          disabled={!selectedDirection || !!selectedRecruitmentGroup}
                        >
                          <option value="not selected">Выбери поток из списка</option>
                          {allRecruitments?.map(option => (
                            <option key={option.id} value={option.id}>
                              {option.channelName}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>

                    <div>
                      <label className="label">
                        <div>Ментор:</div>
                        <select
                          name="mentorId"
                          value={values.mentorId}
                          className="input"
                          onChange={handleChange}
                          disabled={selectedDirection === null}
                        >
                          <option value="not selected">Выбрать ментора из списка</option>
                          {allMentors?.map(option => (
                            <option key={option.id} value={option.id}>
                              {`${option.firstName} ${option.lastName}`}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                  </form>

                  <ModalFooter>
                    <ModalButton action="assign" type="submit" form="group-form" disabled={!isValid}>
                      Подтвердить
                    </ModalButton>
                    <ModalButton action="cancel" onClick={() => setOpenModal(false)}>
                      Закрыть
                    </ModalButton>
                  </ModalFooter>
                </>
              )
            }}
          </Formik>
        </ModalContent>
      </ModalWrapper>
    </ReactModal>
  )
}
