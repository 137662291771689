import qs from 'query-string'
import { PageDto } from 'src/model/page-dto'
import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { HTTPMethods } from '../http-enums-methods'
import {
  CuratorGroupServiceInterface,
  RecruitmentGroupPageParams,
  RecruitmentGroupPageDto,
  RecruitmentGroupStudentsInfoDto,
} from '../interfaces/curator-services/curator-group-service'

type CreateRecruitmentGroupBody = {
  channelName: string | null
  mentorId: number
  name: string
  recruitmentId: number
}
class CuratorGroupService implements CuratorGroupServiceInterface {
  private readonly sourceURL = `${serverHost}/api/curator/group`

  public getRecruitmentGroupPage = ({
    pageNumber,
    filterPattern,
    sortType,
    directionId,
    mentorId,
    recruitmentId,
    onlySelf,
  }: RecruitmentGroupPageParams) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/page/${pageNumber}?${qs.stringify({
      filterPattern,
      sortType,
      directionId,
      mentorId,
      recruitmentId,
      onlySelf,
    })}`
    return ApiService.request<PageDto<RecruitmentGroupPageDto>>(url, queryOption)
  }

  public getStudents = (recruitmentGroupId: number) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${recruitmentGroupId}/student`
    return ApiService.request<RecruitmentGroupStudentsInfoDto>(url, queryOption)
  }

  public startRecruitmentGroupProcessing = (recruitmentGroupId: number) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: AuthService.authHeaders(),
    }

    const url = `${this.sourceURL}/${recruitmentGroupId}/slack`
    return ApiService.request<void>(url, queryOption)
  }

  public createRecruitmentGroup = ({ channelName, mentorId, name, recruitmentId }: CreateRecruitmentGroupBody) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: { ...AuthService.authHeaders(), 'content-type': 'application/json;charset=UTF-8' },
      body: JSON.stringify({
        channelName,
        mentorId,
        name,
        recruitmentId,
      }),
    }

    const url = `${this.sourceURL}`
    return ApiService.request<void>(url, queryOption)
  }

  public editRecruitmentGroup = ({
    id,
    channelName,
    mentorId,
    name,
    recruitmentId,
  }: CreateRecruitmentGroupBody & { id: number }) => {
    const queryOption = {
      method: HTTPMethods.PUT,
      headers: { ...AuthService.authHeaders(), 'content-type': 'application/json;charset=UTF-8' },
      body: JSON.stringify({
        id,
        channelName,
        mentorId,
        name,
        recruitmentId,
      }),
    }

    const url = `${this.sourceURL}`
    return ApiService.request<void>(url, queryOption)
  }
}

export default CuratorGroupService
