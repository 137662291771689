import React, { useEffect, useState, useMemo, useCallback, createContext } from 'react'

import { useReactAlert } from 'src/hooks/useReactAlert'
import {
  StudentAnalyticsSortType,
  StudentAnalyticsDto,
  RecruitmentGroupStudentStatus,
} from 'src/model/student-dto/student-dto'
import { RecruitmentShortInfoDto } from 'src/model/recruitment-dto/recruitment-dto'
import { InputGroup, FormControl } from 'react-bootstrap'
import Pagination from 'rc-pagination'
import RecruitmentService from 'src/services/recruitment-service'
import { RoleEnum } from 'src/utils/select-state/RoleEnum'
import { CuratorMentorService } from 'src/services/curator-services/curator-mentor-service'
import 'rc-pagination/assets/index.css'
import { CuratorDirectionDto } from 'src/model/direction-model'
import CuratorAnalyticService from 'src/services/curator-services/curator-analytic'
import { MentorGetDto } from 'src/model/mentor-model'
import { curatorStudentService, CuratorStudentService } from 'src/services/curator-services/curator-student-service'
import { CuratorDirectionsService } from 'src/services/curator-services/curator-direction-service'
import { LoadingOverlay } from '../loading-overlay'
import './styles.css'
import { StudentsTable } from './components/students-table'

const curatorAnalyticService = new CuratorAnalyticService()
const curatorRecruitmentService = new RecruitmentService(RoleEnum.CURATOR)

export const StudentAnalyticsContext = createContext<{
  curatorStudentService: CuratorStudentService
  fetchStudentAnalytics: () => void
}>({
  curatorStudentService,
  fetchStudentAnalytics: () => {},
})

export const StudentAnalyticsPage = () => {
  const { catchErrorAlert } = useReactAlert()

  const [queryString, setQueryString] = useState<string>('')
  const [filterPattern, setFilterPattern] = useState<string>('')

  const [allDirections, setAllDirections] = useState<CuratorDirectionDto[]>()
  const [selectedDirection, setSelectedDirection] = useState<number>()

  const [allMentors, setAllMentors] = useState<MentorGetDto[]>()
  const [selectedMentor, setSelectedMentor] = useState<number>()

  const [allRecruitments, setAllRecruitments] = useState<RecruitmentShortInfoDto[]>()
  const [selectedRecruitment, setSelectedRecruitment] = useState<number>()

  const [selectedStatus, setSelectedStatus] = useState<RecruitmentGroupStudentStatus>()

  const [onlySelf, setOnlySelf] = useState(false)

  const [students, setStudents] = useState<StudentAnalyticsDto[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPageCount, setTotalPageCount] = useState<number>(0)

  const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC')
  const [sortType, setSortType] = React.useState<StudentAnalyticsSortType | undefined>()

  const [isLoading, setLoading] = useState(false)

  const fetchStudentAnalytics = useCallback(() => {
    curatorAnalyticService
      .getStudentAnalyticsPage({
        pageNumber: currentPage,
        filterPattern,
        directionId: selectedDirection,
        mentorId: selectedMentor,
        recruitmentId: selectedRecruitment,
        studentStatus: selectedStatus,
        onlySelf,
        sortType,
      })
      .then(response => {
        setStudents(response.entities)
        setTotalPageCount(response.count)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [
    currentPage,
    filterPattern,
    selectedDirection,
    selectedMentor,
    selectedRecruitment,
    selectedStatus,
    onlySelf,
    sortType,
  ])

  useEffect(() => {
    fetchStudentAnalytics()
  }, [
    currentPage,
    filterPattern,
    selectedDirection,
    selectedMentor,
    selectedRecruitment,
    selectedStatus,
    onlySelf,
    sortType,
  ])

  useEffect(() => {
    CuratorDirectionsService.getAll()
      .then(directions => {
        setAllDirections(directions)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [])

  useEffect(() => {
    if (selectedDirection) {
      CuratorMentorService.getMentors(selectedDirection)
        .then(mentors => {
          setAllMentors(mentors)
        })
        .catch(error => {
          catchErrorAlert(error)
        })
    }
  }, [selectedDirection])

  useEffect(() => {
    curatorRecruitmentService
      .getRecruitments({ onlySelf, directionId: selectedDirection })
      .then(recruitments => {
        setAllRecruitments(recruitments)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [onlySelf, selectedDirection])

  const handleChangeSort = (newSortType: StudentAnalyticsSortType) => {
    setSortType(newSortType)
    setOrder(currentOrder => (currentOrder === 'ASC' ? 'DESC' : 'ASC'))
  }

  const renderOptions = (options?: { id: string | number; name: string }[]) => {
    const defaultOption = (
      <option key="empty" value="">
        Все
      </option>
    )
    return options
      ? [
          defaultOption,
          ...options.map(option => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          )),
        ]
      : defaultOption
  }

  const directionOptions = useMemo(() => renderOptions(allDirections?.map(({ id, name }) => ({ id, name }))), [
    allDirections,
  ])

  const mentorOptions = useMemo(
    () => renderOptions(allMentors?.map(({ id, firstName, lastName }) => ({ id, name: `${firstName} ${lastName}` }))),
    [allMentors]
  )

  const recruitmentOptions = useMemo(
    () => renderOptions(allRecruitments?.map(({ id, channelName }) => ({ id, name: channelName }))),
    [allRecruitments]
  )

  const statusLabel = {
    [RecruitmentGroupStudentStatus.EXPELLED]: 'Отчислен',
    [RecruitmentGroupStudentStatus.RETURNED]: 'Восстановился',
    [RecruitmentGroupStudentStatus.STUDY]: 'Учится',
    [RecruitmentGroupStudentStatus.TRANSFERRED]: 'Переведен в другую группу',
  }

  const statusOptions = useMemo(
    () =>
      renderOptions(
        Object.values(RecruitmentGroupStudentStatus).map(status => ({ id: status, name: statusLabel[status] }))
      ),
    [allRecruitments]
  )

  return (
    <StudentAnalyticsContext.Provider value={{ curatorStudentService, fetchStudentAnalytics }}>
      <div className="content">
        <div className="curator-content">
          <h1 className="page-header">Аналитика по студентам</h1>
          <div className="pagination-search-group">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <i className="bi bi-search" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                onChange={e => setQueryString(e.target.value)}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter') {
                    setFilterPattern(queryString)
                  }
                }}
              />
            </InputGroup>
          </div>
          <div className="filters">
            <label className="label">
              <span>Направление:</span>
              <FormControl
                as="select"
                value={selectedDirection ?? ''}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setSelectedDirection(e.target.value ? Number(e.target.value) : undefined)
                  setSelectedMentor(undefined)
                }}
                className="review-filter-selector"
              >
                {directionOptions}
              </FormControl>
            </label>

            <label className="label">
              <span>Ментор:</span>
              <FormControl
                disabled={!selectedDirection}
                as="select"
                value={selectedMentor ?? ''}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setSelectedMentor(e.target.value ? Number(e.target.value) : undefined)
                }}
                className="review-filter-selector"
              >
                {mentorOptions}
              </FormControl>
            </label>

            <label className="label">
              <span>Поток:</span>
              <FormControl
                as="select"
                value={selectedRecruitment ?? ''}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const recruitmentId = e.target.value ? Number(e.target.value) : undefined
                  setSelectedRecruitment(recruitmentId)
                }}
                className="review-filter-selector"
              >
                {recruitmentOptions}
              </FormControl>
            </label>

            <label className="label">
              <span>Статус:</span>
              <FormControl
                as="select"
                value={selectedStatus ?? ''}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setSelectedStatus(e.target.value as RecruitmentGroupStudentStatus)
                }}
                className="review-filter-selector"
              >
                {statusOptions}
              </FormControl>
            </label>

            <label className="label">
              Только свои:
              <input
                type="checkbox"
                className="self"
                checked={onlySelf}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setOnlySelf(e.target.checked)
                  setSelectedRecruitment(undefined)
                }}
              />
            </label>
          </div>
          <StudentsTable students={students} onChangeSort={handleChangeSort} sortType={sortType} order={order} />
          <Pagination
            onChange={page => setCurrentPage(page)}
            current={currentPage}
            total={totalPageCount}
            pageSize={20}
            showTitle={false}
          />
          <LoadingOverlay open={isLoading} />
        </div>
      </div>
    </StudentAnalyticsContext.Provider>
  )
}
