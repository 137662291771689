import React, { FC } from 'react'
import { Form } from 'react-bootstrap'
import { addItem, deleteItemByIndex, getIndexByKeyValue } from 'src/utils/ArraysUtils'
import { ParameterValue } from '../../../../services/filter-pagination-service'

import { ReportFilterProps } from '../types'

export const ReportFilters: FC<ReportFilterProps> = ({
  paginationParams,
  setPaginationParams,
  paginationParamChangeHandlers,
  paginationParamPairs,
}) => {
  const handleFilterSelect = (paramName: string, paramValue: number | null | string) => {
    const isFirstSelectCleared = paramValue === null && getIndexByKeyValue(paginationParams, 'name', paramName) === 0
    if (isFirstSelectCleared) {
      setPaginationParams([])
      if (paginationParamChangeHandlers) {
        Object.values(paginationParamChangeHandlers).forEach(handler => handler(null))
      }
    }
    const newParams = deleteItemByIndex(paginationParams, getIndexByKeyValue(paginationParams, 'name', paramName))

    const paginationParamHandler = paginationParamChangeHandlers[paramName]
    if (paginationParamChangeHandlers && paginationParamHandler) {
      paginationParamHandler(paramValue)
    }
    setPaginationParams(
      addItem(newParams, {
        name: paramName,
        id: paramValue,
      } as ParameterValue)
    )
  }

  return (
    <>
      {!paginationParamPairs
        ? null
        : paginationParamPairs.map(({ parameterValues, parameterName, parameterDisplayedName }) => (
            <label key={parameterName}>
              <span>{`${parameterDisplayedName}:`}</span>
              <Form.Control
                as="select"
                onChange={event => {
                  handleFilterSelect(parameterName, event.target.value || null)
                }}
                disabled={parameterValues.length === 0}
              >
                {parameterValues.map(({ id, name }) => (
                  <option key={id} value={id || ''}>
                    {name}
                  </option>
                ))}
              </Form.Control>
            </label>
          ))}
    </>
  )
}
