import React, { useEffect, useCallback, useState, createContext } from 'react'
import { useParams } from 'react-router-dom'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { History } from 'history'
import './styles.css'
import { curatorRecruitmentService } from 'src/services/curator-services/curator-recruitment-service'
import { RecruitmentMeetContentDto, DeadlineDto } from 'src/model/recruitment-dto/recruitment-dto'
import { RecruitmentGroupStudentStatus } from 'src/model/student-dto/student-dto'
import { format, parse } from 'date-fns'
import { ru } from 'date-fns/esm/locale'
import { InputGroup, FormControl, Button, Dropdown } from 'react-bootstrap'
import { CompleteMeetModal } from 'src/components/pages/curator/components/meet-page/components/completeMeetModal'
import { StudentsTable } from './components/StudentsTable'
import { LeaveCommentModal } from './components/LeaveCommentModal'
import { ReviewHistoryModal } from '../modals/review-history-modal'
import { DeadlineModal } from '../modals/deadline-modal'
import { EnrollModal } from '../modals/enroll-modal'
import { StudentExpulsionDetailsModal } from '../student-analytics-page/components/student-expulsion-details-modal'

export const MeetPageContext = createContext<{
  fetchRecruitmentMeet: () => void
  setOpenLeaveCommentModal: React.Dispatch<React.SetStateAction<boolean>>

  setSelectedMeetEntryId: React.Dispatch<React.SetStateAction<string | undefined>>
  setOpenReviewHistory: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedStudentId: React.Dispatch<React.SetStateAction<number | undefined>>
  setOpenDeadline: React.Dispatch<React.SetStateAction<boolean>>
  setOpenEnroll: React.Dispatch<React.SetStateAction<boolean>>
  setOpenExpelling: React.Dispatch<React.SetStateAction<boolean>>
}>({
  fetchRecruitmentMeet: () => {},
  setOpenLeaveCommentModal: () => {},
  setSelectedMeetEntryId: () => {},

  setOpenReviewHistory: () => {},
  setSelectedStudentId: () => {},
  setOpenDeadline: () => {},
  setOpenEnroll: () => {},
  setOpenExpelling: () => {},
})

type TProps = {
  history: History
}
type MeetStudentStatus = RecruitmentGroupStudentStatus.EXPELLED | RecruitmentGroupStudentStatus.STUDY

const statusLabelMap: Record<MeetStudentStatus, string> = {
  [RecruitmentGroupStudentStatus.EXPELLED]: 'Отчислен',
  [RecruitmentGroupStudentStatus.STUDY]: 'Учится',
}
const statusLabels: MeetStudentStatus[] = [RecruitmentGroupStudentStatus.EXPELLED, RecruitmentGroupStudentStatus.STUDY]

export const MeetPage: React.FC<TProps> = ({ history }) => {
  const { catchErrorAlert, reactAlert } = useReactAlert()
  const { recruitmentMeetId } = useParams<{ recruitmentMeetId: string }>()
  const [recruitmentMeet, setRecruitmentMeet] = useState<RecruitmentMeetContentDto>()
  const [conferenceLink, setConferenceLink] = useState('')
  const [studentStatus, setStudentStatus] = useState('')
  const [deadlines, setDeadlines] = useState<DeadlineDto[]>()
  const [showDropdown, setShowDropdown] = useState(false)
  const [openLeaveCommentModal, setOpenLeaveCommentModal] = useState(false)
  const [selectedMeetEntryId, setSelectedMeetEntryId] = useState<string | undefined>()
  const [openReviewHistory, setOpenReviewHistory] = useState(false)
  const [selectedStudentId, setSelectedStudentId] = useState<number>()
  const [openDeadline, setOpenDeadline] = useState(false)
  const [openEnroll, setOpenEnroll] = useState(false)
  const [openExpelling, setOpenExpelling] = useState(false)
  const [openCompleteMeetModal, setOpenCompleteMeetModal] = useState(false)
  const fetchRecruitmentMeet = useCallback(
    () =>
      curatorRecruitmentService
        .getRecruitmentMeet(Number(recruitmentMeetId), studentStatus)
        .then(response => {
          setRecruitmentMeet(response)
          setConferenceLink(response.conferenceLink ?? '')
        })
        .catch(error => catchErrorAlert(error)),
    [recruitmentMeetId, studentStatus]
  )

  const redirectToMeetPlanPage = (id?: number) =>
    history.push(id ? `/curator/students/meet-plan?recruitmentId=${id}` : `/curator/students/meet-plan`)

  const fetchDeadlines = useCallback(
    recruitmentId =>
      curatorRecruitmentService
        .getRecruitmentDeadlines(recruitmentId)
        .then(response => setDeadlines(response))
        .catch(error => catchErrorAlert(error)),
    [recruitmentMeetId]
  )

  const startCall = useCallback(
    () =>
      curatorRecruitmentService
        .startRecruitmentCall(recruitmentMeetId, conferenceLink)
        .then(() => reactAlert.success(`Созвон начался ${conferenceLink}`))
        .catch(error => catchErrorAlert(error)),
    [recruitmentMeetId, conferenceLink]
  )

  const finishCall = useCallback(
    () =>
      curatorRecruitmentService
        .finishRecruitmentCall(recruitmentMeetId)
        .then(() => {
          setOpenCompleteMeetModal(true)
        })
        .catch(error => catchErrorAlert(error)),
    [recruitmentMeetId]
  )

  useEffect(() => {
    if (recruitmentMeet?.recruitmentId) {
      fetchDeadlines(recruitmentMeet.recruitmentId)
    }
  }, [recruitmentMeet])

  useEffect(() => {
    fetchRecruitmentMeet()
  }, [studentStatus])

  useEffect(() => {
    const timerId = setInterval(fetchRecruitmentMeet, 10000)
    return () => clearInterval(timerId)
  }, [fetchRecruitmentMeet])

  const getDate = (date: string) => {
    const fullDate = parse(date, 'dd.MM.yyyy HH:mm', new Date())
    const weekDay = format(fullDate, 'EEEE', { locale: ru })
    const ddmmyyyy = format(fullDate, 'dd.MM.yyyy')
    const hour = format(fullDate, 'HH')
    const minute = format(fullDate, 'mm')
    return `${weekDay}, ${ddmmyyyy}г. в ${hour}:${minute}`
  }

  return (
    <MeetPageContext.Provider
      value={{
        fetchRecruitmentMeet,
        setOpenLeaveCommentModal,
        setSelectedMeetEntryId,
        setOpenReviewHistory,
        setSelectedStudentId,
        setOpenDeadline,
        setOpenEnroll,
        setOpenExpelling,
      }}
    >
      <div className="meet-page">
        <div className="meet-page__header">
          <span className="mdi mdi-phone" />
          <span>
            Проведение созвона с потоком: <b>{recruitmentMeet?.recruitmentChannelName}</b>
          </span>
          <span>
            Дата созвона с потоком: <b>{recruitmentMeet?.startDateTime && getDate(recruitmentMeet.startDateTime)}</b>
          </span>
        </div>
        <div className="meet-page__controls">
          <InputGroup className="conf-link">
            Ссылка на конференцию:
            <FormControl
              type="link"
              className="conf-link-input"
              onChange={e => {
                setConferenceLink(e.target.value)
              }}
              value={conferenceLink}
            />
            <Button variant="success" onClick={startCall} disabled={!conferenceLink}>
              Начать созвон
            </Button>
          </InputGroup>
          <div className="status-select__wrapper">
            <label className="status-select__label" htmlFor="statusSelect">
              Выберите статус студента
            </label>
            <select
              className="status-select"
              id="statusSelect"
              onChange={e => setStudentStatus(e.target.value)}
              value={studentStatus}
            >
              <option value="">Любой</option>
              {statusLabels.map(item => (
                <option value={item}>{statusLabelMap[item]}</option>
              ))}
            </select>
          </div>
          <Dropdown
            onToggle={isOpen => setShowDropdown(isOpen)}
            show={showDropdown}
            onClick={(e: any) => e.stopPropagation()}
          >
            <Dropdown.Toggle disabled={!deadlines?.length} variant="primary" className="no-icon">
              Показать дедлайны потока
            </Dropdown.Toggle>
            <Dropdown.Menu
              onMouseLeave={() => {
                setShowDropdown(false)
              }}
            >
              {deadlines?.map(deadline => (
                <Dropdown.Item key={deadline.id} onClick={() => {}}>
                  {deadline.courseName} - {deadline.deadlineDate}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="meet-page__content">
          <StudentsTable students={recruitmentMeet?.meetEntries || []} />
        </div>
        <div className="meet-page__footer">
          <Button variant="warning" onClick={() => redirectToMeetPlanPage()}>
            Закрыть
          </Button>
          <Button variant="success" onClick={finishCall}>
            Завершить и сохранить
          </Button>
        </div>
      </div>
      {selectedMeetEntryId && (
        <LeaveCommentModal
          onClose={() => {
            setOpenLeaveCommentModal(false)
            setSelectedMeetEntryId(undefined)
          }}
          open={openLeaveCommentModal}
          recruitmentMeetId={Number(recruitmentMeetId)}
          meetEntryId={Number(selectedMeetEntryId)}
        />
      )}
      {selectedStudentId && (
        <ReviewHistoryModal
          open={openReviewHistory}
          onClose={() => {
            setOpenReviewHistory(false)
            setSelectedStudentId(undefined)
          }}
          studentId={selectedStudentId}
        />
      )}
      {selectedStudentId && (
        <DeadlineModal
          open={openDeadline}
          onClose={() => {
            setOpenDeadline(false)
            setSelectedStudentId(undefined)
          }}
          onSuccess={fetchRecruitmentMeet}
          studentId={selectedStudentId}
        />
      )}
      {selectedStudentId && (
        <EnrollModal
          type="transfer"
          open={openEnroll}
          onClose={() => {
            setOpenEnroll(false)
            setSelectedStudentId(undefined)
          }}
          onSuccess={fetchRecruitmentMeet}
          studentsIds={[selectedStudentId]}
        />
      )}
      {selectedStudentId && (
        <StudentExpulsionDetailsModal
          open={openExpelling}
          onClose={() => {
            setOpenExpelling(false)
            setSelectedStudentId(undefined)
          }}
          studentId={selectedStudentId}
        />
      )}
      <CompleteMeetModal
        open={openCompleteMeetModal}
        onClose={() => setOpenCompleteMeetModal(false)}
        onConfirm={() => redirectToMeetPlanPage(recruitmentMeet?.recruitmentId)}
      />
    </MeetPageContext.Provider>
  )
}
