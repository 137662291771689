import { useCallback, useState } from 'react'
import { MeetPlanDto, MeetPlanSubmitData } from '../../../../../../model/curator-dto/meet-plan-dto'
import { useReactAlert } from '../../../../../../hooks/useReactAlert'
import {
  CuratorRecruitmentMeetService,
  RecruitmentMeetCalendarQueryParams,
} from '../../../../../../services/curator-services/curator-recruitment-meet-service'

const useRecruitmentMeetCalendar = () => {
  const [recruitmentMeetData, setRecruitmentMeetData] = useState<MeetPlanDto[] | null>(null)
  const [loadingMeetData, setLoadingMeetData] = useState(false)
  const { catchErrorAlert, reactAlert } = useReactAlert()

  const getRecruitmentsMeetData = useCallback((queryParams: RecruitmentMeetCalendarQueryParams) => {
    setLoadingMeetData(true)
    CuratorRecruitmentMeetService.getRecruitmentMeetCalendar(queryParams)
      .then(meetData => {
        setRecruitmentMeetData(meetData)
        setLoadingMeetData(false)
      })
      .catch((error: Error) => {
        catchErrorAlert(error)
        setLoadingMeetData(false)
      })
  }, [])

  const createRecruitmentMeetCall = (data: MeetPlanSubmitData): Promise<void> => {
    return CuratorRecruitmentMeetService.createRecruitmentMeet(data)
      .then(() => reactAlert.success('Встреча успешно создана'))
      .catch((error: Error) => catchErrorAlert(error))
  }

  const deleteRecruitmentMeetCall = (recruitmentMeetId: number): Promise<void> => {
    return CuratorRecruitmentMeetService.deleteRecruitmentMeet(recruitmentMeetId)
      .then(() => reactAlert.success('Встреча успешно удалена'))
      .catch((error: Error) => catchErrorAlert(error))
  }

  const updateRecruitmentMeetCall = (data: MeetPlanSubmitData): Promise<void> => {
    return CuratorRecruitmentMeetService.updateRecruitmentMeet(data)
      .then(() => reactAlert.success('Встреча успешно обновлена'))
      .catch((error: Error) => catchErrorAlert(error))
  }

  return {
    recruitmentMeetData,
    createRecruitmentMeetCall,
    deleteRecruitmentMeetCall,
    updateRecruitmentMeetCall,
    getRecruitmentsMeetData,
    loadingMeetData,
  }
}

export default useRecruitmentMeetCalendar
