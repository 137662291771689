import React, { FC, useRef } from 'react'
import classNames from 'classnames/bind'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'

import { MONTHS_WITH_VALUES } from 'src/utils/dateUtils'
import { getDayFromMonth } from '../utils'

import { SwiperInstanceRef, MonthSwiperProps } from '../types'
import { UNPERMITTED_INDEXES, FIRST_MONTH_INDEX, LAST_MONTH_INDEX } from '../constants'

import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import styles from './month-swiper.module.scss'

const cn = classNames.bind(styles)

export const MonthSwiper: FC<MonthSwiperProps> = ({ calendarConfig, filterCalendar, setFilterCalendar }) => {
  const { selectedMonthId, selectedYear } = filterCalendar
  const swiperDateRef = useRef<SwiperInstanceRef>()

  const checkIsNextSlideDisabledToSwipe = () => {
    const isCurrentMonth = new Date().getMonth() === selectedMonthId
    const isCurrentYear = selectedYear === new Date().getFullYear()

    return isCurrentMonth && isCurrentYear
  }

  const handlePrevSlideChange = () => {
    let currentSlideIndex = swiperDateRef.current?.realIndex! - 1
    const { pointerLeft, pointerRight } = calendarConfig.current
    let newSelectedYear = selectedYear

    if (UNPERMITTED_INDEXES.includes(currentSlideIndex)) {
      currentSlideIndex = LAST_MONTH_INDEX
    }

    if (currentSlideIndex === LAST_MONTH_INDEX) {
      newSelectedYear = new Date().getFullYear() - 1
    }

    setFilterCalendar({
      selectedYear: newSelectedYear,
      selectedMonthId: currentSlideIndex,
      startPublicDate: getDayFromMonth(pointerLeft, currentSlideIndex, selectedYear),
      endPublicDate: getDayFromMonth(pointerRight, currentSlideIndex, selectedYear),
    })

    swiperDateRef.current?.slidePrev()
  }

  const handleNextSlideChange = () => {
    let currentSlideIndex = swiperDateRef.current?.realIndex! + 1
    const { pointerLeft, pointerRight } = calendarConfig.current
    let newSelectedYear = selectedYear

    if (UNPERMITTED_INDEXES.includes(currentSlideIndex)) {
      currentSlideIndex = FIRST_MONTH_INDEX
    }

    if (currentSlideIndex === FIRST_MONTH_INDEX) {
      newSelectedYear = new Date().getFullYear()
    }

    setFilterCalendar({
      selectedYear: newSelectedYear,
      selectedMonthId: currentSlideIndex,
      startPublicDate: getDayFromMonth(pointerLeft, currentSlideIndex, selectedYear),
      endPublicDate: getDayFromMonth(pointerRight, currentSlideIndex, selectedYear),
    })

    swiperDateRef.current?.slideNext()
  }

  return (
    <div className={cn('select-date-wrapper')}>
      <button
        type="button"
        className={cn('report-select-date__prev-btn', 'report-select-date__btn')}
        disabled={false}
        onClick={handlePrevSlideChange}
      >
        &lt;
      </button>
      <Swiper
        loop
        navigation={{
          nextEl: '.review-swiper-button-next',
          prevEl: '.review-swiper-button-prev',
        }}
        modules={[Navigation]}
        allowTouchMove={false}
        initialSlide={filterCalendar.selectedMonthId}
        onBeforeInit={swiper => {
          swiperDateRef.current = swiper
        }}
        className={cn('select-date')}
      >
        {MONTHS_WITH_VALUES.map((month: { name: string; value: number }) => (
          <SwiperSlide key={month.value} className={cn('select-date__card')}>
            <span className={cn('select-date__month')}>{month.name}</span>
            <span className={cn('select-date__year')}>{filterCalendar.selectedYear}</span>
          </SwiperSlide>
        ))}
      </Swiper>
      <button
        className={cn('report-select-date__next-btn', 'report-select-date__btn')}
        disabled={checkIsNextSlideDisabledToSwipe()}
        onClick={handleNextSlideChange}
        type="button"
      >
        &gt;
      </button>
    </div>
  )
}
