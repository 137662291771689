import React, { useContext } from 'react'

import { Dropdown } from 'react-bootstrap'
import { RecruitmentMeetEntryDto } from 'src/model/recruitment-dto/recruitment-dto'
import { useStickyHeader } from '../useStickyHeader'
import { StudentRow } from './StudentRow'
import { MeetPageContext } from '../meet-page'
import '../styles.css'

export type StudentsTableProps = {
  students: RecruitmentMeetEntryDto[]
}

export const StudentsTable: React.FC<StudentsTableProps> = ({ students }) => {
  const {
    setSelectedMeetEntryId,
    setOpenLeaveCommentModal,
    setOpenReviewHistory,
    setSelectedStudentId,
    setOpenDeadline,
    setOpenEnroll,
    setOpenExpelling,
  } = useContext(MeetPageContext)
  const { tableRef, isSticky } = useStickyHeader()

  const renderHeader = () => (
    <thead className="meet-page-table__head">
      <tr>
        <th className="meet-page-table__th">
          <b>id</b>
        </th>
        <th className="meet-page-table__th">
          <b>Студент</b>
        </th>
        <th className="meet-page-table__th">
          <b>Текущий курс</b>
        </th>
        <th className="meet-page-table__th">
          <b>Модуль</b>
        </th>
        <th className="meet-page-table__th">
          <b>Прогресс</b>
        </th>
        <th className="meet-page-table__th">
          <b>Последнее решение</b>
        </th>
        <th className="meet-page-table__th">
          <b>Дедлайн</b>
        </th>
        <th className="meet-page-table__th">
          <b>Статус</b>
        </th>
        <th className="meet-page-table__th">
          <b>Последний комментарий</b>
        </th>
        <th className="meet-page-table__th">
          <b>История присутствия</b>
        </th>
        <th className="meet-page-table__th">
          <b>Присутствие</b>
        </th>
        <th className="meet-page-table__th">
          <b>Действие</b>
        </th>
      </tr>
    </thead>
  )

  return (
    <div className="meet-page-table">
      {isSticky && (
        /*
        .sticky will be the copy of table header while sticky
        needed as otherwise table won't preserve columns width
        */
        <table
          className="sticky"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
          }}
        >
          {renderHeader()}
        </table>
      )}
      <table className="table table-striped" ref={tableRef}>
        {renderHeader()}
        <tbody className="meet-page-table__body">
          {students.map(student => (
            <StudentRow
              key={student.payStudentId}
              student={student}
              action={
                <Dropdown>
                  <Dropdown.Toggle className="meet-page-table__action-btn" variant="secondary">
                    Выбрать
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        setOpenLeaveCommentModal(true)
                        setSelectedMeetEntryId(String(student.meetEntryId))
                      }}
                    >
                      Оставить комментарий
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setOpenReviewHistory(true)
                        setSelectedStudentId(student.payStudentId)
                      }}
                    >
                      История ревью
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setOpenDeadline(true)
                        setSelectedStudentId(student.payStudentId)
                      }}
                    >
                      Перенос дедлайнов
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setOpenEnroll(true)
                        setSelectedStudentId(student.payStudentId)
                      }}
                    >
                      Перевод
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setOpenExpelling(true)
                        setSelectedStudentId(student.payStudentId)
                      }}
                    >
                      Отчисление
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              }
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}
