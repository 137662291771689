import React, { useState, useEffect } from 'react'
import PaginationComponent from 'src/components/hoc/pagination-component'
import QueQueService, { PaginationParamPair, ParameterValue } from 'src/services/filter-pagination-service'
import { useReactAlert } from '../../../hooks/useReactAlert'
import { StudentsProjectQueueListWrapper } from './students-project-queue-list/students-project-queue-list-wrapper'
import { ProjectQueueDto } from '../../../model/project-queue-dto/project-queue-dto'
import { AuthService } from '../../../services/auth-service'
import { assertRole } from '../../../utils/assertRole'
import { RoleEnum } from '../../../utils/select-state/RoleEnum'
import { AdminDirectionsService } from '../../../services/admin-services/admin-directions-service'
import AdminDirectionService from '../../../services/admin-services/admin-direction-service'
import ChiefMentorsService from '../../../services/chief-services/chief-mentor-service'
import { deleteItemByIndex, getIndexByKeyValue } from '../../../utils/ArraysUtils'
import { PaymentType } from '../../../model/payment-type-enum'

const StudentsProjectQueuePage: React.FC = () => {
  const [paginationParamPairs, setPaginationParamPairs] = useState<PaginationParamPair[]>([])
  const [currentDirectionId, setCurrentDirectionId] = useState<number | string | null>(null)
  const [paymentType, setPaymentType] = useState<PaymentType | null>(null)

  const currentRole = AuthService.currentUserValue()?.role.name
  assertRole(currentRole)
  const { catchErrorAlert } = useReactAlert()

  const AdminMentorService = new AdminDirectionService()

  const paymentTypes = [
    { id: PaymentType.UNTIL_PAID, name: 'Постоплата' },
    { id: PaymentType.REGULAR, name: 'Предоплата' },
    { id: PaymentType.TRIAL, name: 'Пробный период' },
  ]

  useEffect(() => {
    if (currentRole === RoleEnum.MENTOR) {
      setPaginationParamPairs([
        {
          parameterName: 'paymentType',
          parameterDisplayedName: 'Тип оплаты',
          parameterValues: [{ name: 'Все', id: null }, ...paymentTypes],
        },
      ])
      return
    }
    if (currentRole === RoleEnum.CHIEF_MENTOR) {
      ChiefMentorsService.getMentors().then(mentors => {
        const mentorsAsParams = mentors.map(mentor => {
          const { id, lastName, firstName } = mentor
          const name = `${lastName} ${firstName}`
          return { id, name } as ParameterValue
        })

        setPaginationParamPairs([
          {
            parameterName: 'mentorId',
            parameterDisplayedName: 'Ментор',
            parameterValues: [{ name: 'Все', id: null }, ...mentorsAsParams],
          },
          {
            parameterName: 'paymentType',
            parameterDisplayedName: 'Тип оплаты',
            parameterValues: [{ name: 'Все', id: null }, ...paymentTypes],
          },
        ])
      })
      return
    }
    AdminDirectionsService.getAll()
      .then(directions => {
        setPaginationParamPairs([
          {
            parameterName: 'directionId',
            parameterDisplayedName: 'Направление',
            parameterValues: [{ name: 'Все', id: null }, ...directions],
          },
          {
            parameterName: 'mentorId',
            parameterDisplayedName: 'Ментор',
            parameterValues: [{ name: 'Все', id: null }],
          },
          {
            parameterName: 'paymentType',
            parameterDisplayedName: 'Тип оплаты',
            parameterValues: [{ name: 'Все', id: null }, ...paymentTypes],
          },
        ])
      })
      .catch(catchErrorAlert)
      .then(() => {
        if (currentDirectionId) {
          AdminMentorService.getMentorsByDirectionId(currentDirectionId).then((mentors: any) => {
            const mentorsAsParams = mentors.map((mentor: any) => {
              const { id, lastName, firstName } = mentor
              const name = `${lastName} ${firstName}`
              return { id, name }
            })

            setPaginationParamPairs(params => {
              const newParams = deleteItemByIndex(params, getIndexByKeyValue(params, 'parameterName', 'mentorId'))
              return [
                ...newParams,
                {
                  parameterName: 'mentorId',
                  parameterDisplayedName: 'Ментор',
                  parameterValues: [{ name: 'Все', id: null }, ...mentorsAsParams],
                },
              ]
            })
          })
        }
      })
      .catch(catchErrorAlert)
  }, [currentDirectionId])

  const handleDirectionSelect = (paramValue: number | string | null) => {
    setCurrentDirectionId(paramValue)
  }

  const isPaymentType = (maybePaymentType: unknown): maybePaymentType is PaymentType | null => {
    return Boolean(maybePaymentType && Object.values<unknown>(PaymentType).includes(maybePaymentType))
  }

  const handlePaymentTypeChange = (paramValue: number | string | null) => {
    if (!isPaymentType(paramValue)) {
      return
    }

    setPaymentType(paramValue)
  }

  const BaseUrlData = {
    [RoleEnum.ADMIN]: `/api/admin/user/student/project/queue`,
    [RoleEnum.MENTOR]: `/api/mentor/user/student/project/queue`,
    [RoleEnum.CHIEF_MENTOR]: `/api/chief/user/student/project/queue`,
  }

  const ProjectQueueService = new QueQueService<ProjectQueueDto>(BaseUrlData[currentRole])

  const CustomPaginationComponent = PaginationComponent<ProjectQueueDto>()
  return (
    <div className="mentor-content">
      <CustomPaginationComponent
        paginationParamPairs={paginationParamPairs}
        Wrapper={StudentsProjectQueueListWrapper}
        service={ProjectQueueService}
        paginationParamChangeHandlers={{
          directionId: handleDirectionSelect,
          paymentType: handlePaymentTypeChange,
        }}
        headerText="Студенты, ожидающие проекта"
        showDateAnalytics
      />
    </div>
  )
}

export default StudentsProjectQueuePage
