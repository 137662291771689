import React, { useEffect, useState } from 'react'
import { formatDateToDDMMYYYY } from 'src/utils/dateUtils'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { ModuleDto } from 'src/model/module-dto/module-dto'
import { ReviewType } from 'src/model/review/review-type'
import Spinner from '../../../../spinner'
import ChiefPlanningReviewsTable from '../../../chief/components/chief-planning-reviews/chief-planning-reviews-table'
import ChiefPlanningReviewsMentorList from '../../../chief/components/chief-planning-reviews/chief-planning-reviews-mentor-list'
import PlanningReviewCreateModal from '../../../chief/components/chief-planning-reviews/chief-planning-reviews-table/planning-review-create-modal'
import PlanningReviewEditModal from '../../../chief/components/chief-planning-reviews/chief-planning-reviews-table/planning-review-edit-modal/planning-review-edit-modal'
import PlanningReviewConfirmRemoveReviewModal from '../../../chief/components/chief-planning-reviews/chief-planning-reviews-table/planning-review-confirm-remove-review-modal'
import AdminPlanningReviewsActionPanel from '../../../admin/reviews/admin-planning-reviews-action-panel'
import { CuratorReviewService } from '../../../../../services/curator-services/curator-review-service'
import { CuratorDirectionsService } from '../../../../../services/curator-services/curator-direction-service'
import { CuratorMentorService } from '../../../../../services/curator-services/curator-mentor-service'
import { CuratorModulesService } from '../../../../../services/curator-services/curator-module-service'
import { DirectionDto } from '../../../../../model/direction-model'
import { MentorGetDto } from '../../../../../model/mentor-model'
import { StudentReviewDto } from '../../../../../model/student-dto/student-review-dto'
import { CourseDto } from '../../../../../model/courses-dto/course-dto'
import { ReviewPlanningMentorDto } from '../../../../../model/review/review-planning-mentor-dto'
import { ReviewForWeekDto } from '../../../../../model/review/review-for-week-dto'
import { REVIEW } from '../../../../../constants/review'
import NumberUtil from '../../../../../utils/NumberUtil'

const CuratorPlanningReviews = () => {
  const getMonday = (date: Date) => {
    const day = date.getDay()
    const diff = date.getDate() - day + (day === 0 ? -6 : 1)
    return new Date(date.setDate(diff))
  }

  const [reviewForWeek, setReviewForWeek] = useState<ReviewForWeekDto>({})
  const [selectedDate, setSelectedDate] = useState<Date>(getMonday(new Date()))
  const [selectedReviewTime, setSelectedReviewTime] = useState<string>(``)
  const [selectedReviewDate, setSelectedReviewDate] = useState<Date>(new Date())
  const [selectedCourseId, setSelectedCourseId] = useState<number>(0)
  const [selectedModuleId, setSelectedModuleId] = useState<number>(0)
  const [selectedMentorId, setSelectedMentorId] = useState<number>(0)
  const [selectedReviewId, setSelectedReviewId] = useState<number>(0)
  const [students, setStudentsDto] = useState<StudentReviewDto[]>([])
  const [selectedZoomAccount, setSelectedZoomAccount] = useState<string>('')
  const [selectedAvailableSlots, setSelectedAvailableSlots] = useState(REVIEW.DEFAULT_GROUP_MEMBERS_COUNT)
  const [studentsRegistered, setStudentsRegistered] = useState<boolean>(false)
  const [modules, setModules] = useState<ModuleDto[]>([])
  const [mentors, setMentors] = useState<MentorGetDto[]>([])
  const [planningMentors, setPlanningMentors] = useState<ReviewPlanningMentorDto[]>([])
  const [showTimeInput, setShowTimeInput] = useState<boolean>(false)
  const [showConfirmRemoveReviewModal, setShowConfirmRemoveReviewModal] = useState<boolean>(false)
  const [directions, setDirections] = useState<DirectionDto[]>([])
  const [selectedDirection, setSelectedDirection] = useState<number>(0)
  const [selectedCourses, setSelectedCourses] = useState<CourseDto[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(true)
  const [zoomLink, setZoomLink] = useState<string>('')
  const { catchErrorAlert } = useReactAlert()
  const [selectedReviewType, setSelectedReviewType] = useState<string>()
  const [selectedReviewWeekCourse, setSelectedReviewWeekCourse] = useState<CourseDto>()
  const [reviewWeekModules, setReviewWeekModules] = useState<ModuleDto[]>()
  const [selectedReviewWeekModules, setSelectedReviewWeekModules] = useState<ModuleDto>()
  const [selectedReviewWeekMentors, setSelectedReviewWeekMentors] = useState<MentorGetDto>()

  const onChangeReviewsByDate = () => {
    const date = formatDateToDDMMYYYY(selectedDate)
    CuratorReviewService.getReviewForWeek({
      filterDate: date,
      directionId: selectedDirection,
      reviewType: selectedReviewType as ReviewType,
      courseId: selectedReviewWeekModules?.id ? undefined : selectedReviewWeekCourse?.id,
      moduleId: selectedReviewWeekModules?.id,
      mentorId: selectedReviewWeekMentors?.id,
    }).then(response => {
      setReviewForWeek(response)
    })
    CuratorReviewService.getMentorsForWeek({
      filterDate: date,
      directionId: selectedDirection,
      reviewType: selectedReviewType as ReviewType,
      courseId: selectedReviewWeekModules?.id ? undefined : selectedReviewWeekCourse?.id,
      moduleId: selectedReviewWeekModules?.id,
      mentorId: selectedReviewWeekMentors?.id,
    }).then(response => {
      setPlanningMentors(response)
    })
  }

  useEffect(() => {
    if (selectedDirection !== 0) {
      onChangeReviewsByDate()
    }
  }, [selectedDirection])

  useEffect(() => {
    if (!selectedReviewWeekCourse?.id) return

    const fetchCourseModules = async () => {
      try {
        const courseModules = await CuratorModulesService.getAllModulesByCourseId(selectedReviewWeekCourse.id)
        setReviewWeekModules(courseModules)
      } catch (error) {
        catchErrorAlert(error)
      }
    }

    fetchCourseModules()
  }, [selectedReviewWeekCourse])

  const getReviewsDate = (): string[] => {
    const weekDate = new Set<string>()
    const reviewKeys = Object.keys(reviewForWeek)

    if (reviewKeys.length > 0) {
      reviewKeys.forEach(timeKey => {
        Object.keys(reviewForWeek[timeKey] || {}).forEach(date => {
          weekDate.add(date)
          return undefined
        })
        return undefined
      })
    }
    return [...weekDate]
  }

  const setDefaultFilters = () => {
    setSelectedReviewType(undefined)
    setSelectedReviewWeekModules(undefined)
    setSelectedReviewWeekCourse(undefined)
    setSelectedReviewWeekMentors(undefined)
  }

  const onChangeDirection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDirection(parseInt(e.target.value, 10))
    setDefaultFilters()
  }

  const [rangeDate, setRangeDate] = useState<string[]>([])
  const [showAddReviewModal, setShowAddReviewModal] = useState<boolean>(false)
  const [showEditReviewModal, setShowEditReviewModal] = useState<boolean>(false)

  useEffect(() => {
    const fetchDirections = async () => {
      try {
        setRangeDate(getReviewsDate())
        const allDirections = await CuratorDirectionsService.getAll()

        if (allDirections && allDirections[0]) {
          setSelectedDirection(allDirections[0].id)
          setSelectedCourses(allDirections[0].courses || [])
        }
        setDirections(allDirections)
      } catch (error) {
        catchErrorAlert(error)
      }
    }
    fetchDirections()
  }, [])

  useEffect(() => {
    if (!selectedCourseId) return

    const fetchModules = async () => {
      try {
        const courseModules = await CuratorModulesService.getAllModulesByCourseId(selectedCourseId)
        setModules(courseModules)
      } catch (error) {
        catchErrorAlert(error)
      }
    }
    fetchModules()
  }, [selectedCourseId])

  useEffect(() => {
    if (directions.length !== 0) {
      const directionIndex = directions.findIndex(el => el.id === selectedDirection)
      // @ts-ignore
      const { courses } = directions[directionIndex]
      setSelectedCourses(courses)
    }
  }, [selectedDirection])

  useEffect(() => {
    if (selectedDirection !== 0) {
      CuratorMentorService.getMentors(selectedDirection).then(setMentors)
    }
  }, [selectedDirection])

  useEffect(() => {
    setRangeDate(getReviewsDate())
    setIsLoaded(false)
  }, [reviewForWeek])

  useEffect(() => {
    if (selectedReviewId !== 0) {
      CuratorReviewService.getChiefReviewDtoByReviewId(selectedReviewId).then(response => {
        const {
          mentorId,
          courseId,
          moduleId,
          zoomAcc,
          availableSlots: currentAvailableSlots,
          studentReviewForMentorDtos,
          zoomLink: responseZoomLink,
        } = response
        setSelectedMentorId(mentorId)
        setSelectedCourseId(courseId)
        setSelectedModuleId(moduleId)
        setSelectedZoomAccount(zoomAcc)
        setSelectedAvailableSlots(currentAvailableSlots)
        setStudentsDto(studentReviewForMentorDtos)
        setShowEditReviewModal(true)
        setZoomLink(responseZoomLink)
      })
    }
  }, [selectedReviewId])

  const onChangeCourseReviewFilterSelector = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const courseId = +e.target.value
    if (!courseId) {
      setSelectedReviewWeekCourse(undefined)
      setSelectedReviewWeekModules(undefined)
      return
    }
    setSelectedReviewWeekCourse(selectedCourses.find(course => course.id === courseId))
  }

  const onChangeModuleReviewFilterSelector = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const moduleId = +e.target.value
    if (!moduleId) {
      setSelectedReviewWeekModules(undefined)
      return
    }
    setSelectedReviewWeekModules(reviewWeekModules?.find(module => module.id === moduleId))
  }

  const onChangeMentorReviewFilterSelector = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const mentorId = +e.target.value
    if (!mentorId) {
      setSelectedReviewWeekMentors(undefined)
      return
    }
    setSelectedReviewWeekMentors(mentors?.find(mentor => mentor.id === mentorId))
  }

  const onChangeCourseSelector = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const courseId = +e.target.value

    if (!courseId) {
      setModules([])
      return
    }

    setSelectedCourseId(courseId)
    CuratorModulesService.getAllModulesByCourseId(courseId)
      .then(courseModules => {
        if (!courseModules[0]) return courseModules
        setSelectedModuleId(courseModules[0].id)
        return courseModules
      })
      .then(setModules)
      .catch(catchErrorAlert)
  }

  const onChangeModuleSelector = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedModuleId(+e.target.value)
  }

  const onChangeMentorSelector = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMentorId(+e.target.value)
  }

  const onChangeZoomAccount = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedZoomAccount(e.target.value)
  }

  const onAvailableSlotsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    if (!value || NumberUtil.isNonNegativeNumber(value)) {
      setSelectedAvailableSlots(Number(value))
    }
  }

  const setDefaultReviewData = () => {
    setSelectedMentorId(0)
    setSelectedCourseId(0)
    setSelectedModuleId(0)
    setSelectedReviewId(0)
    setSelectedAvailableSlots(REVIEW.DEFAULT_GROUP_MEMBERS_COUNT)
    setSelectedZoomAccount('')
    setStudentsRegistered(false)
  }

  const addAdditionalTime = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const target = e.target as HTMLInputElement
      setShowTimeInput(false)
      const time = target.value
      const dateObj: { [key: string]: [] } = {}

      getReviewsDate().forEach(reviewDate => {
        dateObj[reviewDate] = []
      })

      const timeObj = {
        [time]: dateObj,
      }

      const newReviewForWeek = { ...reviewForWeek, ...timeObj }
      setReviewForWeek(newReviewForWeek)
    }
  }

  if (isLoaded) {
    return <Spinner />
  }

  const reviewDate = new Date(selectedReviewDate).toLocaleString('ru', {
    weekday: `long`,
    year: `numeric`,
    month: `numeric`,
    day: `numeric`,
  })

  return (
    <div className="chief-page">
      <header className="chief-header">
        <h1 id="planning-review-header">Групповые ревью</h1>
      </header>
      <section className="chief-section">
        <AdminPlanningReviewsActionPanel
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          onChangeReviewsByDate={onChangeReviewsByDate}
          directions={directions}
          selectedDirection={selectedDirection}
          onChangeDirection={onChangeDirection}
          selectedReviewType={selectedReviewType}
          onSelectReviewType={setSelectedReviewType}
          courses={selectedCourses}
          selectedCourse={selectedReviewWeekCourse}
          onChangeCourse={onChangeCourseReviewFilterSelector}
          modules={reviewWeekModules}
          selectedModule={selectedReviewWeekModules}
          onChangeModule={onChangeModuleReviewFilterSelector}
          mentors={mentors}
          selectedMentor={selectedReviewWeekMentors}
          onChangeMentor={onChangeMentorReviewFilterSelector}
        />
        <ChiefPlanningReviewsTable
          selectedDates={rangeDate}
          setShowAddReviewModal={setShowAddReviewModal}
          setSelectedTime={setSelectedReviewTime}
          setSelectedReviewDate={setSelectedReviewDate}
          reviewForWeek={reviewForWeek}
          setSelectedReviewId={setSelectedReviewId}
          setDefaultReviewData={setDefaultReviewData}
          showTimeInput={showTimeInput}
          setShowTimeInput={setShowTimeInput}
          addAdditionalTime={addAdditionalTime}
          setStudentsRegistered={setStudentsRegistered}
          setShowEditReviewModal={setShowEditReviewModal}
        />
        <ChiefPlanningReviewsMentorList planningMentors={planningMentors} />
      </section>
      <footer className="chief-footer" />
      <PlanningReviewCreateModal
        courses={selectedCourses}
        modules={modules}
        mentors={mentors}
        selectedDate={reviewDate}
        selectedTime={selectedReviewTime}
        onChangeCourseSelector={onChangeCourseSelector}
        onChangeModuleSelector={onChangeModuleSelector}
        onChangeMentorSelector={onChangeMentorSelector}
        onChangeZoomAccount={onChangeZoomAccount}
        createReview={() => {}} // не используется куратором
        setDefaultReviewData={setDefaultReviewData}
        modalShowed={showAddReviewModal}
        onClose={() => setShowAddReviewModal(false)}
        availableSlots={selectedAvailableSlots}
        onAvailableSlotsChange={onAvailableSlotsChange}
        balancing={null} // не используется куратором
        setBalancing={null} // не используется куратором
      />
      <PlanningReviewEditModal //модалка редактирования ревью
        courses={selectedCourses}
        modules={modules}
        mentors={mentors}
        students={students}
        selectedDate={reviewDate}
        selectedTime={selectedReviewTime}
        selectedCourseId={selectedCourseId}
        selectedModuleId={selectedModuleId}
        selectedMentorId={selectedMentorId}
        selectedZoomAccount={selectedZoomAccount}
        onChangeCourseSelector={onChangeCourseSelector}
        onChangeModuleSelector={onChangeModuleSelector}
        onChangeMentorSelector={onChangeMentorSelector}
        onChangeZoomAccount={onChangeZoomAccount}
        modalShowed={showEditReviewModal}
        updateReview={() => {}} // не используется куратором
        studentsRegistered={studentsRegistered}
        setDefaultReviewData={setDefaultReviewData}
        onClose={() => setShowEditReviewModal(false)}
        showConfirmRemoveReviewModal={() => setShowConfirmRemoveReviewModal(true)}
        zoomLink={zoomLink}
        availableSlots={selectedAvailableSlots}
        onAvailableSlotsChange={onAvailableSlotsChange}
        balancing={null} // не используется куратором
        setBalancing={null} // не используется куратором
      />
      <PlanningReviewConfirmRemoveReviewModal
        modalShowed={showConfirmRemoveReviewModal}
        onCloseConfirmModal={() => setShowConfirmRemoveReviewModal(false)}
        removeReview={() => {}} // не используется куратором
        openEditModal={() => setShowEditReviewModal(true)}
        selectedDate={reviewDate}
        selectedTime={selectedReviewTime}
        setDefaultReviewData={setDefaultReviewData}
      />
    </div>
  )
}

export default CuratorPlanningReviews
