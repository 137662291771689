import React, { FC } from 'react'
import classNames from 'classnames/bind'

import { Item } from './item'
import { useReports } from '../hooks'

import { ReportPagesApiProps, ReportCalendarListProps } from '../types'

import styles from './report-calendar.module.scss'

const cx = classNames.bind(styles)

export const List: FC<ReportPagesApiProps & ReportCalendarListProps> = ({
  apiRole,
  filterCalendar,
  paginationParams,
  calendarConfig,
  filterValues,
}) => {
  const { reports } = useReports({ apiRole, filterCalendar, paginationParams, calendarConfig, filterValues })

  return (
    <div className={cx('report-grid')}>
      {Object.values(reports).map(reportsRow => {
        return (
          <div className={cx('report-row')}>
            {Object.values(reportsRow).map(report => {
              if (!report) return <div className={cx('report-empty')} />

              return <Item {...report} />
            })}
          </div>
        )
      })}
    </div>
  )
}
