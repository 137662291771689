import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { curatorRecruitmentService } from 'src/services/curator-services/curator-recruitment-service'
import { RecruitmentMeetHistoryDto, RecruitmentMeetEntryStatus } from 'src/model/recruitment-dto/recruitment-dto'
import { useReactAlert } from 'src/hooks/useReactAlert'
import './styles.css'

export const MeetHistoryPage = () => {
  const { catchErrorAlert } = useReactAlert()
  const { recruitmentId } = useParams<{ recruitmentId: string }>()
  const [recruitmentMeetHistory, setRecruitmentMeetHistory] = useState<RecruitmentMeetHistoryDto>()

  useEffect(() => {
    curatorRecruitmentService
      .getMeetHistory(Number(recruitmentId))
      .then(response => {
        setRecruitmentMeetHistory(response)
      })
      .catch(err => catchErrorAlert(err))
  }, [])

  const renderStatus = (status: RecruitmentMeetEntryStatus) => {
    const icons = {
      [RecruitmentMeetEntryStatus.MISSED]: (
        <span className="mdi mdi-close-circle-outline" style={{ color: '#DC3545' }} />
      ),
      [RecruitmentMeetEntryStatus.VISITED]: (
        <span className="mdi mdi-check-circle-outline" style={{ color: '#28A745' }} />
      ),
      [RecruitmentMeetEntryStatus.NOT_SELECTED]: (
        <span className="mdi mdi-help-circle-outline" style={{ color: '#DDDDDD' }} />
      ),
    }

    return icons[status]
  }

  return (
    <div className="meet-history-page">
      <h1>История присутствия на созвонах с потоком: {recruitmentMeetHistory?.channelName}</h1>
      <div className="table-container">
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th className="meet-history-table-cell" />
                {recruitmentMeetHistory?.studentsMeetHistory[0]?.statusHistory.map(({ meetDateTime }) => (
                  <th className="meet-history-table-cell">{meetDateTime}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {recruitmentMeetHistory?.studentsMeetHistory?.map(student => (
                <tr className="student-row">
                  <td className="meet-history-table-cell">
                    <b>{student.payStudentFullName}</b>
                  </td>
                  {student.statusHistory.map(({ status }) => {
                    return <td className="meet-history-table-cell">{renderStatus(status)}</td>
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
