import React, { useEffect, useMemo, useState } from 'react'
import { useReactAlert } from 'src/hooks/useReactAlert'
import TableHeaderSorting from 'src/components/shared/table-header-sorting'
import { TableRow } from '../../../../../table/table'
import { MentorUserCourseService } from '../../../../../../services/mentor-services/mentor-user-course-service'
import Spinner from '../../../../../spinner'
import {
  TaskNotificationDto,
  MentorCheckWaitedTaskSortType,
} from '../../../../../../model/notification-dto/task-notification-dto'
import { studentHost } from '../../../../../../config'

enum TableHeaderCell {
  STUDENT,
  DEADLINE_DATE,
  SOLUTION_DATE,
  TASK_POSITION,
  TASK_LINK,
}

const tableHeaders = [
  {
    id: TableHeaderCell.STUDENT,
    header: 'Студент',
  },
  {
    id: TableHeaderCell.DEADLINE_DATE,
    header: 'Дата дедлайна',
    sortOptions: {
      asc: { action: MentorCheckWaitedTaskSortType.DEADLINE_DATE_ASC, label: `Дата дедлайна ↑` },
      desc: { action: MentorCheckWaitedTaskSortType.DEADLINE_DATE_DESC, label: `Дата дедлайна ↓` },
    },
  },
  {
    id: TableHeaderCell.SOLUTION_DATE,
    header: 'Дата решения задачи',
    sortOptions: {
      asc: { action: MentorCheckWaitedTaskSortType.ANSWER_DATETIME_ASC, label: `Дата решения задачи ↑` },
      desc: { action: MentorCheckWaitedTaskSortType.ANSWER_DATETIME_DESC, label: `Дата решения задачи ↑` },
    },
  },
  {
    id: TableHeaderCell.TASK_POSITION,
    header: 'Расположение задачи',
  },
  {
    id: TableHeaderCell.TASK_LINK,
    header: 'Ссылка на задачу',
  },
]

const dataConfig: Record<string, Function> = {
  studentFullName: (taskNotification: TaskNotificationDto) => taskNotification.studentFullName,
  courseDeadlineDate: (taskNotification: TaskNotificationDto) => taskNotification.courseDeadlineDate,
  answerDate: (taskNotification: TaskNotificationDto) => taskNotification.answerDate,
  courseTaskPosition: (taskNotification: TaskNotificationDto) => {
    return `${taskNotification.courseName} / ${taskNotification.moduleName} / ${taskNotification.chapterName}`
  },
  link: ({ chapterPosition, modulePosition, coursePosition, courseTaskPosition, studentId }: TaskNotificationDto) => (
    <a
      href={`${studentHost}/fake/user/${studentId}/courses/${coursePosition}/${modulePosition}/${chapterPosition}/${courseTaskPosition}`}
      target="_blank nooffer"
    >
      перейти к проверке
    </a>
  ),
}

const TaskNotificationDtosPage = () => {
  const { reactAlert } = useReactAlert()
  const [taskNotifications, setTaskNotificationDtos] = useState<TaskNotificationDto[]>([])
  const [loading, setLoading] = useState<Loading>({} as Loading)
  const [sortType, setSortType] = useState<MentorCheckWaitedTaskSortType | string>('')

  useEffect(() => {
    ;(async () => {
      try {
        setLoading({
          ...loading,
          taskNotifications: true,
        })

        const currentTaskNotificationDtos = await MentorUserCourseService.getTasksNotification(sortType)
        setTaskNotificationDtos(currentTaskNotificationDtos)
      } catch (e) {
        reactAlert.error(e.message)
      } finally {
        setLoading({
          ...loading,
          taskNotifications: false,
        })
      }
    })()
  }, [sortType])

  const isLoading = useMemo(() => {
    return Object.values(loading).some(status => status)
  }, [loading])

  if (isLoading) {
    return (
      <div className="loader">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="content">
      <div className="mentor-content">
        <div className="mentor-header">
          <h2>Все уведомления</h2>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped admin-users-table">
          <thead>
            <tr>
              {tableHeaders.map(header => (
                <th key={`${header.header}`}>
                  <TableHeaderSorting header={header} sortType={sortType} setSortType={setSortType} />
                </th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody>
            {taskNotifications.map(task => {
              return (
                <tr>
                  {Object.entries(dataConfig).map(([_, getValue]) => {
                    return <td>{getValue(task)}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

type Loading = {
  taskNotifications: boolean
}

export default TaskNotificationDtosPage
