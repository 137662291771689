import React, { useContext } from 'react'

import {
  RecruitmentMeetEntryDto,
  RecruitmentMeetEntryStatus,
  RecruitmentMeetEntryStatusInfoDto,
  RecruitmentMeetEntryStatusInfo,
} from 'src/model/recruitment-dto/recruitment-dto'
import '../styles.css'

import cn from 'classnames'
import { curatorRecruitmentService } from 'src/services/curator-services/curator-recruitment-service'
import { useParams } from 'react-router-dom'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { RecruitmentGroupStudentStatus } from 'src/model/student-dto/student-dto'
import { MeetPageContext } from '../meet-page'
import { studentHost } from '../../../../../../config'

type StudentRowProps = {
  student: RecruitmentMeetEntryDto
  action: React.ReactNode
}

export const StudentRow: React.FC<StudentRowProps> = ({ student, action }) => {
  const { recruitmentMeetId } = useParams<{ recruitmentMeetId: string }>()
  const { catchErrorAlert, reactAlert } = useReactAlert()
  const { fetchRecruitmentMeet } = useContext(MeetPageContext)
  const {
    meetEntryId,
    payStudentId,
    payStudentName,
    currentCourseInfo,
    currentEntryStatus,
    lastComment,
    entryStatusesHistory,
    statusInfo,
  } = student

  const getStatus = ({ status, key }: { status?: RecruitmentMeetEntryStatus; key: number }) => {
    switch (status) {
      case RecruitmentMeetEntryStatus.MISSED:
        return <span key={key} className="meet-status meet-status--missed" />
      case RecruitmentMeetEntryStatus.VISITED:
        return <span key={key} className="meet-status meet-status--visited" />
      case RecruitmentMeetEntryStatus.NOT_SELECTED:
        return <span className="meet-status meet-status--not-selected" />
      default:
        return <span key={key} className="meet-status meet-status--not-selected" />
    }
  }

  const renderStatus = (statuses: RecruitmentMeetEntryStatusInfoDto[]) => {
    const arrayStatuses = []

    for (let i = 0; i < 8; i++) {
      arrayStatuses.push(
        getStatus({
          status: statuses[i]?.entryStatus,
          key: i,
        })
      )
    }

    return arrayStatuses
  }

  const statusLabel = {
    [RecruitmentGroupStudentStatus.EXPELLED]: 'Отчислен',
    [RecruitmentGroupStudentStatus.RETURNED]: 'Восстановился',
    [RecruitmentGroupStudentStatus.STUDY]: 'Учится',
    [RecruitmentGroupStudentStatus.TRANSFERRED]: 'Переведен в',
  }

  const updateRecruitmentMeetEntryStatus = (status: RecruitmentMeetEntryStatus) =>
    curatorRecruitmentService
      .updateRecruitmentMeetEntryStatus({
        meetEntryId: String(meetEntryId),
        recruitmentMeetId,
        recruitmentMeetEntryStatus: status,
      })
      .then(() => fetchRecruitmentMeet())
      .then(() => reactAlert.success('Статус успешно обновлен'))
      .catch(error => catchErrorAlert(error))

  function renderStatusCell(status: RecruitmentMeetEntryStatusInfo) {
    if (
      status.recruitmentGroupStudentStatus === RecruitmentGroupStudentStatus.TRANSFERRED &&
      status?.transferredTo &&
      status?.transferredFrom
    ) {
      return `Переведен из ${status.transferredFrom} \n в ${status.transferredTo}`
    }

    if (status.recruitmentGroupStudentStatus === RecruitmentGroupStudentStatus.TRANSFERRED && status.transferredFrom) {
      return `Переведен из ${status?.transferredFrom}`
    }
    if (status.recruitmentGroupStudentStatus === RecruitmentGroupStudentStatus.TRANSFERRED && status.transferredTo) {
      return `Переведен в ${status.transferredTo}`
    }

    return statusLabel[statusInfo.recruitmentGroupStudentStatus]
  }

  return (
    <tr key={payStudentId}>
      <td className="meet-page-table__td">{payStudentId}</td>
      <td className="meet-page-table__td">
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-link"
          href={`${studentHost}/fake/user/${payStudentId}/statistic`}
        >
          {payStudentName}
        </a>
      </td>
      <td className="meet-page-table__td">{currentCourseInfo?.courseName ?? '-'}</td>
      <td className="meet-page-table__td">{currentCourseInfo?.moduleName ?? '-'}</td>
      <td className="meet-page-table__td">{currentCourseInfo ? `${currentCourseInfo!.courseProgress}%` : '-'}</td>
      <td className="meet-page-table__td">{currentCourseInfo?.lastAnswerDateTime ?? '-'}</td>
      <td className="meet-page-table__td">{currentCourseInfo?.deadlineDateTime ?? '-'}</td>
      <td className="meet-page-table__td">{renderStatusCell(statusInfo)}</td>
      <td className="meet-page-table__td">{lastComment}</td>
      <td className="meet-page-table__td meet-page-table__status">{renderStatus(entryStatusesHistory)}</td>
      <td className="meet-page-table__td ">
        <button
          onClick={() => {
            updateRecruitmentMeetEntryStatus(RecruitmentMeetEntryStatus.VISITED)
          }}
          type="button"
          className={cn([
            'meet-page-table__status-btn',
            'meet-page-table__status-btn--visit',
            {
              'meet-page-table__status-btn--active':
                currentEntryStatus.entryStatus === RecruitmentMeetEntryStatus.VISITED,
            },
          ])}
        >
          <span className="mdi mdi-checkbox-marked-circle-outline" />
        </button>
        <button
          onClick={() => {
            updateRecruitmentMeetEntryStatus(RecruitmentMeetEntryStatus.MISSED)
          }}
          type="button"
          className={cn([
            'meet-page-table__status-btn',
            'meet-page-table__status-btn--miss',
            {
              'meet-page-table__status-btn--active':
                currentEntryStatus.entryStatus === RecruitmentMeetEntryStatus.MISSED,
            },
          ])}
        >
          <span className="mdi mdi-close-circle-outline" />
        </button>
      </td>
      <td className="meet-page-table__td">{action}</td>
    </tr>
  )
}
