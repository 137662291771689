import React, { useEffect, useState } from 'react'
import { formatDateToDDMMYYYY } from 'src/utils/dateUtils'
import { useReactAlert } from 'src/hooks/useReactAlert'
import useSetDocumentTitle from 'src/hooks/useSetDocumentTitle'
import moment from 'moment'
import ChiefPlanningReviewsActionPanel from './chief-planning-reviews-action-panel'
import ChiefPlanningReviewsTable from './chief-planning-reviews-table'

import PlanningReviewCreateModal from './chief-planning-reviews-table/planning-review-create-modal'
import PlanningReviewEditModal from './chief-planning-reviews-table/planning-review-edit-modal/planning-review-edit-modal'
import ChiefReviewService from '../../../../../services/chief-services/chief-review-service'
import ChiefCourseService from '../../../../../services/chief-services/chief-course-service'
import ChiefMentorService from '../../../../../services/chief-services/chief-mentor-service'
import Spinner from '../../../../spinner'
import PlanningReviewConfirmRemoveReviewModal from './chief-planning-reviews-table/planning-review-confirm-remove-review-modal'
import ChiefPlanningReviewsMentorList from './chief-planning-reviews-mentor-list'
import ChiefCopyReviewsModal from './chief-planning-reviews-action-panel/chief-copy-reviews-modal/chief-copy-reviews-modal'
import ChiefBalancingWindow from '../chief-balancing'
import { REVIEW } from '../../../../../constants/review'
import NumberUtil from '../../../../../utils/NumberUtil'

import './chief-planning-reviews.css'

const chiefReviewService = new ChiefReviewService()
const chiefCourseService = new ChiefCourseService()

const ChiefPlanningReviews = () => {
  useSetDocumentTitle('Шеф-ментор | Планирование ревью')

  const { catchErrorAlert, reactAlert } = useReactAlert()

  const getMonday = date => {
    const day = date.getDay()
    const diff = date.getDate() - day + (day === 0 ? -6 : 1)
    return new Date(date.setDate(diff))
  }

  const [reviewForWeek, setReviewForWeek] = useState({})
  const [selectedDate, setSelectedDate] = useState(getMonday(new Date()))
  const [selectedReviewTime, setSelectedReviewTime] = useState(``)
  const [selectedReviewDate, setSelectedReviewDate] = useState(new Date())
  const [selectedCourseId, setSelectedCourseId] = useState(0)
  const [selectedModuleId, setSelectedModuleId] = useState(0)
  const [selectedMentorId, setSelectedMentorId] = useState(0)
  const [selectedReviewId, setSelectedReviewId] = useState(0)
  const [students, setStudentsDto] = useState([])
  const [selectedZoomAccount, setSelectedZoomAccount] = useState('')
  const [selectedAvailableSlots, setSelectedAvailableSlots] = useState(REVIEW.DEFAULT_GROUP_MEMBERS_COUNT)
  const [studentsRegistered, setStudentsRegistered] = useState(false)
  const [courses, setCourses] = useState([])
  const [modules, setModules] = useState([])
  const [mentors, setMentors] = useState([])
  const [planningMentors, setPlanningMentors] = useState([])
  const [showTimeInput, setShowTimeInput] = useState(false)
  const [showConfirmRemoveReviewModal, setShowConfirmRemoveReviewModal] = useState(false)
  const [showCopyReviewsModal, setShowCopyReviewsModal] = useState(false)
  const [copyReviewTargetDate, setCopyReviewTargetDate] = useState(
    getMonday(
      moment(new Date())
        .add(7, 'days')
        .toDate()
    )
  )
  const [isLoaded, setIsLoaded] = useState(true)
  const [zoomLink, setZoomLink] = useState('')
  const [selectedReviewType, setSelectedReviewType] = useState()
  const [selectedReviewWeekCourse, setSelectedReviewWeekCourse] = useState()
  const [reviewWeekModules, setReviewWeekModules] = useState()
  const [selectedReviewWeekModules, setSelectedReviewWeekModules] = useState()
  const [selectedReviewWeekMentors, setSelectedReviewWeekMentors] = useState()
  const [isBalancing, setBalancing] = useState(true)

  const onCopyReview = () => {
    chiefReviewService
      .copyReviews(selectedDate, copyReviewTargetDate)
      .then(() => {
        reactAlert.success(`Ревью успешно скопированы`)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
      .finally(() => {
        setShowCopyReviewsModal(false)
      })
  }

  const onChangeSelectedDate = inputValue => {
    if (inputValue) {
      setSelectedDate(new Date(inputValue))
      setCopyReviewTargetDate(
        moment(new Date(inputValue))
          .add(7, 'days')
          .toDate()
      )
    }
  }

  const onChangeReviewsByDate = () => {
    const date = formatDateToDDMMYYYY(selectedDate)
    chiefReviewService
      .getReviewForWeek({
        filterDate: date,
        reviewType: selectedReviewType,
        courseId: selectedReviewWeekModules?.id ? undefined : selectedReviewWeekCourse?.id,
        moduleId: selectedReviewWeekModules?.id,
        mentorId: selectedReviewWeekMentors?.id,
      })
      .then(response => {
        setReviewForWeek(response)
      })
    chiefReviewService
      .getMentorsStatisticForWeek({
        filterDate: date,
        reviewType: selectedReviewType,
        courseId: selectedReviewWeekModules?.id ? undefined : selectedReviewWeekCourse?.id,
        moduleId: selectedReviewWeekModules?.id,
        mentorId: selectedReviewWeekMentors?.id,
      })
      .then(setPlanningMentors)
  }

  const getReviewsDate = () => {
    const weekDate = new Set()

    Object.keys(reviewForWeek).forEach(key => {
      Object.keys(reviewForWeek[key]).forEach(date => {
        weekDate.add(date)
      })
    })
    return [...weekDate]
  }

  const [rangeDate, setRangeDate] = useState([])
  const [showAddReviewModal, setShowAddReviewModal] = useState(false)
  const [showEditReviewModal, setShowEditReviewModal] = useState(false)

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        setRangeDate(getReviewsDate())
        const chiefCourses = await chiefCourseService.getChiefCourses()
        setCourses(chiefCourses)
      } catch (error) {
        catchErrorAlert(error)
      }
    }

    fetchCourses()
  }, [])

  useEffect(() => {
    if (!selectedReviewWeekCourse?.id) return

    const fetchCourseModules = async () => {
      try {
        const courseModules = await chiefCourseService.getAllModulesByCourseId(selectedReviewWeekCourse.id)
        setReviewWeekModules(courseModules)
      } catch (error) {
        catchErrorAlert(error)
      }
    }

    fetchCourseModules()
  }, [selectedReviewWeekCourse])

  useEffect(() => {
    if (!selectedCourseId) return

    const fetchModules = async () => {
      try {
        const courseModules = await chiefCourseService.getAllModulesByCourseId(selectedCourseId)
        setModules(courseModules)
      } catch (error) {
        catchErrorAlert(error)
      }
    }
    fetchModules()
  }, [selectedCourseId])

  useEffect(() => {
    onChangeReviewsByDate()
  }, [selectedDate])

  useEffect(() => {
    ChiefMentorService.getMentors().then(setMentors)
  }, [])

  useEffect(() => {
    setRangeDate(getReviewsDate())
    setIsLoaded(false)
  }, [reviewForWeek])

  useEffect(() => {
    if (selectedReviewId !== 0) {
      chiefReviewService.getChiefReviewDtoByReviewId(selectedReviewId).then(response => {
        const {
          mentorId,
          courseId,
          moduleId,
          zoomAcc,
          availableSlots: currentAvailableSlots,
          studentReviewForMentorDtos,
          zoomLink: responseZoomLink,
          balancing,
        } = response
        setSelectedMentorId(mentorId)
        setSelectedCourseId(courseId)
        setSelectedModuleId(moduleId)
        setSelectedZoomAccount(zoomAcc)
        setSelectedAvailableSlots(currentAvailableSlots)
        setStudentsDto(studentReviewForMentorDtos)
        setShowEditReviewModal(true)
        setZoomLink(responseZoomLink)
        setBalancing(balancing)
      })
    }
  }, [selectedReviewId])

  const onChangeCourseReviewFilterSelector = e => {
    const courseId = +e.target.value
    if (!courseId) {
      setSelectedReviewWeekCourse(undefined)
      setSelectedReviewWeekModules(undefined)
      return
    }
    setSelectedReviewWeekCourse(courses.find(course => course.id === courseId))
  }

  const onChangeModuleReviewFilterSelector = e => {
    const moduleId = +e.target.value
    if (!moduleId) {
      setSelectedReviewWeekModules(undefined)
      return
    }
    setSelectedReviewWeekModules(reviewWeekModules?.find(module => module.id === moduleId))
  }

  const onChangeMentorReviewFilterSelector = e => {
    const mentorId = +e.target.value
    if (!mentorId) {
      setSelectedReviewWeekMentors(undefined)
      return
    }
    setSelectedReviewWeekMentors(mentors?.find(mentor => mentor.id === mentorId))
  }

  const onChangeCourseSelector = e => {
    const courseId = +e.target.value

    if (!courseId) {
      setModules([])
      return
    }

    setSelectedCourseId(courseId)
    chiefCourseService
      .getAllModulesByCourseId(courseId)
      .then(courseModules => {
        if (!courseModules[0]) return courseModules
        setSelectedModuleId(courseModules[0].id)
        return courseModules
      })
      .then(setModules)
      .catch(catchErrorAlert)
  }

  const onChangeModuleSelector = e => {
    setSelectedModuleId(e.target.value)
  }

  const onChangeMentorSelector = e => {
    setSelectedMentorId(e.target.value)
  }

  const onChangeZoomAccount = e => {
    setSelectedZoomAccount(e.target.value)
  }

  const onAvailableSlotsChange = e => {
    const { value } = e.target

    if (!value || NumberUtil.isNonNegativeNumber(value)) {
      setSelectedAvailableSlots(Number(value))
    }
  }

  const setDefaultReviewData = () => {
    setSelectedMentorId(0)
    setSelectedCourseId(0)
    setSelectedModuleId(0)
    setSelectedReviewId(0)
    setSelectedZoomAccount('')
    setSelectedAvailableSlots(REVIEW.DEFAULT_GROUP_MEMBERS_COUNT)
    setStudentsRegistered(false)
    setZoomLink('')
  }

  const createReview = () => {
    if (selectedModuleId * selectedCourseId === 0) {
      reactAlert.error(`Невозможно создать ревью. Курс/Модуль не выбран`)
      return
    }

    const date = formatDateToDDMMYYYY(selectedReviewDate)

    const dto = {
      reviewTime: `${date} ${selectedReviewTime}`,
      mentorId: selectedMentorId,
      courseId: selectedCourseId,
      moduleId: selectedModuleId,
      zoomAcc: selectedZoomAccount,
      availableSlots: selectedAvailableSlots,
      balancing: isBalancing,
    }

    chiefReviewService
      .createReview(dto)
      .then(() => {
        onChangeReviewsByDate()
        reactAlert.success(`Ревью успешно создано`)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  const updateReview = () => {
    const date = formatDateToDDMMYYYY(selectedReviewDate)

    const dto = {
      reviewId: selectedReviewId,
      reviewTime: `${date} ${selectedReviewTime}`,
      mentorId: selectedMentorId,
      courseId: selectedCourseId,
      moduleId: selectedModuleId,
      zoomAcc: selectedZoomAccount,
      availableSlots: selectedAvailableSlots,
      balancing: isBalancing,
    }

    chiefReviewService
      .updateReviewById(dto, selectedReviewId)
      .then(() => {
        onChangeReviewsByDate()
        reactAlert.success(`Ревью успешно обновлено`)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  const removeReview = () => {
    chiefReviewService
      .deleteReviewById(selectedReviewId)
      .then(() => {
        onChangeReviewsByDate()
        reactAlert.success(`Ревью успешно удалено`)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  const addAdditionalTime = e => {
    if (e.keyCode === 13) {
      setShowTimeInput(false)
      const time = e.target.value
      const dateObj = {}

      getReviewsDate().forEach(reviewDate => {
        dateObj[reviewDate] = []
      })

      const timeObj = {
        [time]: dateObj,
      }

      const newReviewForWeek = { ...reviewForWeek, ...timeObj }
      setReviewForWeek(newReviewForWeek)
    }
  }

  if (isLoaded) {
    return <Spinner />
  }

  const reviewDate = new Date(selectedReviewDate).toLocaleString('ru', {
    weekday: `long`,
    year: `numeric`,
    month: `numeric`,
    day: `numeric`,
  })

  return (
    <>
      <header className="chief-header">
        <h1 id="planning-review-header">Групповые ревью</h1>
      </header>
      <section className="chief-section">
        <ChiefPlanningReviewsActionPanel
          selectedDate={selectedDate}
          setSelectedDate={onChangeSelectedDate}
          onChangeReviewsByDate={() => onChangeReviewsByDate()}
          onOpenCopyReviewModal={() => setShowCopyReviewsModal(true)}
          selectedReviewType={selectedReviewType}
          onSelectReviewType={setSelectedReviewType}
          courses={courses}
          selectedCourse={selectedReviewWeekCourse}
          onChangeCourse={onChangeCourseReviewFilterSelector}
          modules={reviewWeekModules}
          selectedModule={selectedReviewWeekModules}
          onChangeModule={onChangeModuleReviewFilterSelector}
          mentors={mentors}
          selectedMentor={selectedReviewWeekMentors}
          onChangeMentor={onChangeMentorReviewFilterSelector}
        />
        <ChiefPlanningReviewsTable
          selectedDates={rangeDate}
          setShowAddReviewModal={setShowAddReviewModal}
          setShowEditReviewModal={setShowEditReviewModal}
          setSelectedTime={setSelectedReviewTime}
          setSelectedReviewDate={setSelectedReviewDate}
          reviewForWeek={reviewForWeek}
          setSelectedReviewId={setSelectedReviewId}
          setDefaultReviewData={setDefaultReviewData}
          showTimeInput={showTimeInput}
          setShowTimeInput={setShowTimeInput}
          addAdditionalTime={addAdditionalTime}
          setStudentsRegistered={setStudentsRegistered}
        />
        <div className="info-panel">
          <ChiefPlanningReviewsMentorList planningMentors={planningMentors} />
          <ChiefBalancingWindow selectedDate={selectedDate} />
        </div>
      </section>
      <footer className="chief-footer" />
      <PlanningReviewCreateModal
        courses={courses}
        modules={modules}
        mentors={mentors}
        selectedDate={reviewDate}
        selectedTime={selectedReviewTime}
        onChangeCourseSelector={onChangeCourseSelector}
        onChangeModuleSelector={onChangeModuleSelector}
        onChangeMentorSelector={onChangeMentorSelector}
        onChangeZoomAccount={onChangeZoomAccount}
        availableSlots={selectedAvailableSlots}
        onAvailableSlotsChange={onAvailableSlotsChange}
        createReview={createReview}
        setDefaultReviewData={setDefaultReviewData}
        modalShowed={showAddReviewModal}
        onClose={() => setShowAddReviewModal(false)}
        balancing={isBalancing}
        setBalancing={setBalancing}
      />
      <PlanningReviewEditModal
        courses={courses}
        modules={modules}
        mentors={mentors}
        students={students}
        selectedDate={reviewDate}
        selectedTime={selectedReviewTime}
        selectedCourseId={selectedCourseId}
        selectedModuleId={selectedModuleId}
        selectedMentorId={selectedMentorId}
        selectedZoomAccount={selectedZoomAccount}
        onChangeCourseSelector={onChangeCourseSelector}
        onChangeModuleSelector={onChangeModuleSelector}
        onChangeMentorSelector={onChangeMentorSelector}
        onChangeZoomAccount={onChangeZoomAccount}
        availableSlots={selectedAvailableSlots}
        onAvailableSlotsChange={onAvailableSlotsChange}
        modalShowed={showEditReviewModal}
        updateReview={updateReview}
        removeReview={removeReview}
        studentsRegistered={studentsRegistered}
        setDefaultReviewData={setDefaultReviewData}
        onClose={() => setShowEditReviewModal(false)}
        showConfirmRemoveReviewModal={() => setShowConfirmRemoveReviewModal(true)}
        zoomLink={zoomLink}
        balancing={isBalancing}
        setBalancing={setBalancing}
      />
      <PlanningReviewConfirmRemoveReviewModal
        modalShowed={showConfirmRemoveReviewModal}
        onCloseConfirmModal={() => setShowConfirmRemoveReviewModal(false)}
        removeReview={removeReview}
        openEditModal={() => setShowEditReviewModal(true)}
        selectedDate={reviewDate}
        selectedTime={selectedReviewTime}
        setDefaultReviewData={setDefaultReviewData}
      />
      <ChiefCopyReviewsModal
        modalShowed={showCopyReviewsModal}
        selectedDate={selectedDate}
        onRequestClose={() => setShowCopyReviewsModal(false)}
        onConfirm={onCopyReview}
        targetDate={copyReviewTargetDate}
        setTargetDate={setCopyReviewTargetDate}
      />
    </>
  )
}

export default ChiefPlanningReviews
