import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'

import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from 'src/components/shared/modal-view'

import { RecruitmentStudentsInfoDto } from 'src/model/recruitment-dto/recruitment-dto'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { curatorRecruitmentService } from 'src/services/curator-services/curator-recruitment-service'
import { RecruitmentGroupStudentStatus } from '../../../../../../model/student-dto/student-dto'

const statusLabel = {
  [RecruitmentGroupStudentStatus.EXPELLED]: 'Отчислен',
  [RecruitmentGroupStudentStatus.RETURNED]: 'Восстановился',
  [RecruitmentGroupStudentStatus.STUDY]: 'Учится',
  [RecruitmentGroupStudentStatus.TRANSFERRED]: 'Переведен в другую группу',
}

interface StudentsModalProps {
  open: boolean
  recruitmentId: number
  onClose: () => void
}

export const StudentsModal: React.FC<StudentsModalProps> = ({ open, onClose, recruitmentId }) => {
  ReactModal.setAppElement('#root')
  const { catchErrorAlert } = useReactAlert()
  const [recruitmentStudentsInfo, setRecruitmentStudentsInfo] = useState<RecruitmentStudentsInfoDto>()

  useEffect(() => {
    curatorRecruitmentService
      .getStudents(recruitmentId)
      .then(response => setRecruitmentStudentsInfo(response))
      .catch(error => catchErrorAlert(error))
  }, [recruitmentId])

  return (
    <ReactModal isOpen={open} onRequestClose={onClose} style={customStyles} shouldCloseOnEsc>
      <ModalWrapper>
        <ModalHeader>{`Студенты потока ${recruitmentStudentsInfo?.channelName || ''}`}</ModalHeader>
        <ModalContent>
          <table className="table table-striped admin-users-table">
            <thead>
              <tr>
                <th>
                  <b>id</b>
                </th>
                <th>
                  <b>Имя</b>
                </th>
                <th>
                  <b>Фамилия</b>
                </th>
                <th>
                  <b>Email</b>
                </th>
                <th>
                  <b>Группа</b>
                </th>
                <th>
                  <b>статус</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {recruitmentStudentsInfo?.students.map(student => (
                <tr key={student.id}>
                  <td>{student.id}</td>
                  <td>{student.firstName}</td>
                  <td>{student.lastName}</td>
                  <td>{student.email}</td>
                  <td>{student.groupChannelName}</td>
                  <td>{statusLabel[student.status]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </ModalContent>
        <ModalFooter>
          <ModalButton action="back" onClick={onClose}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}
