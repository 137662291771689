import { MeetPlanSubmitData, RecruitmentMeetInfoDto } from '../../../../../../model/curator-dto/meet-plan-dto'

export interface MeetPlanExtendedData extends MeetPlanSubmitData {
  meetTime: string
  fullDate: string | null
  finished: boolean
}

const formatDateToInputView = (data: string): string => {
  const [day, month, year] = data.split('.')
  return `${year}-${month}-${day}`
}

export const recruitmentMeetMapper = (data: RecruitmentMeetInfoDto): MeetPlanExtendedData => {
  const [startDateTime, meetTime] = data.startDateTime.split(' ')
  return {
    id: data.id,
    recruitmentId: data.recruitmentId,
    meetTime: meetTime!,
    startDateTime: formatDateToInputView(startDateTime!),
    fullDate: data.startDateTime,
    finished: data.finished,
  }
}
