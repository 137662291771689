import ApiService from './api-service'
import { HTTPMethods } from './http-enums-methods'
import { RoleEnum } from '../utils/select-state/RoleEnum'
import { UrlSwitchService } from './url-switch-service'
import { PageDto } from '../model/page-dto'
import { ProjectQueueDto } from '../model/project-queue-dto/project-queue-dto'

const Url = {
  PROJECT_STUDENTS_LIST: (pageNumber: number) => `${pageNumber}`,
  PROJECT_QUEUE_LENGTH: 'count',
  TAKE_STUDENT_TO_PROJECT: (studentId: number) => `${studentId}`,
  RETURN_STUDENT_TO_EDUCATION: (studentId: number) => `${studentId}/education/refresh`,
  BOOK_STUDENT: (studentId: number) => `booking/${studentId}`,
  SET_PROJECT_CHANNEL_NAME_PARAM: (param: string | null) => `/?projectChannelName=${param}`,
}

const BaseUrlData = {
  [RoleEnum.ADMIN]: `/api/admin/user/student/project/queue`,
  [RoleEnum.MENTOR]: `/api/mentor/user/student/project/queue`,
  [RoleEnum.CHIEF_MENTOR]: `/api/chief/user/student/project/queue`,
}

class ProjectQueueService extends UrlSwitchService<typeof BaseUrlData> {
  requestMentorUserController: <T>(url: string, init?: RequestInit) => Promise<T>

  constructor(role: RoleEnum.ADMIN | RoleEnum.MENTOR | RoleEnum.CHIEF_MENTOR) {
    super(BaseUrlData, role)
    this.requestMentorUserController = ApiService.generateControllerRequestHelper(this.sourceUrl)
  }

  public getPage(numberPage: number) {
    return this.requestMentorUserController<PageDto<ProjectQueueDto>>(Url.PROJECT_STUDENTS_LIST(numberPage))
  }

  public getProjectStudentsQueueLength() {
    return this.requestMentorUserController<number>(Url.PROJECT_QUEUE_LENGTH)
  }

  public deleteById(id: number, { queryParams }: { queryParams: string | null }) {
    const params = Url.SET_PROJECT_CHANNEL_NAME_PARAM(queryParams)
    const url = Url.TAKE_STUDENT_TO_PROJECT(id) + params
    const options = {
      method: HTTPMethods.DELETE,
    }
    return this.requestMentorUserController(url, options)
  }

  public returnEducationById(id: number) {
    const options = {
      method: HTTPMethods.POST,
    }
    return this.requestMentorUserController(Url.RETURN_STUDENT_TO_EDUCATION(id), options)
  }

  public bookStudentById(id: number) {
    const options = {
      method: HTTPMethods.POST,
    }
    return this.requestMentorUserController(Url.BOOK_STUDENT(id), options)
  }

  public unBookStudentById(id: number) {
    const options = {
      method: HTTPMethods.DELETE,
    }
    return this.requestMentorUserController(Url.BOOK_STUDENT(id), options)
  }
}

export default ProjectQueueService
