import React from 'react'
import 'rc-pagination/assets/index.css'

import '../styles.css'
import { StudentAnalyticsDto, RecruitmentGroupStudentStatus } from 'src/model/student-dto/student-dto'
import { studentHost } from '../../../../../../config'

type StudentsRowProps = {
  student: StudentAnalyticsDto
  action: React.ReactNode
  onOpenStudentExpulsionDetails: () => void
}

export const StudentsRow: React.FC<StudentsRowProps> = ({ student, action, onOpenStudentExpulsionDetails }) => {
  const {
    id,
    fullName,
    registrationDate,
    currentCourseInfo,
    lastVisitedRecruitmentMeetEntryDateTime,
    statusInfo,
    groupChannelName,
  } = student

  const statusLabel = {
    [RecruitmentGroupStudentStatus.EXPELLED]: 'Отчислен',
    [RecruitmentGroupStudentStatus.RETURNED]: 'Восстановился',
    [RecruitmentGroupStudentStatus.STUDY]: 'Учится',
    [RecruitmentGroupStudentStatus.TRANSFERRED]: 'Переведен в другую группу',
  }

  const renderStatus = () => {
    switch (student.statusInfo.recruitmentGroupStudentStatus) {
      case RecruitmentGroupStudentStatus.EXPELLED:
        return (
          <button className="button-like-a" type="button" onClick={onOpenStudentExpulsionDetails}>
            {statusLabel[statusInfo.recruitmentGroupStudentStatus]}
          </button>
        )
      default:
        return statusLabel[statusInfo.recruitmentGroupStudentStatus] || ''
    }
  }

  return (
    <tr key={id} className="recruitment-row">
      <td>{id}</td>
      <td>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-link"
          href={`${studentHost}/fake/user/${id}/statistic`}
        >
          {fullName}
        </a>
      </td>
      <td>{groupChannelName}</td>
      <td>{registrationDate}</td>
      <td>{currentCourseInfo?.courseName || '-'}</td>
      <td>{currentCourseInfo?.moduleName || '-'}</td>
      <td>{currentCourseInfo?.addingDate || ''}</td>
      <td>{currentCourseInfo?.courseProgress || '-'}</td>
      <td>{currentCourseInfo?.lastAnswerDateTime || '-'}</td>
      <td>{currentCourseInfo?.deadlineDateTime || '-'}</td>
      <td>{lastVisitedRecruitmentMeetEntryDateTime || '-'}</td>
      <td>{renderStatus()}</td>
      <td>{action}</td>
    </tr>
  )
}
