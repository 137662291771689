import { BehaviorSubject } from 'rxjs'
import { history, serverHost } from '../config'
import ApiService from './api-service'
import { UserDto } from '../model/user-dto/user-dto'
import { CookieUtil } from '../utils/CookieUtil'
import { LocationUtil } from '../utils/LocationUtil'

const getToken = () => {
  return CookieUtil.get('token') ?? null
}

const currentTokenSubject = new BehaviorSubject<string | null>(getToken())

const setToken = (token: string | null) => {
  CookieUtil.set('token', token, { domain: LocationUtil.getSecondLevelDomain(), path: '/', sameSite: 'Strict' })
  currentTokenSubject.next(token)
}

const getUser = () => {
  return CookieUtil.get('user') ?? null
}

const setUser = (user: UserDto | '') => {
  CookieUtil.set('user', JSON.stringify(user), {
    domain: LocationUtil.getSecondLevelDomain(),
    path: '/',
    sameSite: 'Strict',
  })
}

function authHeaders() {
  const currentToken = currentTokenSubject.value
  if (currentToken) {
    return {
      Authorization: `Bearer ${currentToken}`,
      zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
  }
  return undefined
}

function logout() {
  const currentUser = getUser()
  const token = getToken()
  if (currentUser && token) {
    setUser('')
    setToken('')
    history.push('/login?param=logout')
  }
}

function fetchUserProfile() {
  return ApiService.request<UserDto>(`${serverHost}/api/user/profile`, {
    headers: authHeaders(),
  }).then(user => {
    setUser(user)
    return user
  })
}

function login(authUser: LoginUser) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(authUser),
  }
  return ApiService.request<AuthToken>(`${serverHost}/token`, requestOptions)
    .then(({ token }) => {
      setToken(token)
    })
    .then(fetchUserProfile)
}

function validate(authUser: LoginUser) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(authUser),
  }

  return ApiService.request<boolean>(`${serverHost}/token/validate_user`, requestOptions)
}

function currentUserValue() {
  const currentUserJson = getUser()
  return currentUserJson ? (JSON.parse(currentUserJson) as UserDto) : null
}

export const AuthService = {
  login,
  validate,
  logout,
  authHeaders,
  fetchUserProfile,
  currentUserValue,
  getToken,
}

interface LoginUser {
  username: string
  password: string
  rememberMe: boolean
}

interface AuthToken {
  token: string
}
