import React, { useState, useContext } from 'react'

import { useReactAlert } from 'src/hooks/useReactAlert'

import { RecruitmentDto, RecruitmentSortType } from 'src/model/recruitment-dto/recruitment-dto'
import { Dropdown } from 'react-bootstrap'
import { History } from 'history'

import { Icon } from '@iconify/react'
import sortIcon from '@iconify/icons-mdi/sort'
import sortDescending from '@iconify/icons-mdi/sort-descending'
import sortAscending from '@iconify/icons-mdi/sort-ascending'
import { StudentsModal } from './students-modal'
import { LoadingOverlay } from '../../loading-overlay'
import '../styles.css'
import { RecruitmentRow } from './recruitment-row'
import { AssignSelfModal } from './assign-self-modal'

import { RecruitmentContext } from '../recruitment-page'

interface RecruitmentTableProps {
  recruitments: RecruitmentDto[]
  sortType: RecruitmentSortType | undefined
  order: 'ASC' | 'DESC'
  onChangeSort: (sortType: RecruitmentSortType) => void
  history: History
}

export const RecruitmentTable: React.FC<RecruitmentTableProps> = ({
  recruitments,
  sortType,
  order,
  onChangeSort,
  history,
}) => {
  const { catchErrorAlert, reactAlert } = useReactAlert()
  const { curatorRecruitmentService, fetchRecruitment, setSelectedRecruitment, setOpenModal } = useContext(
    RecruitmentContext
  )
  const [openStudentsModal, setOpenStudentsModal] = useState(false)
  const [openAssignSelfModal, setOpenAssignSelfModal] = useState(false)

  const [recruitmentId, setRecruitmentId] = useState<number>()
  const [isLoading, setLoading] = useState(false)

  const handleChangeSort = (sorts: Record<typeof order, RecruitmentSortType>) => () => {
    const sort = order === 'ASC' ? 'DESC' : 'ASC'
    onChangeSort(sorts[sort])
  }

  const renderSortIcon = (sortTypes: RecruitmentSortType[]) => {
    if (sortType && sortTypes.includes(sortType)) {
      return <Icon icon={order === 'ASC' ? sortAscending : sortDescending} className="sortIcon" />
    }

    return <Icon icon={sortIcon} className="sortIcon" />
  }

  return (
    <>
      <div className="pagination-wrapped">
        <table className="table table-striped admin-users-table">
          <thead>
            <tr>
              <th>
                <b>id</b>
              </th>

              <th
                onClick={handleChangeSort({
                  'ASC': RecruitmentSortType.CHANNEL_NAME_ASC,
                  'DESC': RecruitmentSortType.CHANNEL_NAME_DESC,
                })}
                className="sortableHeading"
              >
                <b>
                  Канал потока{' '}
                  {renderSortIcon([RecruitmentSortType.CHANNEL_NAME_ASC, RecruitmentSortType.CHANNEL_NAME_DESC])}
                </b>
              </th>

              <th
                onClick={handleChangeSort({
                  'ASC': RecruitmentSortType.DIRECTION_ASC,
                  'DESC': RecruitmentSortType.DIRECTION_DESC,
                })}
                className="sortableHeading"
              >
                <b>
                  Направление {renderSortIcon([RecruitmentSortType.DIRECTION_ASC, RecruitmentSortType.DIRECTION_DESC])}
                </b>
              </th>

              <th
                onClick={handleChangeSort({
                  'ASC': RecruitmentSortType.START_DATE_ASC,
                  'DESC': RecruitmentSortType.START_DATE_DESC,
                })}
                className="sortableHeading"
              >
                <b>Старт {renderSortIcon([RecruitmentSortType.START_DATE_ASC, RecruitmentSortType.START_DATE_DESC])}</b>
              </th>

              <th
                onClick={handleChangeSort({
                  'ASC': RecruitmentSortType.CURATOR_ASC,
                  'DESC': RecruitmentSortType.CURATOR_DESC,
                })}
                className="sortableHeading"
              >
                <b>Куратор {renderSortIcon([RecruitmentSortType.CURATOR_ASC, RecruitmentSortType.CURATOR_DESC])}</b>
              </th>
              <th>
                <b>Дедлайны</b>
              </th>
              <th>
                <b>Действия</b>
              </th>
            </tr>
          </thead>
          <tbody>
            {recruitments.map(recruitment => (
              <RecruitmentRow
                onRowClick={() => {
                  history.push(`/curator/recruitment-group?recruitmentId=${recruitment.id}`)
                }}
                key={recruitment.id}
                recruitment={recruitment}
                action={
                  <Dropdown onClick={(e: any) => e.stopPropagation()}>
                    <Dropdown.Toggle variant="secondary">Выбрать</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setOpenModal(true)
                          setSelectedRecruitment(recruitment)
                        }}
                      >
                        Редактировать
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setRecruitmentId(recruitment.id)
                          setOpenStudentsModal(true)
                        }}
                      >
                        Просмотр студентов
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => window.open(`/curator/recruitment/${recruitment.id}/meet-history`, '_blank')}
                      >
                        История присутствия
                      </Dropdown.Item>
                      {!recruitment.curatorName && (
                        <Dropdown.Item
                          onClick={() => {
                            setRecruitmentId(recruitment.id)
                            setOpenAssignSelfModal(true)
                          }}
                        >
                          Забрать поток
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                }
              />
            ))}
          </tbody>
        </table>
      </div>

      {recruitmentId && openStudentsModal && (
        <StudentsModal
          open={openStudentsModal}
          onClose={() => {
            setOpenStudentsModal(false)
            setRecruitmentId(undefined)
          }}
          recruitmentId={recruitmentId}
        />
      )}
      <AssignSelfModal
        open={openAssignSelfModal}
        onClose={() => setOpenAssignSelfModal(false)}
        onConfirm={() => {
          if (recruitmentId) {
            setLoading(true)
            curatorRecruitmentService
              .assignSelf(Number(recruitmentId))
              .then(() => {
                setOpenAssignSelfModal(false)
                fetchRecruitment()
              })
              .then(() => reactAlert.success('Теперь вы куратор потока'))
              .catch(error => catchErrorAlert(error))
              .finally(() => setLoading(false))
          }
        }}
      />
      <LoadingOverlay open={isLoading} />
    </>
  )
}
