import React, { useCallback, useEffect, useState } from 'react'
import { RecruitmentDto } from '../../../../../../model/recruitment-dto/recruitment-dto'
import { useReactAlert } from '../../../../../../hooks/useReactAlert'
import { CuratorRecruitmentMeetService } from '../../../../../../services/curator-services/curator-recruitment-meet-service'

const useRecruitmentStudents = (self: boolean) => {
  const [recruitmentStudents, setRecruitmentStudents] = useState<RecruitmentDto[]>([])
  const [selectedRecruitment, setSelectedRecruitment] = useState<RecruitmentDto | null>(null)
  const { catchErrorAlert } = useReactAlert()

  const getRecruitments = useCallback((queryParams: { onlySelf: boolean }) => {
    CuratorRecruitmentMeetService.getRecruitment(queryParams)
      .then(recruitment => {
        setRecruitmentStudents(recruitment)
      })
      .catch((error: Error) => catchErrorAlert(error))
  }, [])

  const handleSelectRecruitment = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const selectedOption = recruitmentStudents.find(recruitment => recruitment.id === +event.target.value)
    if (selectedOption) {
      setSelectedRecruitment(selectedOption)
    } else {
      setSelectedRecruitment(null)
    }
  }

  useEffect(() => {
    getRecruitments({ onlySelf: self })
  }, [self])

  return { selectedRecruitment, recruitmentStudents, handleSelectRecruitment }
}

export default useRecruitmentStudents
