import React, { useState, useContext } from 'react'

import { useReactAlert } from 'src/hooks/useReactAlert'

import { Dropdown } from 'react-bootstrap'

import { Icon } from '@iconify/react'
import sortIcon from '@iconify/icons-mdi/sort'
import sortDescending from '@iconify/icons-mdi/sort-descending'
import sortAscending from '@iconify/icons-mdi/sort-ascending'
import {
  StudentAnalyticsSortType,
  StudentAnalyticsDto,
  RecruitmentGroupStudentStatus,
} from 'src/model/student-dto/student-dto'
import { LoadingOverlay } from '../../loading-overlay'
import '../styles.css'
import { StudentsRow } from './students-row'
import { ReviewHistoryModal } from '../../modals/review-history-modal'
import { MeetHistoryModal } from './meet-history-modal'
import { CompleteDirectionModal } from './complete-direction-modal'
import { StudentAnalyticsContext } from '../student-analytics-page'
import { DeadlineModal } from '../../modals/deadline-modal'
import { EnrollModal } from '../../modals/enroll-modal'
import { StudentExpulsionDetailsModal } from './student-expulsion-details-modal'

interface StudentsTableProps {
  students: StudentAnalyticsDto[]
  sortType: StudentAnalyticsSortType | undefined
  order: 'ASC' | 'DESC'
  onChangeSort: (sortType: StudentAnalyticsSortType) => void
}

export const StudentsTable: React.FC<StudentsTableProps> = ({ students, sortType, order, onChangeSort }) => {
  const { catchErrorAlert, reactAlert } = useReactAlert()

  const { curatorStudentService, fetchStudentAnalytics } = useContext(StudentAnalyticsContext)

  const [openReviewHistory, setOpenReviewHistory] = useState(false)
  const [openMeetHistory, setOpenMeetHistory] = useState(false)
  const [openCompleteDirection, setOpenCompleteDirection] = useState(false)
  const [openDeadline, setOpenDeadline] = useState(false)
  const [openReturn, setOpenReturn] = useState(false)
  const [openTransfer, setOpenTransfer] = useState(false)
  const [openExpelling, setOpenExpelling] = useState(false)
  const [openStudentExpulsionDetails, setOpenStudentExpulsionDetails] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState<StudentAnalyticsDto>()

  const [isLoading, setLoading] = useState(false)

  const handleChangeSort = (sorts: Record<typeof order, StudentAnalyticsSortType>) => () => {
    const sort = order === 'ASC' ? 'DESC' : 'ASC'
    onChangeSort(sorts[sort])
  }

  const renderSortIcon = (sortTypes: StudentAnalyticsSortType[]) => {
    if (sortType && sortTypes.includes(sortType)) {
      return <Icon icon={order === 'ASC' ? sortAscending : sortDescending} className="sortIcon" />
    }

    return <Icon icon={sortIcon} className="sortIcon" />
  }

  return (
    <div>
      <div className="pagination-wrapped">
        <table className="table table-striped admin-users-table">
          <thead>
            <tr>
              <th>
                <b>id</b>
              </th>
              <th
                onClick={handleChangeSort({
                  'ASC': StudentAnalyticsSortType.STUDENT_NAME_ASC,
                  'DESC': StudentAnalyticsSortType.STUDENT_NAME_DESC,
                })}
                className="sortableHeading"
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                <b>
                  Студент
                  {renderSortIcon([
                    StudentAnalyticsSortType.STUDENT_NAME_ASC,
                    StudentAnalyticsSortType.STUDENT_NAME_DESC,
                  ])}
                </b>
              </th>
              <th className="sortableHeading">
                <b>Канал группы</b>
              </th>
              <th
                onClick={handleChangeSort({
                  'ASC': StudentAnalyticsSortType.REGISTRATION_DATE_ASC,
                  'DESC': StudentAnalyticsSortType.REGISTRATION_DATE_DESC,
                })}
                className="sortableHeading nowrapWords"
              >
                <b>
                  Регистрация
                  {renderSortIcon([
                    StudentAnalyticsSortType.REGISTRATION_DATE_ASC,
                    StudentAnalyticsSortType.REGISTRATION_DATE_DESC,
                  ])}
                </b>
              </th>
              <th
                onClick={handleChangeSort({
                  'ASC': StudentAnalyticsSortType.CURRENT_COURSE_NAME_ASC,
                  'DESC': StudentAnalyticsSortType.CURRENT_COURSE_NAME_DESC,
                })}
                className="sortableHeading"
              >
                <b>
                  Текущий{' '}
                  <span className="nowrapWords">
                    курс
                    {renderSortIcon([
                      StudentAnalyticsSortType.CURRENT_COURSE_NAME_ASC,
                      StudentAnalyticsSortType.CURRENT_COURSE_NAME_DESC,
                    ])}
                  </span>
                </b>
              </th>
              <th className="sortableHeading">
                <b>Модуль</b>
              </th>
              <th
                onClick={handleChangeSort({
                  'ASC': StudentAnalyticsSortType.CURRENT_COURSE_ENROLL_DATE_ASC,
                  'DESC': StudentAnalyticsSortType.CURRENT_COURSE_ENROLL_DATE_DESC,
                })}
                className="sortableHeading nowrapWords"
              >
                <b>
                  Поступил
                  {renderSortIcon([
                    StudentAnalyticsSortType.CURRENT_COURSE_ENROLL_DATE_ASC,
                    StudentAnalyticsSortType.CURRENT_COURSE_ENROLL_DATE_DESC,
                  ])}
                </b>
              </th>
              <th
                onClick={handleChangeSort({
                  'ASC': StudentAnalyticsSortType.CURRENT_COURSE_PROGRESS_ASC,
                  'DESC': StudentAnalyticsSortType.CURRENT_COURSE_PROGRESS_DESC,
                })}
                className="sortableHeading nowrapWords"
              >
                <b>
                  Прогресс
                  {renderSortIcon([
                    StudentAnalyticsSortType.CURRENT_COURSE_PROGRESS_ASC,
                    StudentAnalyticsSortType.CURRENT_COURSE_PROGRESS_DESC,
                  ])}
                </b>
              </th>
              <th
                onClick={handleChangeSort({
                  'ASC': StudentAnalyticsSortType.LAST_ANSWER_DATE_ASC,
                  'DESC': StudentAnalyticsSortType.LAST_ANSWER_DATE_DESC,
                })}
                className="sortableHeading"
              >
                <b>
                  Последнее{' '}
                  <span className="nowrapWords">
                    Решение
                    {renderSortIcon([
                      StudentAnalyticsSortType.LAST_ANSWER_DATE_ASC,
                      StudentAnalyticsSortType.LAST_ANSWER_DATE_DESC,
                    ])}
                  </span>
                </b>
              </th>
              <th
                onClick={handleChangeSort({
                  'ASC': StudentAnalyticsSortType.CURRENT_COURSE_DEADLINE_DATE_ASC,
                  'DESC': StudentAnalyticsSortType.CURRENT_COURSE_DEADLINE_DATE_DESC,
                })}
                className="sortableHeading nowrapWords"
              >
                <b>
                  Дедлайн
                  {renderSortIcon([
                    StudentAnalyticsSortType.CURRENT_COURSE_DEADLINE_DATE_ASC,
                    StudentAnalyticsSortType.CURRENT_COURSE_DEADLINE_DATE_DESC,
                  ])}
                </b>
              </th>
              <th className="sortableHeading">
                <b>Последнее присутствие</b>
              </th>
              <th
                onClick={handleChangeSort({
                  'ASC': StudentAnalyticsSortType.STUDENT_STATUS_ASC,
                  'DESC': StudentAnalyticsSortType.STUDENT_STATUS_DESC,
                })}
                className="sortableHeading nowrapWords"
              >
                <b>
                  Статус
                  {renderSortIcon([
                    StudentAnalyticsSortType.STUDENT_STATUS_ASC,
                    StudentAnalyticsSortType.STUDENT_STATUS_DESC,
                  ])}
                </b>
              </th>
              <th>
                <b>Действия</b>
              </th>
            </tr>
          </thead>
          <tbody>
            {students.map(student => (
              <StudentsRow
                key={student.id}
                student={student}
                onOpenStudentExpulsionDetails={() => {
                  setOpenStudentExpulsionDetails(true)
                  setSelectedStudent(student)
                }}
                action={
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" className="student-action-btn">
                      Выбрать
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setOpenReviewHistory(true)
                          setSelectedStudent(student)
                        }}
                      >
                        История ревью
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setOpenMeetHistory(true)
                          setSelectedStudent(student)
                        }}
                      >
                        История присутствия
                      </Dropdown.Item>
                      {student.statusInfo.recruitmentGroupStudentStatus === RecruitmentGroupStudentStatus.EXPELLED && (
                        <Dropdown.Item
                          onClick={() => {
                            setOpenReturn(true)
                            setSelectedStudent(student)
                          }}
                        >
                          Восстановить
                        </Dropdown.Item>
                      )}
                      {student.statusInfo.recruitmentGroupStudentStatus !== RecruitmentGroupStudentStatus.EXPELLED && (
                        <>
                          <Dropdown.Item
                            onClick={() => {
                              setOpenDeadline(true)
                              setSelectedStudent(student)
                            }}
                          >
                            Перенос дедлайнов
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setOpenCompleteDirection(true)
                              setSelectedStudent(student)
                            }}
                          >
                            Отправить в очередь
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setOpenExpelling(true)
                              setSelectedStudent(student)
                            }}
                          >
                            Отчислить
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setOpenTransfer(true)
                              setSelectedStudent(student)
                            }}
                          >
                            Перевести
                          </Dropdown.Item>
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                }
              />
            ))}
          </tbody>
        </table>
      </div>
      {selectedStudent && (
        <ReviewHistoryModal
          open={openReviewHistory}
          onClose={() => {
            setOpenReviewHistory(false)
            setSelectedStudent(undefined)
          }}
          studentId={selectedStudent.id}
        />
      )}
      {selectedStudent && (
        <MeetHistoryModal
          open={openMeetHistory}
          onClose={() => {
            setOpenMeetHistory(false)
            setSelectedStudent(undefined)
          }}
          student={selectedStudent}
        />
      )}
      {selectedStudent && (
        <CompleteDirectionModal
          open={openCompleteDirection}
          onClose={() => setOpenCompleteDirection(false)}
          onConfirm={() => {
            setLoading(true)
            curatorStudentService
              .completeDirection(selectedStudent.id)
              .then(() => {
                setOpenCompleteDirection(false)
                fetchStudentAnalytics()
              })
              .then(() => reactAlert.success('Студент успешно отправлен в очередь'))
              .catch(error => catchErrorAlert(error))
              .finally(() => setLoading(false))
          }}
        />
      )}
      {selectedStudent && (
        <StudentExpulsionDetailsModal
          disabled
          open={openStudentExpulsionDetails}
          onClose={() => {
            setOpenStudentExpulsionDetails(false)
            setSelectedStudent(undefined)
          }}
          reasonValue={selectedStudent.statusInfo.expellingReason?.reason}
          commentValue={selectedStudent.statusInfo.expellingReason?.comment}
        />
      )}
      {selectedStudent && (
        <StudentExpulsionDetailsModal
          open={openExpelling}
          onClose={() => {
            setOpenExpelling(false)
            setSelectedStudent(undefined)
          }}
          studentId={selectedStudent.id}
        />
      )}
      {selectedStudent && (
        <DeadlineModal
          open={openDeadline}
          onClose={() => {
            setOpenDeadline(false)
            setSelectedStudent(undefined)
          }}
          onSuccess={fetchStudentAnalytics}
          studentId={selectedStudent.id}
        />
      )}
      {selectedStudent && (
        <EnrollModal
          type="return"
          open={openReturn}
          onClose={() => {
            setOpenReturn(false)
            setSelectedStudent(undefined)
          }}
          onSuccess={fetchStudentAnalytics}
          studentsIds={[selectedStudent.id]}
        />
      )}
      {selectedStudent && (
        <EnrollModal
          type="transfer"
          open={openTransfer}
          onClose={() => {
            setOpenTransfer(false)
            setSelectedStudent(undefined)
          }}
          onSuccess={fetchStudentAnalytics}
          studentsIds={[selectedStudent.id]}
        />
      )}
      <LoadingOverlay open={isLoading} />
    </div>
  )
}
